import React from 'react';
import { ArrowCircleDownIcon, ArrowCircleUpIcon } from '@patternfly/react-icons';

const throughputFromLinks = (links) => {
  var throughput = { primaryDown: 0, primaryUp: 0, standbyDown: 0, standbyUp: 0 }

  if (links && links.length > 0) {
    links.forEach(link => {
      if (!link.isStandby && link.isEnabled) {
        throughput.primaryDown += parseFloat(link.downloadThroughput)
        throughput.primaryUp += parseFloat(link.uploadThroughput)
      }
      if (link.isStandby && link.isEnabled) {
        throughput.standbyDown += parseFloat(link.downloadThroughput)
        throughput.standbyUp += parseFloat(link.uploadThroughput)
      }
    });
  }

  return (
    <table className="w-100">
      <tbody>
        <tr className="info-section-row" key="throughput-1">
          <td>Primary Link(s):</td>
          <td className="d-flex align-items-center">{throughput.primaryDown > 0 ? (<><ArrowCircleDownIcon className="icon-small me-2"/> {throughput.primaryDown} Mbps</>) : ''}</td>
          <td className="d-flex align-items-center">{throughput.primaryUp > 0 ? (<><ArrowCircleUpIcon className="icon-small me-2" /> {throughput.primaryUp} Mbps</>) : ''}</td>
        </tr>
        <tr className="info-section-row" key="throughput-2">
          <td>Standby Link(s):</td>
          <td className="d-flex align-items-center">{throughput.standbyDown > 0 ? (<><ArrowCircleDownIcon className="icon-small me-2" /> {throughput.standbyDown} Mbps</>) : ''}</td>
          <td className="d-flex align-items-center">{throughput.standbyUp > 0 ? (<><ArrowCircleUpIcon className="icon-small me-2" /> {throughput.standbyUp} Mbps</>) : ''}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default throughputFromLinks;