import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const LastLocationContext = createContext<string | null>(null);

export const LastLocationProvider: React.FC = ({ children }) => {
  const [lastLocation, setLastLocation] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    setLastLocation(location.pathname);
  }, [location]);

  return (
    <LastLocationContext.Provider value={lastLocation}>
      {children}
    </LastLocationContext.Provider>
  );
};

export const useLastLocation = () => useContext(LastLocationContext);
