import React from 'react';
import PropTypes from '@app/utils/propTypes';

import { Table, Button, Row, Col } from 'reactstrap';
import { MdOutlinePeople } from 'react-icons/md';
import { ids } from 'webpack';
import { number } from 'prop-types';
import IconWidget from '@app/components/widget/IconWidget';
import { Line } from 'react-chartjs-2';
import { CardBody } from '@patternfly/react-core';
import { Link } from 'react-router-dom';

const CoursesList = ({ headers, coursesData, activeCourseId, setActiveCourseId, ...restProps }) => {
  const [activePartnerExpand, setActivePartnerExpand] = React.useState(null);

  const handleRowClick = (id) => {
    setActiveCourseId(id);
  };

  return (
    <Row>
      <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
        <div className="dashboard-card-wrapper user-courses">
          <h2>
            <strong>User Courses</strong>
          </h2>
          <Row>
            {coursesData.map(({ id, name, image, description }, index) => (
              <Col key={index} lg={4} md={4} sm={6} xs={12} className="mb-3">
                <div className="course-item p-3">
                  <div className="course-image mb-3">
                    <img src={image} alt="" />
                  </div>
                  <div className="course-name mb-3">
                    <strong>
                      {name}
                    </strong>
                  </div>
                  <div className="course-description mb-3">{description}</div>
                  <div className="ld_course_grid_button mb-3">
                    <Link to={`/courses/${id}`} className="btn w-100 ethica-button-green">
                      <strong>Start Learning</strong>
                    </Link>
                  </div>
                  <div className="learndash-wrapper learndash-widget mb-3">
                    <div className="ld-progress ld-progress-inline">
                      <div className="ld-progress-heading">
                        <div className="ld-progress-stats">
                          <div className="ld-progress-percentage ld-secondary-color">20% Complete</div>
                        </div>
                      </div>

                      <div className="ld-progress-bar">
                        <div className="ld-progress-bar-percentage ld-secondary-background" style={{width:'20%'}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

CoursesList.propTypes = {
  headers: PropTypes.node,
  coursesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      lessons: PropTypes.array,
    })
  ),
  activeCourseId: PropTypes.string,
  setActiveCourseId: PropTypes.func,
};

CoursesList.defaultProps = {
  headers: [],
  coursesData: [],
};

export default CoursesList;