import { Netmask } from 'netmask';
import { MAX_ACL_PORT } from './constants';

export const isUUID = (value: string | undefined) => /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/.test(value as string);

export const isValidUrl = (url) => {
  const expr = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,24}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  return expr.test(String(url).toLowerCase());
}

export const isValidPhone = (phone) => {
  const expr = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return expr.test(String(phone).toLowerCase());
}

export const isValidEmail = (email) => {
  const expr = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return expr.test(String(email).toLowerCase());
}

export const isValidIP = (ip) => {
  const expr = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
  return expr.test(String(ip).toLowerCase());
}

export const isValidSubnet = (subnet) => {
  if (subnet === undefined || subnet === "") {
    return false;
  }
  const parts = subnet.split('/');
  if (parts.length !== 2) {
    return false;
  }
  return isValidIP(parts[0]) && isValidPrefix('/' + parts[1])
}

export const isValidACLSubnet = (subnet) => {
  if (!subnet || subnet === "") {
    return false;
  }
  const parts = subnet.toString().split('/');
  if (parts.length !== 2) {
    return false;
  }
  return isValidIP(parts[0]) && isValidACLPrefix('/' + parts[1])
}

export const ipInSubnet = (ip, subnet) => {
  try {
    const block = new Netmask(subnet)
    return block.contains(ip) 
  } catch {
    return false;
  }
}

export const isValidVlan = (str) => {
  if (typeof str === undefined || str.toString() === '0') {
    return false;
  }
  if (str?.toString().includes('.')) {
    return false;
  }
  return isValidNumber(str) && isWithinRange(str, 2, 4094);
}

export const isValidNumber = (str) => {
  if (isNaN(str) || str === "") {
    return false;
  }
  return true;
}

export const isValidPercent = (str) => {
  const numStr = str.toString();
  if (!isValidNumber(numStr)) {
    return false;
  }
  if (isNaN(numStr)) {
    return false;
  }
  const num = parseInt(numStr);
  if (num < 1 || num > 100) {
    return false;
  }
  return true;
}

export const isValidPriority = (input) => {
  if (input && (input === "" || input === "*" || (isValidNumber(input) && isWithinRange(input, 0, 999)))) {
    return true;
  }
  else {
    return false;
  }
}

export const isValidPortRange = (input) => {
  // Accept blank string, an integer, or range (e.g. 100 or 100-200)
  if (!input) {
    return false;
  }
  if (isValidNumber(input) && parseInt(input) <= MAX_ACL_PORT) {
    return true;
  }
  if (input.split('-').length > 2) {
    return false;
  }

  if (input.indexOf('-') !== -1) {
    let range = input.split('-');

    if (!isValidNumber(range[0]) || !isValidNumber(range[1])) {
      return false;
    }
    if (!isWithinRange(range[0], 0, MAX_ACL_PORT) || !isWithinRange(range[1], 1, MAX_ACL_PORT) || parseInt(range[0]) > parseInt(range[1])) {
      return false
    }
    return true;
  }
  else {
    return false;
  }
}

export const isWithinRange = (value, start, end) => {
  const numStr = value?.toString();
  if (!isValidNumber(numStr) || !isValidNumber(start) || !isValidNumber(end)) {
    return false;
  }
  if (isNaN(numStr) || isNaN(start) || isNaN(end)) {
    return false;
  }
  const num = parseInt(numStr);
  const startNum = parseInt(start.toString());
  const endNum = parseInt(end.toString());

  if (num < startNum || num > endNum) {
    return false;
  }
  return true;
}

export const isLengthWithinRange = (value, minLength, maxLength) => {
  if (!value) {
    return false;
  }
  if (value.length < minLength || value.length > maxLength) {
    return false;
  }
  return true;
}

export const isValidPrefix = (prefix) => {
  if (prefix.length > 3) {
    return false;
  }
  if (!prefix.startsWith('/')) {
    return false;
  }
  const mask = parseInt(prefix.split('/')[1]);
  if (!mask) {
    return false;
  }
  if (mask < 8 || mask > 32) {  
    return false;
  }
  return true;
}

export const isValidACLPrefix = (prefix) => {
  if (prefix.length > 3) {
    return false;
  }
  if (!prefix.startsWith('/')) {
    return false;
  }
  const mask = parseInt(prefix.split('/')[1]);
  if (mask === undefined) {
    return false;
  }
  if ((mask > 0 && mask < 8) || mask > 32) {  
    return false;
  }
  return true;
}

export const textInputRequiredOnly = (textInput) => {
  return textInput ? 'default' : 'error';
} 

export const textInputValidate = (textInput, enabled, isValid, customError) => {
  var validated = textInputRequiredOnly(textInput);
  var errorText = " Required field";
  
  if (enabled && textInput && !isValid) {
    errorText = " " + customError;
    validated = 'error';
  }

  return {
    errorText: errorText,
    validated: validated
  }
}

export const isValidIpNotRequired = (textInput) => {
  if (textInput) {
    return isValidIP(textInput);
  } else {
    return true;
  }
}

export const validatePassword = (newPassword, confirmPassword) => {
  if (newPassword === '' || confirmPassword === '') {
    return { isValid: false, error: "New password and confirm password are required" }
  }

  // Minimum length
  if (newPassword.length < 8) {
    return { isValid: false, error: "New password must be at least 8 characters in length" }
  }

  // Lowercase letter
  if (/[a-z]/g.test(newPassword) === false) {
    return { isValid: false, error: "New password must contain at least one lowercase letter" }
  }

  // Uppercase letter
  if (/[A-Z]/g.test(newPassword) === false) {
    return { isValid: false, error: "New password must contain at least one uppercase letter" }
  }

  // Numeric character
  if (/\d/g.test(newPassword) === false) {
    return { isValid: false, error: "New password must contain at least one numeric character" }
  }

  // Special character
  if (/\W/g.test(newPassword) === false) {
    return { isValid: false, error: "New password must contain at least one special character" }
  }

  // Passwords must match
  if (newPassword !== confirmPassword) {
    return { isValid: false, error: "Passwords do not match" }
  }

  return { isValid: true }
}

export const isValidCreditCardExpiry = (expiry) => {
  if (!expiry || expiry === '' || (expiry && expiry.length !== 7)) {
    return false;
  }
 
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();
  const strCurrentYear = currentYear.toString();
  const expiryParts = expiry.split('/');
  const expMonth = expiryParts[0];
  const expYear = expiryParts[1];

  if (hasCharacter(expMonth) || hasCharacter(expYear)) {
    return false;
  }

  const expMonthInt = parseInt(expMonth);
  const expYearInt = parseInt(expYear);
  const currentYearInt = parseInt(strCurrentYear);

  if (expMonthInt > 12 || expMonthInt < 1) {
    return false;
  }

  if (expYearInt < currentYearInt) {
    return false;
  }

  if (expYearInt === currentYearInt && expMonthInt < currentMonth) {
    return false;
  }

  return true;
}

const hasCharacter = (str) => {
  for (let i = 0; i < str.length; i++) {
    if (!isValidNumber(str.charAt(i))) {
      return true;
    }
  }
  return false;
}

export const isValidCreditCardNumber = (number) => {
  if (!number || number === '' || (number && (number.length < 15 || number.length > 16))) {
    return false;
  }

  return hasCharacter(number) ? false : true;
}

export const isValidCreditCardCvc = (cvc) => {
  if (!cvc || cvc === '' || (cvc && (cvc.length < 3 || cvc.length > 4))) {
    return false;
  }

  return hasCharacter(cvc) ? false : true;
}