import React, { Component } from "react";
import loaderLogo from '@app/assets/images/logo/CloudAccess-Logo-2024.png';

class Loader extends Component {
  render() {
    return (
      <div className="loader-wrapper">
        <div className="loader-content">
          <img
            src={loaderLogo}
            width="115"
            alt=""
          />
          <div className="loader m-auto mt-2"></div>
        </div>
      </div>
    );
  }
}

export default Loader;