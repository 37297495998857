import * as React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { AppDispatch } from '@app/store';
import PartnerApi from '@app/api/partner-api/partner.actions';
import { Spacer } from '@app/components/Spacer';
import ApiGateway from '@app/api/api-gateway/api-gateway';
import { getCountryOptions, getRegionOptions } from '@app/common/world';
import { getParamFromQueryString } from '@app/lib/functions';
import { getNamesFromFullname } from './PartnerHelper';
import { textInputRequiredOnly, textInputValidate, isValidPhone, isValidEmail, isValidNumber, isLengthWithinRange } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { Flex, FlexItem, PageSection, Title, TextInput, Select, SelectVariant } from '@patternfly/react-core';
import './Partner.css';
import { MdClose } from 'react-icons/md';
import { Card, CardBody, Form, FormGroup, Button, Input, Label, FormFeedback } from 'reactstrap';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';

export const PartnerForm = connect()(class extends React.Component<any, any> {
  form: Form | null;
  constructor(props) {
    super(props);

    //const id = this.props.activePartnerId ?? this.props.computedMatch?.params?.id;
    const countries = getCountryOptions();

    this.state = {
      id: props.activePartnerId ?? null,
      name: '',
      address_1: '',
      address_2: '',
      city: '',
      country: '',
      postal_code: '',
      state: '',
      contact_email: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_phone: '',
      contact_phone_ext: '',
      countries: countries,
      regions: [],
      isCountryOpen: false,
      isRegionOpen: false,
      error: '',
      message: '',
      messageType: '',
      isLoading:  false,
      mode: props.activePartnerId ? 'edit' : 'new',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activePartnerId !== prevState.id) {
      return {
        id: nextProps.activePartnerId ?? null,
        mode: nextProps.activePartnerId ? 'edit' : 'new',
      };
    }
    return null;
  }

  closeForm = () => {
    if (this.props.setPartnerForm) {
      this.props.setPartnerForm(false);
    }
  };

  componentDidMount = () => {
    try {
      this.loadPartner();
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the partner profile"
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      this.loadPartner();
    }
  }

  loadPartner = async () => {
    const { mode, id } = this.state;

    if (mode === 'edit') {
      const partner = await PartnerApi.get(id);
      const names = getNamesFromFullname(partner.billing_contact);

      this.setState(() => ({
        name: partner.name,
        address_1: partner.billing_address1,
        address_2: partner.billing_address2,
        city: partner.billing_city,
        country: partner.billing_country,
        postal_code: partner.billing_postal_code,
        state: partner.billing_state,
        contact_email: partner.billing_email,
        contact_first_name: names.first_name,
        contact_last_name: names.last_name,
        contact_phone: partner.billing_phone,
        contact_phone_ext: partner.billing_ext,
        regions: partner.billing_country ? getRegionOptions(partner.billing_country) : [],
      }));  
    } else {
      this.setState(() => ({
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        country: '',
        postal_code: '',
        state: '',
        contact_email: '',
        contact_first_name: '',
        contact_last_name: '',
        contact_phone: '',
        contact_phone_ext: '',
        isCountryOpen: false,
        isRegionOpen: false,
        error: '',
        message: '',
        messageType: '',
        isLoading:  false,
      }));       
    }
  }

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  onCountryToggle = (isOpen) => {
    this.setState(() => ({
      isCountryOpen: isOpen,
    }));
  };

  onRegionToggle = (isOpen) => {
    this.setState(() => ({
      isRegionOpen: isOpen,
    }));
  };

  onCountrySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearCountry();
    } 
    else {
      const regions = getRegionOptions(selection);
      this.setState(() => ({
        country: selection,
        regions: regions,
        state: null,
        isCountryOpen: false,
      }));
    }
  };

  onRegionSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearRegion();
    } 
    else {
      this.setState(() => ({
        state: selection,
        isRegionOpen: false,
      }));
    }
  };

  clearCountry = () => {
    this.setState({
      country: null,
      isCountryOpen: false,
    });
  };

  clearRegion = () => {
    this.setState({
      state: null,
      isRegionOpen: false,
    });
  };

  handleCancel = () => {
    const { id } = this.state;

    if (id) {
      this.props.history.push(`/partners?id=${id}`);
    }
    else {
      this.props.history.push('/partners');
    }
  }

  partnerFromState = () => {
    const { name, address_1, address_2, city, country, state, postal_code, contact_email, contact_phone, contact_first_name, contact_last_name } = this.state;

    let partner = {
      name: name,
      address: {
        line1: address_1,
        line2: address_2,
        city: city,
        country: country,
        postal_code: postal_code,
        state: state
      },
      contact_info: {
        email: contact_email,
        first_name: contact_first_name,
        last_name: contact_last_name,
        phone: contact_phone
      }
    }

    return partner;
  }  

  updatePartner = async () => {
    const payload = this.partnerFromState();
    const { id } = this.state;
    let result;

    try {
      const result = await axios.patch(
        `${process.env.REACT_APP_USER_MGMT_API_BASE_URL}/partners/${id}`,
        payload,
        { headers: ApiGateway.getHeaders() }
      );
    } catch (error) {
      console.error('Error occurred:', error);
    
      if (error.response) {
        const errorMessage = error.response.data?.error || 'Unknown server error.';
        this.setState({ message: errorMessage });
      } else {
        this.setState({ message: 'Network error. Please check your connection.' });
      }
    }
  }

  createPartner = async () => {
    const payload = this.partnerFromState();
    let result;

    try {
      result = await axios.post(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + '/partners',
        payload, 
        { headers: ApiGateway.getHeaders() }
      );
      this.props.history.push(`/partners?id=${result.data.id}`);
    }
    catch (error) {
      this.setState(() => ({
        message: error.response.data.error
      }));
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { mode } = this.state;
    const { name, contact_email, contact_first_name, contact_last_name, contact_phone, address_1, city, postal_code } = this.state;
  
    const errors = {
      nameError: this.validateName(name),
      firstNameError: this.validateFirstName(contact_first_name),
      lastNameError: this.validateLastName(contact_last_name),
      phoneError: this.validatePhone(contact_phone),
      emailError: this.validateEmail(contact_email),
      addressError: this.validateAddress(address_1),
      cityError: this.validateCity(city),
      postalCodeError: this.validatePostalCode(postal_code),
    };
  
    this.setState(errors, () => {
      const hasErrors = Object.values(errors).some((error) => error);
  
      if (!hasErrors) {  
        if (mode === "edit") {
          this.updatePartner();
        } else if (mode === "new") {
          this.createPartner();
        }
        this.saveSuccess();
        this.closeForm();
      }
    });
  }

  saveSuccess = () => {
    Swal.fire({
      title: 'Partner Saved',
      icon: 'success',
      reverseButtons: false,
    })
  };

  validateName = (value) => {
    if (!value) {
      return "Name is required";
    } else if (value.length < 3) {
      return "Name must be at least 3 characters";
    } else {
      return "";
    }
  };

  validateFirstName = (value) => {
    if (!value) {
      return "First Name is required";
    } else if (value.length < 3) {
      return "First Name must be at least 3 characters";
    } else {
      return "";
    }
  };

  validateLastName = (value) => {
    if (!value) {
      return "Last Name is required";
    } else if (value.length < 3) {
      return "Last Name must be at least 3 characters";
    } else {
      return "";
    }
  };

  validateAddress = (value) => {
    if (!value) {
      return "Address is required";
    } else if (value.length < 3) {
      return "Address must be at least 3 characters";
    } else {
      return "";
    }
  };

  validatePhone = (value) => {
    const phoneRegex = /^[+]?[0-9]{10,15}$/;
    if (!value) {
      return "Phone is required";
    } else if (!phoneRegex.test(value)) {
      return "Invalid phone number";
    } else {
      return "";
    }
  };

  validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      return "Email is required";
    } else if (!emailRegex.test(value)) {
      return "Invalid email address";
    } else {
      return "";
    }
  };

  validateCity = (value) => {
    if (!value) {
      return "City is required";
    } else if (value.length < 3) {
      return "City must be at least 3 characters";
    } else {
      return "";
    }
  };

  validatePostalCode = (value) => {
    if (!value) {
      return "Postal code is required";
    } else if (value.length < 3) {
      return "Postal code must be at least 3 characters";
    } else {
      return "";
    }
  };

  render() {
    const { 
      name, address_1, address_2, city, country, postal_code, state, contact_email, contact_phone, contact_first_name, 
      contact_last_name, contact_phone_ext, countries, regions, isCountryOpen, isRegionOpen, mode
    } = this.state;

    return (
      <div className="border p-2 shadow partners-form">
        
        <div className="d-flex justify-content-between border-bottom">
          <h3 className="col-9"><strong>{mode === "edit" ? "Edit" : "New"} Partner</strong></h3>
          <div className="pt-2 pb-2 pl-2">  
            <Button outline className="ethica-button-black" size="sm" onClick={this.closeForm}><MdClose /></Button>
          </div>
        </div>

        <Form ref={(form) => this.form = form} noValidate>
          <Card className="mt-2">
            <CardBody>
              <FormGroup label="Partner Name">
                <Label for="name">Partner Name</Label>
                <Input
                  required                  
                  type="text" 
                  id="name" 
                  name="name" 
                  value={name || ""}
                  disabled={mode === "edit" ? true : false}
                  invalid={!!this.state.nameError}
                  onChange={(e) => this.handleChange("name", e.target.value)} 
                />
                {this.state.nameError && <FormFeedback>{this.state.nameError}</FormFeedback>}
              </FormGroup>
            </CardBody>
          </Card>

          <div className="d-flex justify-content-between">
                <Card className="mt-2 me-2 w-100">
                  <CardBody>
                    <Title headingLevel="h3" size="md">Primary Contact</Title><br />

                    <FormGroup label="First Name">
                      <Label for="contact_first_name">First Name*</Label>
                      <Input
                        required 
                        type="text" 
                        id="contact_first_name" 
                        name="contact_first_name" 
                        value={contact_first_name || ""}
                        invalid={!!this.state.firstNameError}
                        onChange={(e) => this.handleChange("contact_first_name", e.target.value)} 
                      />
                      {this.state.firstNameError && <FormFeedback>{this.state.firstNameError}</FormFeedback>}
                    </FormGroup>
                    <FormGroup label="Last Name">
                      <Label for="contact_last_name">Last Name*</Label>
                      <Input
                        required 
                        type="text" 
                        id="contact_last_name" 
                        name="contact_last_name" 
                        value={contact_last_name || ""}
                        invalid={!!this.state.lastNameError}
                        onChange={(e) => this.handleChange("contact_last_name", e.target.value)} 
                      />
                      {this.state.lastNameError && <FormFeedback>{this.state.lastNameError}</FormFeedback>}
                    </FormGroup>
                    <FormGroup label="Email Address">
                      <Label for="contact_email">Email Address*</Label>
                      <Input
                        required 
                        type="email" 
                        id="contact_email" 
                        name="contact_email" 
                        value={contact_email || ""}
                        invalid={!!this.state.emailError}
                        onChange={(e) => this.handleChange("contact_email", e.target.value)} 
                      />
                      {this.state.emailError && <FormFeedback>{this.state.emailError}</FormFeedback>}
                    </FormGroup>
                    <FormGroup label="Phone Number">
                      <Label for="contact_phone">Phone Number*</Label>
                      <Input
                        required 
                        type="text" 
                        id="contact_phone" 
                        name="contact_phone" 
                        value={contact_phone || ""}
                        invalid={!!this.state.phoneError}
                        onChange={(e) => this.handleChange("contact_phone", e.target.value)} 
                      />
                      {this.state.phoneError && <FormFeedback>{this.state.phoneError}</FormFeedback>}
                    </FormGroup>
                  </CardBody>
                </Card>

                <Card className="mt-2 w-100">
                  <CardBody>
                    <Title headingLevel="h3" size="md">Partner Profile</Title><br />

                    <FormGroup label="Address 1">
                      <Label for="address_1">Address 1*</Label>
                      <Input
                        required 
                        type="text" 
                        id="address_1" 
                        name="address_1" 
                        placeholder=""
                        value={address_1 || ""}
                        invalid={!!this.state.addressError}
                        onChange={(e) => this.handleChange("address_1", e.target.value)} 
                      />
                      {this.state.addressError && <FormFeedback>{this.state.addressError}</FormFeedback>}
                    </FormGroup>
                    <FormGroup label="Address 2">
                      <Label for="address_2">Address 2</Label>
                      <Input
                        type="text" id="address_2" name="address_2" placeholder=""
                        value={address_2 || ""}
                        onChange={(e) => this.handleChange("address_2", e.target.value)} 
                      />
                    </FormGroup>

                    <div className="d-flex justify-content-between">
                      <FormGroup label="Postal Code / Zip" className="me-1">
                        <Label for="postal_code">Postal Code / Zip</Label>
                        <Input 
                          type="text" 
                          id="postal_code" 
                          name="postal_code" 
                          value={postal_code || ""}
                          invalid={!!this.state.postalCodeError}
                          onChange={(e) => this.handleChange("postal_code", e.target.value)} 
                        />
                        {this.state.postalCodeError && <FormFeedback>{this.state.postalCodeError}</FormFeedback>}
                      </FormGroup>
                      <FormGroup label="City">
                        <Label for="city">City</Label>
                        <Input 
                          type="text" 
                          id="city" 
                          name="city" 
                          value={city || ""}
                          invalid={!!this.state.cityError}
                          onChange={(e) => this.handleChange("city", e.target.value)} 
                        />
                        {this.state.postalCodeError && <FormFeedback>{this.state.postalCodeError}</FormFeedback>}
                      </FormGroup>
                    </div>

                    <FormGroup label="Country">
                      <Input
                        type="select" 
                        value={this.state.country || ''}
                        onChange={(e) => this.onCountrySelect(e, e.target.value, false)}
                        onClick={() => this.onCountryToggle(!this.state.isCountryOpen)}
                        open={this.state.isCountryOpen} 
                      >
                        {countries &&
                        <>
                          <option key="default" value="">Select country</option>
                          {countries.map((country, index) => (
                              <option key={country.key || index} value={country.props.value}>
                                  {country.props.value}
                              </option>
                          ))}
                        </>
                        }
                      </Input>
                    </FormGroup>
                    <FormGroup label="State / Province">
                      <Input
                        type="select" 
                        value={this.state.state || ''}
                        onChange={(e) => this.onRegionSelect(e, e.target.value, false)}
                        onClick={() => this.onRegionToggle(!this.state.isRegionOpen)}
                        open={this.state.isRegionOpen} 
                        disabled={regions.length === 0}
                      >
                        {regions &&
                        <>
                          <option key="default" value="">Select region</option>
                          {regions.map((region) => (
                              <option key={region.key} value={region.props.value}>
                                  {region.props.value}
                              </option>
                          ))}
                        </>
                        }
                      </Input>
                    </FormGroup>
                  </CardBody>
                </Card>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <Button className="me-2 ms-4 ethica-button-green" onClick={this.handleSubmit}>Save</Button>
            <Button className="me-2 ethica-button-black" onClick={this.closeForm}>Cancel</Button>
          </div>
        </Form>
      </div>
    );
  }
})

export default withRouter(PartnerForm);