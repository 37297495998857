import React, { useState, useEffect, useContext } from 'react'
import SearchInput from '../../components/SearchInput';
import Avatar from '../../components/Avatar';
import { UserCard } from '../../components/card';
import UserApi from '@app/api/user-api/user.actions';
import UserSession from '@app/common/user-session';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
  MdLightMode,
  MdDarkMode,
  MdAccountCircle
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { Link } from 'react-router-dom';
import { actionCreators } from '../../../app/common/auth/auth.reducer';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import SidebarContext from '@app/providers/contexts/SidebarContext';
import { useDispatch } from 'react-redux';
import CartHeader from './cart.header.component';

interface HeaderProps {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  isLoading: boolean;
};

const bem = bn.create('header');

export const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch();
  const [isNavOpen, setNavOpen] = React.useState(true);

  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    isLoading: false,
  });
  
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { sidebarStatus, toggleSidebar } = useContext(SidebarContext);

  useEffect(() => {
    try {
      loadProfile();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        message: "There was an error getting the user profile",
      }));
    }
  }, []);

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const userId = UserSession.getUserId();
        const user = await UserApi.get(userId);
        
        setState((prevState) => ({
          ...prevState,
          firstName: user?.first_name || "",
          lastName: user?.last_name || "",
          email: user?.email || "",
          phone: user?.phone || ""
        }));
      } catch (error) {
        console.error('Failed to load user profile', error);
      }
    };
  
    loadProfile();
  }, []);

  const [state, setState] = useState({
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  });

  const onNavToggle = React.useCallback(() => {
    setNavOpen(!isNavOpen);
  }, [isNavOpen, setNavOpen]);

  const toggleUserCardPopover = () => {
    setState((prevState) => ({
      ...prevState,
      isOpenUserCardPopover: !prevState.isOpenUserCardPopover,
    }));
  };

  const handleLogout = React.useCallback(() => {
    dispatch(actionCreators.logoutRequest());
  }, [dispatch]);

  return (
    <Navbar light expand className={bem.b(theme === 'light' ? 'bg-white' : 'bg-black')}>
        <Nav navbar className="me-2 ps-1">
          <Button outline onClick={toggleSidebar} className="sidebar-button">
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>
          <SearchInput />
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavLink>
          </NavLink>
        </Nav>

        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <CartHeader />
          </NavItem>
          <NavItem>
            <NavLink>
              <div className="pe-3" onClick={toggleTheme}>
                {theme === 'light' ? (
                  <MdLightMode />
                ) : (
                  <MdDarkMode style={{ color: "white" }}/>
                )}                
              </div>

              <MdAccountCircle id="Popover2" fill="#85ba3b" size="25" onClick={toggleUserCardPopover} className="can-click"/>
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={state.isOpenUserCardPopover}
              toggle={toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
              {...{
                popperProps: {
                  modifiers: [],
                  transition: {
                    timeout: 300,
                  },
                },
              }}
            >
              <PopoverBody className="p-0 border-light header-profile">
                <UserCard
                  title={`${state.firstName} ${state.lastName}`}
                  subtitle={state.email}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> <Link to="/admin">Profile</Link>
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light" onClick={handleLogout}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
  )
}

function dispatch(arg0: { type: import("../../../app/common/auth/auth.reducer").actionTypes; }) {
  throw new Error('Function not implemented.');
}
