import * as React from 'react';
import ControllerApi from '@app/api/controller-api/controller.actions';
import GatewayApi from '@app/api/gateway-api/gateway.actions';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import Server from '@app/api/gateway/gateway.actions';
import Site from '@app/api/site/site.actions';
import PublicIp from '@app/api/publicip/publicip.actions';
import Wan from '@app/api/wan/wan.actions';
import setControllerServers from '@app/pages/controller/ControllerHelper';
import { textInputRequiredOnly, isValidIP, isValidPrefix, isLengthWithinRange, isUUID } from '@app/lib/validator';
import { Timezones, getCountryOptions, getRegionOptions, getTimezoneOptions, getOffsetString } from '@app/common/world';
import { IconHeading } from '@app/components/IconHeading';
import { Saver } from '@app/components/Saver';
import ControllerIcon from '@app/bgimages/controllers-black.svg';
import { ExclamationCircleIcon, TrashIcon, PlusIcon, BullhornIcon, TopologyIcon, PficonNetworkRangeIcon } from '@patternfly/react-icons';
import { PageSection, Title, Checkbox, Flex, FlexItem, Modal, ModalVariant, TextInput, Select, SelectVariant, TextArea, Tabs, Tab, TabTitleText, TabTitleIcon } from "@patternfly/react-core";
import { Table, TableHeader, TableBody, TableVariant, cancelCellEdits, validateCellEdits, applyCellEdits, EditableTextCell } from '@patternfly/react-table';
import { FormGroupSpacer } from '../../components/forms/pf/form-group-spacer.component';
import classnames from 'classnames';

import './Controller.css';

import { connect } from 'react-redux';
import { actionCreators } from '@app/common/session/session.reducer';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, FormGroup, Label, Input, Card, CardBody, CardHeader } from 'reactstrap';
import { MdAccountTree, MdAddCircle, MdAltRoute, MdDomain, MdLocationOn } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import Loader from '@app/components/Loader';

enum TabIndex {
  OSPF,
  BGP
}

const TitleMap = {
  [TabIndex.OSPF]: 'OSPF',
  [TabIndex.BGP]: 'BGP'
};

export const ControllerForm = connect()(class extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const id = this.props.computedMatch?.params?.id;
    const countries = getCountryOptions();
    const timezones = getTimezoneOptions();

    const basePath = process.env.REACT_APP_BASE_PATH || '';  

    this.state = {
      id: id,
      name: "",
      description: "",
      country: "",
      region: "", 
      timezone: "",
      ip_address: "",
      routingEnabled: false,
      routingConfig: {
        ospf_enabled: false,
        ospf_configuration: "",
        bgp_enabled: false,
        bgp_configuration: ""
      },
      activeTabIndex: TabIndex.OSPF,
      isCountryOpen: false,
      isRegionOpen: false,
      isTimezoneOpen: false,
      isDeleteServerConfirmOpen: false,
      serverId: "",
      newServerTempId: -1,
      countries: countries,
      regions: [],
      timezones: timezones,
      servers: [],
      bgp_configuration_rows: 10,
      ospf_configuration_rows: 10,
      message: "",
      messageType: "",
      editRowMode: false,
      mode: id ? "edit" : "new",
      activeTab: '1',
      ospfChecked: false,
      bgpChecked: false,
      ospfText: '',
      bgpText: '',
      isLoading: true
    }
  }

  static contextType = ThemeContext;

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  handleOspfCheckboxChange = () => {
    this.setState((prevState) => ({
      ospfChecked: !prevState.ospfChecked,
    }));
  };

  handleBGPCheckboxChange = () => {
    this.setState((prevState) => ({
      bgpChecked: !prevState.bgpChecked,
    }));
  };

  handleOspfTextChange = (e) => {
    this.setState({ ospfText: e.target.value });
  };

  handleBGPTextChange = (e) => {
    this.setState({ bgpText: e.target.value });
  };


  componentDidMount = async () => {
    if (this.state.mode === "edit") {
      try {
        await this.loadController();
      } 
      catch (error) {
        console.log(error)
        this.setState(() => ({
          message: "There was an error loading the controller"
        }));
      }
    }
  }

  loadController = async () => {
    const sites = await Site.getAll();
    const wans = await Wan.getAll();
    const controller = await ControllerApi.get(this.state.id);
    const gateways = await GatewayApi.getAll();
    const tunnels = await Tunnel.getAll();
    const publicIps = await PublicIp.getAll();

    if (controller === undefined) {
      this.props.history.push("/notfound");
    }
    
    controller.Servers = gateways.filter(gateway => gateway.controller_id === this.state.id);

    for (let i=0; i<controller.Servers.length; i++) {
      controller.Servers[i] = { 
        ...controller.Servers[i], 
        Tunnels: tunnels.filter(tunnel => tunnel.serverId === controller.Servers[i].id),
        PublicIps: publicIps.filter(publicIp => publicIp.serverId === controller.Servers[i].id)
      };
    }

    const servers = setControllerServers(controller, sites, wans);
    const assignedServers = servers['assigned'].map(item => { return this.getRow(item, false); });
    const availableServers = servers['available'].map(item => { return this.getRow(item, false); });
    const regions = getRegionOptions(controller.country) || [];
    const timezones = Timezones();
    const timezoneObj = timezones.find(t => t.name === controller.timezone);
    const timezone = getOffsetString(timezoneObj); //TODO: Ugly, refactor this
    const ospfConfig = "";
    const bgpConfig = "";
    const ospf = controller.routing_configuration?.ospf_configuration;
    const bgp = controller.routing_configuration?.bgp_configuration;
    
    if (ospf) {
      ospfConfig = ospf.join('\n');
    }
    if (bgp) {
      bgpConfig = bgp.join('\n');
    }  

    this.setState(() => ({
      name: controller.name,
      description: controller.description,
      country: controller.country, 
      region: controller.region, 
      regions: regions,
      timezone: timezone,
      ip_address: controller.ip_address || '',
      // routerIp: controller.router_ip,
      // isVps: controller.is_vps,
      // vpsProvider: controller.vps_provider,
      // monitorEndpoint: controller.monitor_endpoint,
      ospf_configuration_rows: ospf?.length > 10 ? ospf.length : 10,
      bgp_configuration_rows: bgp?.length > 10 ? bgp.length : 10,
      routingEnabled: controller.dynamic_routing_enabled,
      routingConfig: {
        ospf_enabled: controller.routing_configuration?.ospf_enabled,
        ospf_configuration: ospfConfig,
        bgp_enabled: controller.routing_configuration?.bgp_enabled,
        bgp_configuration: bgpConfig,
      },
      servers: [...assignedServers, ...availableServers],
      isLoading: false
    }));
  }

  getRow = (server, isEditable) => {
    var cells: any[] = [];

    cells.push(this.getCell(server, "ip_4_address"));
    cells.push(this.getCell(server, "ip_4_cidr"));
    cells.push(this.getCell(server, "ip_4_router"));
    cells.push(this.getCell(server, "delete"));
    cells.push(this.getCell(server, "id"));

    const row = {
      rowEditValidationRules: [{
        name: 'required',
        validator: value => value.trim() !== '',
        errorText: 'Field required'
      },{
        name: 'invalidIp',
        validator: value => isValidIP(value.trim()) !== false,
        errorText: 'Invalid IP Address'
      },{
        name: 'invalidMask',
        validator: value => isValidPrefix(value.trim()) !== false,
        errorText: 'Must be between /8 and /32'
      }],
      cells: cells,
      publicIpId: (server.PublicIps && server.PublicIps.length > 0) ? server.PublicIps[0].id : ""
    }

    row.isEditable = isEditable;
 
    return row;
  } 

  getCell = (server, columnName) => {
    if (columnName === 'delete') {
      return { 
        title: (
          <div className="trash-icon" onClick={(e) => this.deleteConfirmation(e, server.id)}>
            <TrashIcon />
          </div>
        ),
        props: {
          value: "-1",
          name: columnName
        }
      };
    }
    
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableTextCell
          value={value}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          handleTextInputChange={this.handleTextInputChange}
          inputAriaLabel={columnName}
        />
      ),
      props: {
        value: server[columnName],
        name: columnName
      }
    }

    return cell;
  }

  handleTabClick = (e, index) => {
    this.setState(() => ({
      activeTabIndex: index
    }));
  };

  addServer = () => {
    const { servers, newServerTempId } = this.state;
    var emptyRow = { ip_4_address: "", ip_4_cidr: "/24", ip_4_router: "", delete: "", id: newServerTempId.toString() };

    servers.push(this.getRow(emptyRow, true));

    this.setState(() => ({
      newServerTempId: newServerTempId - 1,
      editRowMode: true,
      servers: servers
    }));
  }

  deleteConfirmation = (event, id) => {
    this.setState(() => ({
      isDeleteServerConfirmOpen: true,
      serverId: id
    }));
  }

  deleteServer = async () => {
    const { serverId, servers } = this.state;
    const serverIdIndex = this.getServerColumns().length;
    const updated = servers.filter(c => c.cells[serverIdIndex].props.value !== serverId);

    // If server being deleted actually exists in the DB. Servers added, but not persisted will have "-1" id
    if (isUUID(serverId)) {
      try {
        await Server.delete(serverId);
        this.setState(() => ({
          message: "Gateway was deleted successfully!",
          servers: updated, 
        }));
      } catch (error) {
        if ((error as any).message === 'SERVER_ASSIGNED') {
          this.props.dispatch(actionCreators.notificationEnqueue({
            message: 'Server Assigned: Unable to Delete',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          }));
        }
      }
    }
    else {
      this.setState(() => ({
        message: "Gateway was deleted successfully!",
        servers: updated, 
      }));
    }

    this.setState(() => ({
      isDeleteServerConfirmOpen: false,
      serverId: "",
      error: "",
      editRowMode: false
    }));
  }

  onCountryToggle = (isOpen) => {
    this.setState(() => ({ 
      isCountryOpen: isOpen
    }));
  }

  onRegionToggle = (isOpen) => {
    this.setState(() => ({ 
      isRegionOpen: isOpen
    }));
  }

  onTimezoneToggle = (isOpen) => {
    this.setState(() => ({ 
      isTimezoneOpen: isOpen
    }));
  }

  onCountrySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearCountry();
    }
    else {
      const regions = getRegionOptions(selection);
      this.setState(() => ({ 
        country: selection,
        regions: regions,
        region: null,
        isCountryOpen: false 
      }));
    }
  }

  onRegionSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearRegion();
    }
    else {
      this.setState(() => ({ 
        region: selection,
        isRegionOpen: false 
      }));
    }
  }

  onTimezoneSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearTimezone();
    }
    else {
      this.setState(() => ({ 
        timezone: selection,
        isTimezoneOpen: false 
      }));
    }
  }

  clearCountry = () => {
    this.setState({
      country: null,
      isCountryOpen: false
    });
  };

  clearRegion = () => {
    this.setState({
      region: null,
      isRegionOpen: false
    });
  };

  clearTimezone = () => {
    this.setState({
      timezone: null,
      isTimezoneOpen: false
    });
  };

  handleTextInputChange = (newValue, evt, rowIndex, cellIndex) => {
    let newRows = Array.from(this.state.servers);
    newRows[rowIndex].cells[cellIndex].props.editableValue = newValue;
    this.setState(() => ({
      servers: newRows
    }));
  }

  updateEditableRows = async (evt, type, isEditable, rowIndex, validationErrors) => {
    let newRows = Array.from(this.state.servers);
    var invalidIps = [];
    var invalidMasks = [];

    if (validationErrors.invalidIp && validationErrors.invalidIp.includes("ip_4_address")) {
      invalidIps.push("ip_4_address")
    }    
    if (validationErrors.invalidIp && validationErrors.invalidIp.includes("ip_4_router")) {
      invalidIps.push("ip_4_router")
    }
    if (validationErrors.invalidMask && validationErrors.invalidMask.includes("ip_4_cidr")) {
      invalidMasks.push("ip_4_cidr")
    }

    if (invalidIps.length > 0) {
      validationErrors.invalidIp = invalidIps;
    }
    else {
      delete validationErrors.invalidIp
    }

    if (invalidMasks.length > 0) {
      validationErrors.invalidMask = invalidMasks;
    }
    else {
      delete validationErrors.invalidMask
    }

    if (validationErrors && Object.keys(validationErrors).length) {
      newRows[rowIndex] = validateCellEdits(newRows[rowIndex], type, validationErrors);
      this.setState({ servers: newRows });
      return;
    }
    
    if (type === 'edit' && Object.keys(validationErrors).length === 0) {
      this.setState({ editRowMode: true });
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);
      return;
    }

    if (type === 'cancel') {
      newRows[rowIndex] = cancelCellEdits(newRows[rowIndex]);
      this.setState({ servers: newRows, editRowMode: false });
      return;
    }
    
    if (type === 'save') {
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);

      // Check for duplicate IPs
      let currentRowIp = newRows[rowIndex].cells[0].props.value;
      let newServerIps = newRows.filter(s => s.cells[0].props.value === currentRowIp);

      if (newServerIps.length > 1) {
        this.setState({ editRowMode: true, error: currentRowIp + " already exists. Please edit and try another" });
      }
      else {
        this.setState({ servers: newRows, editRowMode: false, error: "" });
      }
    }
  }

  getServerColumns = () => {
    return ['IP Address', 'Subnet', 'Controller IP', ''];
  }

  handleConfirmToggle = () => {
    this.setState(({ isDeleteServerConfirmOpen }) => ({
      isDeleteServerConfirmOpen: !isDeleteServerConfirmOpen
    }));
  };

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  handleObjectChange = (key, name, value) => {
    const obj = this.state[key];
    const rowsKey = name + '_rows';
    const configRows = obj[name].split('\n');
    const numRows = configRows.length > 10 ? configRows.length : 10;
    obj[name] = value;
    
    this.setState(() => ({
      [key]: obj,
      [rowsKey]: numRows
    }));
  };

  handleConfigPaste = (key, event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('Text');
    const configProp = key.replace('_rows', '');
    const configRows = pastedData.split('\n');
    const numRows = configRows?.length > 10 ? configRows?.length : 10;
    const config = this.state.routingConfig;
    config[configProp] = pastedData;

    this.setState(() => ({
      routingConfig: { ...config },
      [key]: numRows
    }));
  };

  handleRoutingCheckboxChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(prevState => ({
      routingConfig: {
        ...prevState.routingConfig,
        [name]: value
      },
    }));
  };

  handleCancel = () => {
    if (this.state.id) {
      this.props.history.push('/controller/' + this.state.id)
    }
    else {
      this.props.history.push('/controllers')
    }
  }

  handleSubmit = async (event) => {
    if (this.state.mode === "edit") {
      this.updateController();
    }
    else if (this.state.mode === "new") {
      this.createController();
    }
  }

  getPayloadFromState = () => {
    const routingConfig = this.state.routingConfig;

    return {
      name: this.state.name,
      description: this.state.description,
      country: this.state.country,
      region: this.state.region, 
      timezone: this.state.timezone,
      gateways: this.state.servers,
      ip_address: this.state.ip_address,
      dynamic_routing_enabled: routingConfig.ospf_enabled || routingConfig.bgp_enabled,
      routing_configuration: {
        ospf_enabled: routingConfig.ospf_enabled,
        ospf_configuration: routingConfig.ospf_configuration.split('\n') || [],
        bgp_enabled: routingConfig.bgp_enabled,
        bgp_configuration: routingConfig.bgp_configuration.split('\n') || []
      }
    }
  }

  createController = async () => {
    const { name, servers } = this.state;

    if (name.toLowerCase() === "serverless") {
      this.setState(() => ({
        message: "The name " + name + " is reserved. Please try another name"
      })); 
      return;
    }
    
    const controllers = await ControllerApi.getAll();
    const controller = controllers.find(c => c.name === name);

    if (controller) {
      this.setState(() => ({
        message: "A controller with the name " + name + " already exists. Please try another name"
      })); 
      return;
    }

    try {
      const payload = this.getPayloadFromState();
      console.log('payload',payload);
      let result = await ControllerApi.create(payload);
      console.log('result',result);
      this.props.history.push(`${this.state.basePath}/controller/` + result.id)
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error creating the controller"
      }));
    }
  }

  updateController = async () => {
    const { name, servers } = this.state;

    if (name.toLowerCase() === "serverless") {
      this.setState(() => ({
        message: "The name " + name + " is reserved. Please try another name"
      })); 
      return;
    }

    let controllers = await ControllerApi.getAll();
    let otherControllers = controllers?.filter(c => c.id !== this.state.id);
    let controller = otherControllers?.find(c => c.name === name);

    if (controller) {
      this.setState(() => ({
        message: "A controller with the name " + name + " already exists. Please try another name"
      })); 
      return;
    }

    try {
      const payload = this.getPayloadFromState();
      let result = await ControllerApi.update(this.state.id, payload);
      this.props.history.push(`${this.state.basePath}/controller/` + this.state.id)
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error updating the controller"
      }));
    }
  }

  render() {
    const { 
      name, description, country, region, timezone, ip_address, routingConfig, routingEnabled, isCountryOpen, isRegionOpen, isTimezoneOpen, serverId,
      bgp_configuration_rows, ospf_configuration_rows, activeTabIndex, isDeleteServerConfirmOpen, countries, regions, timezones, servers, editRowMode, mode
    } = this.state;

    const bgpErrorState = routingConfig?.bgp_enabled && routingConfig?.bgp_configuration === "" ? { validated: 'error', errorText: " BGP Configuration must be provided" } : { validated: 'default', errorText: "" };
    const ospfErrorState = routingConfig?.ospf_enabled && routingConfig?.ospf_configuration === "" ? { validated: 'error', errorText: " OSPF Configuration must be provided" } : { validated: 'default', errorText: "" };
    const descriptionErrorState = isLengthWithinRange(description, 1, 255) ? { validated: 'default', errorText: "" } : { validated: 'error', errorText: " Max 255 characters allowed" };  
    const ipAddressErrorState = ip_address === '' || (ip_address !== '' && isValidIP(ip_address)) ? { errorText: '', validated: 'default' } : { errorText: 'Invalid IP Address', validated: 'error' };

    const formInvalid = !name || !country || !region || !timezone || editRowMode || descriptionErrorState.validated === 'error' ||
      bgpErrorState.validated === 'error' || ospfErrorState.validated === 'error' || ipAddressErrorState.validated === 'error';
    const { theme } = this.context;
    const { activeTab, ospfChecked, bgpChecked, ospfText, bgpText } = this.state;

    return (
      <React.Fragment>
        {this.state.isLoading ? ( 
          <Loader />
        ) : (
          <Page
            className={`cr-page px-3 pt-2 controllers-form ${theme}`}
            title=""
            breadcrumbs={[
              { name: <a href="/controllers">Controllers</a>, active: false },
              { name: this.state.mode === "edit" ? "Edit Controller" : "New Controller", active: true },
            ]}
          >
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="controllers-card-wrapper d-flex justify-content-between">
                  <Col lg={3} md={3} sm={12} xs={12} className="me-2">
                    <Card isCompact className="controller-edit-panel rounded-2">
                      <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                        <div className="w-100">                  
                          <IconHeading
                            icon={<MdDomain className="icon-medium" />}
                            heading="Controller"
                          />
                        </div>
                      </CardHeader>
                      <CardBody className="pt-3">
                        <FormGroup label="Controller Name:" isRequired fieldId="name" className="form-item"
                          helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={mode === "new" ? textInputRequiredOnly(name) : 'default'}>
                          <TextInput
                            isRequired type="text" id="name" name="name" autoComplete="new-password" className="form-control"
                            value={name || ''} onChange={(e) => this.handleChange("name", e)} validated={mode === "new" ? textInputRequiredOnly(name) : 'default'}
                            style={{height:"38px"}} placeholder="Controller Name"
                          />
                        </FormGroup>

                        <FormGroup label="IP Address:" fieldId="ip_address" className="form-item"
                          helperTextInvalid={ipAddressErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={ipAddressErrorState.validated}>
                          <TextInput
                            isRequired type="text" id="ip_address" name="ip_address" autoComplete="new-password" className="form-control"
                            value={ip_address || ''} onChange={(e) => this.handleChange("ip_address", e)} validated={ipAddressErrorState.validated}
                            style={{height:"38px"}} placeholder="IP Address"
                          />
                        </FormGroup>

                        <FormGroupSpacer>
                          <FormGroup label="Description:" fieldId="description" isRequired helperTextInvalid={' Required field'} className="form-item" 
                            helperTextInvalidIcon={<ExclamationCircleIcon />} validated={descriptionErrorState.validated}>
                            <TextArea 
                              rows="3" id="description" name="description" autoComplete="new-password"
                              validated={description !== "" ? descriptionErrorState.validated : 'default'}
                              value={description || ''} onChange={(e) => this.handleChange("description", e)} 
                              aria-label="Controller description" className="form-control" placeholder="Description"
                            />
                          </FormGroup>
                        </FormGroupSpacer>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={3} md={3} sm={12} xs={12} className="me-2">
                    <Card isCompact className="controller-edit-panel rounded-2">
                      <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                        <div className="w-100">                  
                          <IconHeading
                            icon={<MdLocationOn className="icon-medium" />}
                            heading="Location"
                          />
                        </div>
                      </CardHeader>
                      <CardBody className="pt-3">
                        <FormGroup label="Country" className="form-item">
                          <Input
                            type="select" 
                            value={this.state.country || ''}
                            onChange={(e) => this.onCountrySelect(e, e.target.value, false)}
                            onClick={() => this.onCountryToggle(!this.state.isCountryOpen)}
                            open={this.state.isCountryOpen}
                            invalid={!country}
                          >
                            {countries &&
                            <>
                              <option key="default" value="">Select country*</option>
                              {countries.map((country, index) => (
                                  <option key={country.key || index} value={country.props.value}>
                                      {country.props.value}
                                  </option>
                              ))}
                            </>
                            }
                          </Input>
                        </FormGroup>

                        <FormGroup label="State / Province" className="form-item">
                          <Input
                            type="select" 
                            value={this.state.region || ''}
                            onChange={(e) => this.onRegionSelect(e, e.target.value, false)}
                            onClick={() => this.onRegionToggle(!this.state.isRegionOpen)}
                            open={this.state.isRegionOpen} 
                            disabled={regions.length === 0}
                            invalid={!region}
                          >
                            {regions &&
                            <>
                              <option key="default" value="">Select region*</option>
                              {regions.map((region) => (
                                  <option key={region.key} value={region.props.value}>
                                      {region.props.value}
                                  </option>
                              ))}
                            </>
                            }
                          </Input>
                        </FormGroup>
                        
                        <FormGroup label="Timezone" className="form-item">
                          <Input
                            type="select" 
                            value={this.state.timezone || ''}
                            onChange={(e) => this.onTimezoneSelect(e, e.target.value, false)}
                            onClick={() => this.onTimezoneToggle(!this.state.isTimezoneOpen)}
                            open={this.state.isTimezoneOpen} 
                            disabled={timezones.length === 0}
                            invalid={!timezone}
                          >
                            {timezones &&
                            <>
                              <option key="default" value="">Select timezone*</option>
                              {timezones.map((timezone) => (
                                  <option key={timezone.key} value={timezone.props.value}>
                                      {timezone.props.value}
                                  </option>
                              ))}
                            </>
                            }
                          </Input>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <Card isCompact className="rounded-2 h-100">
                      <CardHeader className="p-2 pb-0 fw-bold d-flex align-items-center bg-transparent">     
                        <div className="pb-2">
                          <IconHeading
                            icon={<MdAltRoute className="icon-medium" />}
                            heading="Dynamic Routing"
                          />
                        </div>
                        <div className="d-flex ms-2">
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => this.toggleTab('1')}
                              >
                                <span className="pb-1 pt-1">OSPF</span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => this.toggleTab('2')}
                              >
                                <span className="pb-1 pt-1">BGP</span>                              
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </CardHeader>
                      <CardBody className="pt-3">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <FormGroup className="form-item">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  checked={routingConfig?.ospf_enabled}
                                  onChange={this.handleOspfCheckboxChange}
                                  className="me-1"
                                />
                                Enable OSPF
                              </Label>
                            </FormGroup>
                            <FormGroup className="form-item">
                              <Label for="ospfTextarea">OSPF Details</Label>
                              <Input
                                type="textarea"
                                id="ospfTextarea"
                                value={routingConfig?.ospf_configuration}
                                onChange={(e) => this.handleObjectChange('routingConfig', 'ospf_configuration', e)}
                                disabled={!ospfChecked}
                              />
                            </FormGroup>
                          </TabPane>
                          <TabPane tabId="2">
                            <FormGroup className="form-item">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  isChecked={routingConfig?.bgp_enabled}
                                  onChange={this.handleBGPCheckboxChange}
                                  className="me-1"
                                />
                                Enable BGP
                              </Label>
                            </FormGroup>
                            <FormGroup className="form-item">
                              <Label for="bgpTextarea">BGP Details</Label>
                              <Input
                                type="textarea"
                                id="bgpTextarea"
                                value={routingConfig?.bgp_configuration} 
                                onChange={(e) => this.handleObjectChange('routingConfig', 'bgp_configuration', e)} 
                                disabled={!bgpChecked}
                              />
                            </FormGroup>
                          </TabPane>
                        </TabContent>   
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="controllers-card-wrapper d-flex">
                    <Card>
                      <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                        <div className="w-100 d-flex justify-content-between">       
                            <IconHeading icon={<MdAccountTree className="icon-medium" />} heading="Gateways" />

                            <div className="pointer add-section" onClick={this.addServer}>
                              <PlusIcon /> &nbsp;Add Gateway
                            </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Table
                          onRowEdit={this.updateEditableRows}
                          aria-label="Edit Gateways"
                          variant={TableVariant.compact}
                          cells={this.getServerColumns()}
                          rows={servers}
                        >
                          <TableHeader />
                          <TableBody />
                        </Table>
                        <div className="text-center">
                          <MdAddCircle className="controller-add-icon" onClick={this.addServer}/>
                        </div>
                        <br />
                        <span className="error">{this.state.error}</span>
                      </CardBody>
                    </Card>
                </div>
              </Col>
            </Row>

          <Flex>
            <FlexItem className="flex-item-full d-flex justify-content-center">

              <Modal
                title="Confirm Action"
                titleIconVariant={BullhornIcon}
                variant={ModalVariant.small}
                isOpen={isDeleteServerConfirmOpen}
                onClose={this.handleConfirmToggle}
                actions={[
                  <Button key="confirm" variant="primary" onClick={this.deleteServer}>Yes</Button>,
                  <Button key="cancel" variant="link" onClick={this.handleConfirmToggle}>Cancel</Button>
                ]}
              >
                <p>Are you sure you want to delete this gateway?</p>
                {isUUID(serverId) ? <p><b>{serverId}</b></p> : <></> }
              </Modal>

              <Saver 
                submitButtonText="Save" 
                submitButtonisDisabled={formInvalid}
                submit={this.handleSubmit} 
                cancel={this.handleCancel} 
                showCancel={true}
                message={this.state.message}
                messageType={this.state.messageType}
              />
            </FlexItem>
          </Flex>
          
          </Page>
        )}
      </React.Fragment>
    );
  }
});

export default withRouter(ControllerForm);