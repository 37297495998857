import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router-dom";

import { 
  LoginFooterItem, 
  LoginForm, 
  LoginMainFooterBandItem, 
  LoginPage, 
  LoginMainHeader, 
  ListItem 
} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';
import brandImg from '@app/assets/images/generic-login.jpg';
import { Spinner } from '@patternfly/react-core';

// import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import { userIsNotAuthenticated } from '@app/components/layout/auth.component';
import { actionCreators } from '@app/common/auth/auth.reducer';
import { State } from '@app/store/root-reducer';
import { Alert, Button, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import VideoBackground from '@app/components/VideoBackground';
import { FormGroup } from '@mui/material';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      isValidUsername: false,
      isValidPassword: false,
      showHelperText: false,
      helperText: "",
      isLoading: false
    };
  }

  handleUsernameChange = (event) => {
    const value = event.target.value;
    this.setState({ userName: value });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;
    this.setState({ password });
  };

  onLoginButtonClick = (event) => {
    event.preventDefault();
    const { userName, password } = this.state;
    this.setState({ helperText: false });

    if (userName && password) {
      this.setState(() => ({ 
        helperText: "Invalid login credentials",
        showHelperText: false,
        isLoading: true
      }));
      this.login(userName, password);
    } else {
      this.setState(() => ({ 
        isValidUsername: !!userName,
        isValidPassword: !!password,
        helperText: "Username and password are required",
        showHelperText: true,
        isLoading: false
      }));
    }
  };

  login = async (userName, password) => {
    this.setState({
      showHelperText: true,
      helperText: '',
    });
    this.props.dispatch(actionCreators.signInRequest(userName, password));
  }

  render() {
    const registrationLink = process.env.REACT_APP_PORTAL_URL + "/registration/";
    const termsLink = process.env.REACT_APP_APP_TERMS_LINK;
    const helpLink = process.env.REACT_APP_HELP_LINK;
    const privacyLink = process.env.REACT_APP_PRIVACY_POLICY_LINK;
    const forgotPasswordLink = "/forgot-password";
    const { helperText } = this.state;
    const { error, isLoading } = this.props;

    const message = error || helperText;

    const helperText = (
      <React.Fragment>
        {message}
      </React.Fragment>
    );

    const signUpForAccountMessage = (
      <LoginMainFooterBandItem className="text-center mt-3">
        Need an account? <a href={registrationLink} target="_blank">Sign up here!</a>
      </LoginMainFooterBandItem>
    );

    const forgotCredentials = (
      <LoginMainFooterBandItem className="text-center">
        <a href={forgotPasswordLink} target="_blank">Forgot your password?</a>
      </LoginMainFooterBandItem>
    );

    // const loginHeader = (
    //   <LoginMainHeader>
    //     <img src={EthicaLogo} alt="Ethica Logo" />
    //   </LoginMainHeader>
    // );

    const listItem = (
      <React.Fragment>
        <ListItem>
          <LoginFooterItem href={termsLink}>Terms of Use </LoginFooterItem>
        </ListItem>
        <ListItem>
          <LoginFooterItem href={helpLink}>Help</LoginFooterItem>
        </ListItem>
        <ListItem>
          <LoginFooterItem href={privacyLink}>Privacy Policy</LoginFooterItem>
        </ListItem>
      </React.Fragment>
    );

    const loginForm = (
      <>
        <Container className="mt-5" style={{ maxWidth: '400px' }}>
        <h2 className="text-center mb-4">Log in to your account</h2>
        
        {this.state.showHelperText && message && (
          <Alert color="danger">
            {message}
          </Alert>
        )}

        <Form>
          <FormGroup className="mb-3">
            <Label for="email">Email</Label>
            <Input
              type="text"
              id="email"
              placeholder="Username"
              value={this.state.userName}
              onChange={(e) => this.handleUsernameChange(e)}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <Label for="password">Password</Label>
            <Input
              type="password"
              id="password"
              placeholder="Password"
              value={this.state.password}
              onChange={(e) => this.handlePasswordChange(e)}
            />
          </FormGroup>

          <FormGroup className="m-auto pt-3 w-auto">
            <Button color="secondary" className="ethica-button-green w-auto" block onClick={(e) => this.onLoginButtonClick(e)}>
              Login
            </Button>
          </FormGroup>
        </Form>
      </Container>
        {/* <LoginForm
          showHelperText={this.state.showHelperText}
          helperText={helperText}
          usernameLabel="Username"
          usernameValue={this.state.userName}
          onChangeUsername={(e) => this.handleUsernameChange(e)}
          isValidUsername={this.state.isValidUsername}
          passwordLabel="Password"
          passwordValue={this.state.password}
          onChangePassword={(e) => this.handlePasswordChange(e)}
          isValidPassword={this.state.isValidPassword}
          onLoginButtonClick={(e) => this.onLoginButtonClick(e)}
        /> */}
      </>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>Login</title>
        </Helmet>
        <Row className="h-100 w-100 login-page overflow-x-hidden">
          <Col lg={8} md={8} sm={12} xs={12} className="bg-white">
            <div>
              <VideoBackground/>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="bg-white align-content-center">
            <Row>
              <Col lg={8} md={8} sm={8} xs={8} className="m-auto">
                <div className="login-form pb-3">

                  {loginForm}

                  {isLoading ? (
                    <div className="loader m-auto mt-3"></div>
                  ) : (
                    <div className="mt-3" style={{height: "65px"}}>
                    </div>
                  )}

                  {signUpForAccountMessage}

                  {forgotCredentials}

                </div>
                <div className="login-form-footer mt-5">
                  {listItem}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect((state: State) => ({ 
  isLoading: state.common.auth?.isLoading,
  error: state.common.auth?.error,
}))(withRouter(userIsNotAuthenticated(Login)));