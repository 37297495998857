import React from "react";
import ReactDOM from "react-dom";
import { registerSelectors } from 'reselect-tools'
import App from '@app/index';
import CombinedProvider from '@app/providers/CombinedProvider';
import { CartProvider } from '@app/contexts/cart.context';

import * as configurationSelectors from './app/common/configuration/configuration.selectors';

registerSelectors(configurationSelectors);

ReactDOM.render(
<CombinedProvider>
    <CartProvider>
        <App />
    </CartProvider>
</CombinedProvider>, 
document.getElementById("root") as HTMLElement);