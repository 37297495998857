// @ts-nocheck
import { CHECK_TOKEN_EXPIRATION_FREQ } from '../lib/constants';

var UserSession = (function () {
  const itemKey = 'et';
  let isLoggedIn = false;
  let userTimeout;

  // TODO: Refactor this and getPartnerId, getUserId, getClientCompanyIds, getToken 
  //       out as the new getParam() will now handle it
  var getPartnerId = function () {
    const item = JSON.parse(localStorage.getItem(itemKey));
    return item ? item.partnerId : undefined;
  };

  var getCourseId = function () {
    const item = JSON.parse(localStorage.getItem(itemKey));
    return item ? item.courseId : undefined;
  };

  var getUserId = function () {
    const item = JSON.parse(localStorage.getItem(itemKey));
    return item ? item.userId : undefined;
  };

  var clear = function () {
    localStorage.removeItem(itemKey);
    clearTimeout(userTimeout);
  };

  var setParams = function (params) {
    localStorage.setItem(itemKey, JSON.stringify(params));
    // const item = JSON.parse(localStorage.getItem(itemKey));
    // localStorage.setItem(itemKey, JSON.stringify({ ...item, ...params }));
  };

  var setParam = function (param, value) {
    const item = JSON.parse(localStorage.getItem(itemKey)) || {};
    item[param] = value;
    localStorage.setItem(itemKey, JSON.stringify(item));
  };

  var hasToken = function () {
    const item = JSON.parse(localStorage.getItem(itemKey));
    const token = item ? item.token : undefined;
    const expireTime = item ? new Date(item.expiration) : undefined;
    const now = new Date();
    const hasValidToken = (token && expireTime > now) ? true : false;

    if (!hasValidToken) {
      forceLogin();
    }
    else {
      userTimeout = setTimeout(() => { hasToken() }, CHECK_TOKEN_EXPIRATION_FREQ);
    }
    
    return hasValidToken;
  };

  var forceLogin = function () {
    clear();
  };

  var getToken = function () {
    const item = JSON.parse(localStorage.getItem(itemKey));
    return item ? item.token : undefined; 
  };

  var getClientCompanyIds = function () {
    const item = JSON.parse(localStorage.getItem(itemKey));
    return item ? item.clientCompanyIds : undefined;
  };

  var getParam = function (paramName) {
    const item = JSON.parse(localStorage.getItem(itemKey));
    return item ? item[paramName] : undefined;
  };

  return {
    getPartnerId: getPartnerId,
    getCourseId: getCourseId,
    getUserId: getUserId,
    getClientCompanyIds: getClientCompanyIds,
    hasToken: hasToken,
    getToken: getToken,
    clear: clear,
    setParams: setParams,
    setParam: setParam,
    getParam: getParam
  }

})();

export default UserSession;
