import React from 'react';
import loginVideo from '@app/assets/video/ethica-bg.mp4';
import defaultLogo from '@app/assets/images/logo/cloud-access-logo-white.png'
import { selectPageTitle } from '@app/common/session/session.selectors';
import { useSelector } from 'react-redux';

const VideoBackground = () => {
  let pageTitle = useSelector(selectPageTitle) ?? 'Orchestrator';

  console.log('pageTitle',pageTitle);

  return (
    <div className="video-container">
      <video 
        autoPlay 
        loop 
        muted 
        playsInline 
        className="video-content"
      >
        <source src={loginVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay">
        <h1 className="video-text mt-3">Welcome to {pageTitle}!</h1>
      </div>
      <div className="video-bottom">
          <img
            src={defaultLogo}
            width="150"
            alt=""
          />
      </div>
    </div>
  );
};
  
  export default VideoBackground;