import * as React from 'react';
import { Helmet } from 'react-helmet';
import Wan from '@app/api/wan/wan.actions';
import getAllGatewayIds from '@app/pages/wan/WanHelper';
import Site from '@app/api/site/site.actions';
import Server from '@app/api/gateway/gateway.actions';
import Controller from '@app/api/controller/controller.actions';
import { countChars, emptyObject } from '@app/lib/functions';
import { textInputRequiredOnly, isLengthWithinRange, isValidIP, isValidSubnet, ipInSubnet } from '@app/lib/validator';
import { IconHeading } from '@app/components/IconHeading';
import { Saver } from '@app/components/Saver';
import { truncateString } from '@app/utils/utils';
import WanIcon from '@app/bgimages/wans-black.svg';
import ACLProfile from '@app/api/aclprofile/aclprofile.actions';
import { TopologyIcon, LockIcon, DomainIcon, InfrastructureIcon, ExclamationCircleIcon, ServiceCatalogIcon } from '@patternfly/react-icons';
import { Flex, FlexItem, PageSection, Title, Chip, ChipGroup, FormGroup, TextInput, Select, SelectOption, SelectVariant, TextArea, Spinner } from "@patternfly/react-core";
import { Table, TableHeader, TableBody, TableVariant, cancelCellEdits, validateCellEdits, applyCellEdits, EditableTextCell, EditableSelectInputCell } from '@patternfly/react-table';
import { FormGroupSpacer } from '../../components/forms/pf/form-group-spacer.component';
import loaderLogo from '@app/assets/images/logo/CloudAccess-Logo-BlkCloud.jpg';

import './Wan.css';
import Page from '@app/components/Page';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { MdWidthWide } from 'react-icons/md';
import Loader from '@app/components/Loader';

class WanForm extends React.Component {
  constructor(props) {
    super(props);

    const id = this.props.computedMatch.params.id;

    this.state = {
      id: id,
      name: "",
      description: "",
      isEncrypted: false,
      sites: [],
      wans: [],
      wan: {},
      servers: [],
      controllers: [],
      siteSubnets: [],
      origAccessibleIps: [],
      activeController: {},
      activeGateway: {},
      standbyController: {},
      standbyGateway: {},      
      isActiveControllerOpen: false,
      isActiveGatewayOpen: false,
      isStandbyControllerOpen: false,
      isStandbyGatewayOpen: false,
      failoverEnabled: false,
      failoverEnabledCheckboxDisabled: true,
      isACLProfileOpen: false,
      aclProfiles: [],
      aclProfileOptions: [],
      message: "",
      messageType: "",
      error: "",
      dataLoading: false,
      editRowMode: false,
      isIpAccessLevelOpen: false,
      mode: id ? "edit" : "new"
    }
  }

  componentDidMount = () => {
    try {
      this.loadWan();
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error loading the WAN"
      }));
    }
  }

  loadWan = async () => {
    const controllers = await Controller.getAll();
    const wans = await Wan.getAll();
    const servers = await Server.getAll();
    const notServerless = controllers.filter(c => c.name !== "Serverless");
    const allAclProfiles = await ACLProfile.getAll();
    let aclProfileOptions = [];

    if (allAclProfiles) {
      aclProfileOptions = allAclProfiles.map(({ id, inbound_rules, outbound_rules, ...profiles }) => profiles);
      aclProfileOptions = aclProfileOptions.map(profile => {
        return {
          value: profile.name,
          description: truncateString(profile.description, 80),
          disabled: false
        }
      });
    }

    if (this.state.mode === "new") {
      this.setState(() => ({
        wans: wans,
        controllers: notServerless,
        aclProfileOptions: [...aclProfileOptions]
      }));
    }
    else if (this.state.mode === "edit") {
      const { id } = this.state;
      const wan = await Wan.get(id);
    
      if (wan === undefined) {
        this.props.history.push("/notfound");
      }

      const sites = await Site.getSitesByWan(id);
      console.log(sites);
      let wanGateways = getAllGatewayIds(wans);
      wanGateways = wanGateways.filter(wanId => wanId !== wan.activeGatewayId);
      const siteSubnets = sites.map(site => { return this.getRow(site, false); });
      const origAccessibleIps = sites.map(site => { return { id: site.id, accessibleIps: site.accessibleIps, ipAccessLevel: site.ipAccessLevel }});

      // Active Controller
      let activeController = notServerless.find(c => c.id === wan.activeGatewayId);
      const activeGateway = servers.find(s => s.id === wan.activeServerId);
      const activeGateways = activeGateway.Gateway.Servers.filter(s => {
        return s.gatewayId === wan.activeGatewayId && !wanGateways.includes(wan.activeGatewayId) && s.Tunnels?.length === 0
      });
      activeController.Servers = [...activeGateways];

      // Standby Controller
      let standbyController = notServerless.find(c => c.id === wan.standbyGatewayId);
      const standbyGateway = standbyController ? servers.find(s => s.id === wan.standbyServerId) : "";
      const standbyGateways = standbyController ? standbyGateway.Gateway.Servers.filter(s => { 
        return s.gatewayId === wan.standbyGatewayId && !wanGateways.includes(wan.standbyGatewayId) && s.Tunnels?.length === 0
      }) : [];
      if (standbyController) {
        standbyController.Servers = [...standbyGateways];
      }
      const standbyControllers = notServerless.filter(c => { 
        return c.name !== activeController.name && c.name !== 'Serverless'
      });

      let aclProfiles = await ACLProfile.getAssignedACLs(this.state.id);
      aclProfiles = aclProfiles.map(acl => { return acl.name });

      this.setState(() => ({
        name: wan.name,
        description: wan.description || "",
        isEncrypted: wan.isEncrypted,
        sites: sites,
        wans: wans,
        wan: wan,
        servers: servers,
        aclProfiles: [...aclProfiles],
        aclProfileOptions: [...aclProfileOptions],
        failoverEnabledCheckboxDisabled: false,
        origAccessibleIps: origAccessibleIps,
        controllers: [...notServerless],
        standbyControllers: [...standbyControllers],
        siteSubnets: [...siteSubnets],
        activeController: {...activeController},
        activeGateway: {...activeGateway},
        standbyController: {...standbyController} || "",
        standbyGateway: {...standbyGateway} || "",
        failoverEnabled: standbyController && standbyGateway
      }));
    }
  }

  onACLProfileSelect = (event, selection) => {
    const { aclProfiles } = this.state;

    if (aclProfiles.includes(selection)) {
      this.setState(prev => ({
        aclProfiles: prev.aclProfiles.filter(item => item !== selection)
      }));
    }
    else {
      this.setState(prev => ({
        aclProfiles: [...prev.aclProfiles, selection] 
      }));
    }
  };

  clearACLProfileSelection = () => {
    this.setState({
      aclProfiles: [],
      isACLProfileOpen: false
    });
  };

  chipGroupComponent = () => {
    const { aclProfiles } = this.state;

    return (
      <ChipGroup>
        {(aclProfiles || []).map((currentChip, index) => (
          <Chip
            isReadOnly={index === 0 ? true : false}
            key={currentChip}
            onClick={event => this.onACLProfileSelect(event, currentChip)}
          >
            {currentChip}
          </Chip>
        ))}
      </ChipGroup>
    );
  };

  getSiteSubnetColumns = () => {
    return ['LAN IP', 'Netmask', 'Accessible IPs', 'IP Addresses'];
  }

  getCardClass = (section, enabled) => {
    return enabled ? section + "-form-row card-enabled" : section + "-form-row card-disabled";
  }

  updateEditableRows = async (evt, type, isEditable, rowIndex, validationErrors) => {
    let newRows = Array.from(this.state.siteSubnets);

    if (validationErrors && Object.keys(validationErrors).length) {
      newRows[rowIndex] = validateCellEdits(newRows[rowIndex], type, validationErrors);
      this.setState({ siteSubnets: newRows });
      return;
    }
    
    if (type === 'edit' && Object.keys(validationErrors).length === 0) {
      this.setState({ editRowMode: true });
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);
      return;
    }

    if (type === 'cancel') {
      const { origAccessibleIps } = this.state;
      if (this.state.error !== "") {
        const siteId = newRows[rowIndex].cells[4].props.value;
        const origObj = origAccessibleIps.find(ip => ip.id === siteId);
        newRows[rowIndex].cells[2].props.value = origObj.ipAccessLevel;
        newRows[rowIndex].cells[3].props.value = origObj.accessibleIps;
      }
      newRows[rowIndex] = cancelCellEdits(newRows[rowIndex]);
      this.setState({ siteSubnets: newRows, editRowMode: false, error: "" });
      return;
    }

    if (type === 'save') {
      newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);

      let ipCheck = { isValid: true, error: "" };
      const row = newRows[rowIndex];
      const lanSubnet = row.cells[0].props.value + row.cells[1].props.value;
      const ipAccessLevel = row.cells[2].props.value;
      const accessibleIps = row.cells[3].props.value.replaceAll(" ", "");

      // Accessible IPs must be a comma separated list of valid IP addresses and/or 
      // subnets in CIDR format. Only applicable when ipAccessLevel is set to "Specific"
      if (ipAccessLevel === "Specific") {
        if (accessibleIps === "" || countChars(accessibleIps, ",") === 0) {
          ipCheck = this.validateAccessibleIp(accessibleIps, lanSubnet);
        }
        else {  
          const csv = accessibleIps.split(',');
          for (var i = 0; i < csv.length; i++) {
            ipCheck = this.validateAccessibleIp(csv[i], lanSubnet);
            if (!ipCheck.isValid) { break; }
          }   
        }
      }

      // Remove any spaces from comma separated IPs
      row.cells[3].props.value = row.cells[3].props.value.replaceAll(" ", "");

      if (!ipCheck.isValid) {
        newRows[rowIndex] = applyCellEdits(newRows[rowIndex], type);
        this.setState({ editRowMode: true, error: ipCheck.error });
      }
      else {
        this.setState({ siteSubnets: [...newRows], editRowMode: false, error: "" });
      }
    }
  }

  validateAccessibleIp = (ip, lanSubnet) => {
    if (!isValidIP(ip) && !isValidSubnet(ip)) {
      return { isValid: false, error: ip + " must be a valid IP or CIDR format" }
    }
    if (!ipInSubnet(ip, lanSubnet)) {
      return { isValid: false, error: ip + " must exist in LAN subnet " + lanSubnet }
    }

    return { isValid: true, error: "" }
  }

  getRow = (site, isEditable) => {
    var cells = [];
    let accessibleIpsDisabled = false;

    if (site.ipAccessLevel !== "Specific") {
      accessibleIpsDisabled = true;
    }

    cells.push(this.getTextCell(site.SiteLans[0], "lanSegmentIp4", true, false));
    cells.push(this.getTextCell(site.SiteLans[0], "lanCidrIp4", true, false));
    cells.push(this.getSelectCell(site, "ipAccessLevel", false));
    cells.push(this.getTextCell(site, "accessibleIps", false, accessibleIpsDisabled));
    cells.push(this.getTextCell(site, "id", true, false));

    const row = {
      rowEditValidationRules: [],
      cells: cells
    }

    row.isEditable = isEditable;
 
    return row;
  } 

  getSelectCell = (site, columnName, readOnly) => {
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableSelectInputCell
          value={value}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          onSelect={this.onIpAccessLevelSelect}
          isOpen={props.isSelectOpen}
          options={props.options.map((option, index) => {
            return (
              <SelectOption 
                id={'ip-access-level-' + index} key={index} value={option.value} 
                isPlaceholder={option.isPlaceholder} 
              />
            );
          })}
          onToggle={isOpen => {
            this.onIpAccessLevelToggle(isOpen, rowIndex, cellIndex);
          }}
          selections={props.selected}
        />
      ),
      props: {
        value: site[columnName],
        name: columnName,
        isSelectOpen: props.isSelectOpen || false,
        selected: props.selected && props.selected.length ? props.selected : site[columnName],
        options: [
          { value: 'All' },
          { value: 'Specific' },
          { value: 'None' }
        ]
      }
    }

    return cell;
  }

  getTextCell = (site, columnName, readOnly, isDisabled) => {
    if (readOnly) {
      return { 
        title: site?.[columnName] || "",
        props: {
          value: site?.[columnName],
          name: columnName
        }
      };
    }
    
    const cell = {
      title: (value, rowIndex, cellIndex, props) => (
        <EditableTextCell
          value={value || ""}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          props={props}
          isDisabled={isDisabled}
          handleTextInputChange={this.handleTextInputChange}
          inputAriaLabel={columnName}
        />
      ),
      props: {
        value: site[columnName],
        name: columnName
      }
    }

    return cell;
  }

  onIpAccessLevelSelect = (newValue, evt, rowIndex, cellIndex, isPlaceholder) => {
    const newRows = Array.from(this.state.siteSubnets);
    const newCellProps = newRows[rowIndex].cells[cellIndex].props;
    let accessibleIpsDisabled = false;
    let accessibleIps = newRows[rowIndex].cells[3].props.value;

    if (isPlaceholder) {
      newCellProps.editableValue = [];
      newCellProps.selected = [];
    } 
    else {
      if (newCellProps.editableValue === undefined) {
        newCellProps.editableValue = [];
      }

      let newSelected = Array.from(newCellProps.selected);
      newSelected = newValue;
      newCellProps.editableValue = newSelected;
      newCellProps.selected = newSelected;
    }

    if (newValue !== "Specific") {
      accessibleIpsDisabled = true;
      accessibleIps = "";
    }

    // A hack to be able to conditionally disable the accessibleIps text field when ipAccessLevel !== "Specific"
    newRows[rowIndex].cells[3] = this.getTextCell(
      { accessibleIps: accessibleIps }, "accessibleIps", false, accessibleIpsDisabled
    );

    // Close the ipAccessLevel dropdown
    newRows[rowIndex].cells[2].props.isSelectOpen = false;

    this.setState({
      siteSubnets: [...newRows]
    });
  }

  onIpAccessLevelToggle = (isOpen, rowIndex, cellIndex) => {
    let newRows = Array.from(this.state.siteSubnets);
    newRows[rowIndex].cells[cellIndex].props.isSelectOpen = isOpen;
    this.setState({
      siteSubnets: [...newRows]
    });
  }

  onSelectToggle = (isOpen, isOpenStateVar) => {
    this.setState(() => ({ 
      [isOpenStateVar]: isOpen
    }));
  }
  
  clearActiveController = () => {
    this.setState({
      [stateObject]: null,
      [isOpenStateVar]: false
    });
  }

  onActiveControllerSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController("activeController", "isActiveControllerOpen");
    }
    else {
      const { controllers, wans, wan, standbyController } = this.state;
      let wanGateways = getAllGatewayIds(wans);
      const activeController = controllers.find(c => c.name === selection);
      const activeGateways = activeController.Servers.filter(s => {
        return (s.gatewayId === activeController?.id && s.Tunnels?.length === 0 && !wanGateways.includes(s.id)) || (wan.activeServerId && s.id === wan.activeServerId);
      });
      const standbyControllers = controllers.filter(c => { return c.name !== selection });

      this.setState(prevState => ({
        activeController: {
          ...prevState.activeController,
          id: activeController.id,
          name: selection,
          Servers: [...activeGateways]
        },
        failoverEnabledCheckboxDisabled: false,
        activeGateway: {},
        standbyControllers: [...standbyControllers],
        standbyController: selection === standbyController.name ? {} : { ...prevState.standbyController },
        standbyGateway: selection === standbyController.name ? {} : { ...prevState.standbyGateway },
        isActiveControllerOpen: false 
      }));
    }
  }

  onStandbyControllerSelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController("standbyController", "isStandbyControllerOpen");
    }
    else {
      const { controllers, wans, wan } = this.state;
      let wanGateways = getAllGatewayIds(wans);
      const standbyController = controllers.find(c => c.name === selection);
      const standbyGateways = standbyController.Servers.filter(s => { 
        return (s.Tunnels?.length === 0 && !wanGateways.includes(s.id)) || (wan.standbyServerId && s.id === wan.standbyServerId) 
      });

      this.setState(prevState => ({
        standbyGateway: {},
        standbyController: {
          ...prevState.standbyController,
          id: standbyController.id,
          name: selection,
          Servers: standbyGateways
        },
        isStandbyControllerOpen: false 
      }));
    }
  }

  onActiveGatewaySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController("activeGateway", "isActiveGatewayOpen");
    }
    else {
      const { activeController } = this.state;
      const activeGateway = activeController.Servers.find(s => s.ip4Address === selection);

      this.setState(() => ({
        activeGateway: {...activeGateway},
        isActiveGatewayOpen: false 
      }));
    }
  }

  onStandbyGatewaySelect = (event, selection, isPlaceholder) => {
    if (isPlaceholder) {
      this.clearActiveController("standbyGateway", "isStandbyGatewayOpen");
    }
    else {
      const { standbyController } = this.state;
      const standbyGateway = standbyController.Servers.find(s => s.ip4Address === selection);

      this.setState(() => ({
        standbyGateway: {...standbyGateway},
        isStandbyGatewayOpen: false 
      }));
    }
  }

  handleFailoverEnabled = (checked) => {
    const { standbyController, standbyGateway } = this.state; 
    this.setState({ 
      failoverEnabled: checked,
      standbyController: checked ? {...standbyController} : {},
      standbyGateway: checked ? {...standbyGateway} : {}
    });
  }

  handleTextInputChange = (newValue, evt, rowIndex, cellIndex) => {
    let newRows = Array.from(this.state.siteSubnets);
    newRows[rowIndex].cells[cellIndex].props.editableValue = newValue;
    this.setState(() => ({
      siteSubnets: newRows
    }));
  }

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  handleEncryptionEnabled = (checked) => {
    this.setState({ 
      isEncrypted: checked 
    });
  }

  getActiveControllerOptions = () => {
    const { controllers } = this.state;
    return controllers.map(controller => {
      return <SelectOption key={controller.id} id={controller.id} value={controller.name} />
    });
  }

  getActiveGatewayOptions = () => {
    const { activeController } = this.state;
    if (!activeController.Servers) {
      return [];
    }
    return activeController.Servers.map(gateway => {
      return <SelectOption key={gateway.id} id={gateway.id} value={gateway.ip4Address} />
    });
  }

  getStandbyControllerOptions = () => {
    const { standbyControllers, failoverEnabled } = this.state;

    if (failoverEnabled) {
      return standbyControllers.map(controller => {
        return <SelectOption key={controller.id} id={controller.id} value={controller.name} />
      });
    }
  }

  getStandbyGatewayOptions = () => {
    const { standbyController, failoverEnabled } = this.state;

    if (failoverEnabled) {
      if (!standbyController.Servers) {
        return [];
      }
      return standbyController.Servers.map(gateway => {
        return <SelectOption key={gateway.id} id={gateway.id} value={gateway.ip4Address} />
      });
    }
  }

  handleCancel = () => {
    if (this.state.id) {
      this.props.history.push('/wan/' + this.state.id)
    }
    else {
      this.props.history.push('/wans')
    }
  }

  handleSubmit = async (event) => {
    if (this.state.mode === "edit") {
      this.updateWan();
    }
    else if (this.state.mode === "new") {
      this.createWan();
    }
  }

  getPayloadFromState = () => {
    const { name, description, isEncrypted, activeController, activeGateway, standbyController, 
      standbyGateway, siteSubnets 
    } = this.state;

    let payload = { 
      name: name, 
      description: description, 
      isEncrypted: isEncrypted, 
      activeGatewayId: activeController.id,
      activeServerId: activeGateway.id,
      siteSubnets: siteSubnets
    };

    if (standbyController && standbyController.id && standbyGateway && standbyGateway.id) {
      payload.standbyGatewayId = standbyController.id;
      payload.standbyServerId = standbyGateway.id;
    }
    else {
      payload.standbyGatewayId = null;
      payload.standbyServerId = null;
    }

    return payload;
  }

  createWan = async () => {
    try {
      const { aclProfiles } = this.state;
      const payload = this.getPayloadFromState();
      let result = await Wan.create(payload);
      await ACLProfile.setWanAssignments(aclProfiles, result.id);
      this.props.history.push('/wan/' + result.id);
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error creating the WAN"
      }));
    }
  }

  updateWan = async () => {
    try {
      const { id, sites, aclProfiles } = this.state;
      const payload = this.getPayloadFromState();
      let result = await Wan.update(id, payload, sites);
      await ACLProfile.setWanAssignments(aclProfiles, id);
      this.props.history.push('/wan/' + id);
    }
    catch (error) {
      console.log(error)
      this.setState(() => ({
        message: "There was an error updating the WAN"
      }));
    }
  }

  render() {
    const { name, description, isEncrypted, siteSubnets, activeController, activeGateway, standbyController, 
      standbyGateway, aclProfiles, aclProfileOptions, isActiveControllerOpen, isActiveGatewayOpen, isStandbyControllerOpen, isStandbyGatewayOpen, 
      isACLProfileOpen, failoverEnabled, failoverEnabledCheckboxDisabled, editRowMode, dataLoading, error, mode 
    } = this.state;
    const encryptionCardClass = this.getCardClass("wan-settings", isEncrypted);
    const aclProfileCardClass = this.getCardClass("wan-settings", aclProfiles.length > 0);
    const activeControllers = this.getActiveControllerOptions();
    const activeGateways = this.getActiveGatewayOptions();
    const standbyControllers = this.getStandbyControllerOptions();
    const standbyGateways = this.getStandbyGatewayOptions();
    const descriptionErrorState = isLengthWithinRange(description, 1, 255) ? { validated: 'default', errorText: "" } : { validated: 'error', errorText: " Max 255 characters allowed" };

    const { theme } = this.context;
    const formInvalid = !name 
      || !description 
      || emptyObject(activeController) 
      || emptyObject(activeGateway) 
      || editRowMode 
      || descriptionErrorState.validated === 'error'
      || failoverEnabled && (emptyObject(standbyController) || emptyObject(standbyGateway));

    return (
      <React.Fragment> 
        {dataLoading ? (
          <Loader />
        ) : (
          <Page
            className={`cr-page px-3 pt-2 wans-form position-relative ${theme}`}
            title=""
            breadcrumbs={[
              { name: <a href="/wans">Sites</a>, active: false },
              { name: this.state.mode === "edit" ? "Edit WAN" : "New WAN", active: true },
            ]}
          >
            <>
              <Row>
                <Col>
                  <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                    <Card className="w-100">
                      <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                        <div className="w-100 d-flex justify-content-between">
                          <div className="w-100">
                            <IconHeading icon={<MdWidthWide className="icon-medium"/>} heading="WAN" />
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Card>     
                              <CardBody>     
                                <FormGroup label="WAN Name:" isRequired fieldId="name" className="form-item"
                                    helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(name)}>
                                    <TextInput
                                      isRequired type="text" id="name" name="name" autoComplete="new-password" className="form-control"
                                      value={name} onChange={(e) => this.handleChange("name", e)} validated={textInputRequiredOnly(name)}
                                    />
                                </FormGroup>
                                <FormGroup label="Description:" fieldId="description" className="form-item"
                                  helperTextInvalid={descriptionErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={descriptionErrorState.validated}>
                                  <TextArea 
                                    isRequired rows="3" id="description" name="description" autoComplete="new-password" className="form-control"
                                    value={description} onChange={(e) => this.handleChange("description", e)} validated={descriptionErrorState.validated}
                                    aria-label="WAN description" 
                                  />
                                </FormGroup>
                              </CardBody>     
                            </Card>
                          </Col>

                          <Col>
                            <Card>
                              <CardHeader>
                                <div className="w-100 d-flex justify-content-between sub-heading">
                                  <div className="w-100">  
                                    <IconHeading 
                                      icon={<InfrastructureIcon className="icon-medium" />} heading="Controller" enableText="Enable Failover"
                                      showEnable={true} handleEnable={this.handleFailoverEnabled} enabled={failoverEnabled} checkboxDisabled={failoverEnabledCheckboxDisabled}
                                    />
                                  </div>
                                </div>
                              </CardHeader>
                              <CardBody>
                                <FormGroup label="Active Controller" isRequired={true} fieldId="active-controller-toggle" className="form-item"
                                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(activeController.name)}>
                                  <Input
                                    type="select" 
                                    value={activeController.name || ''}
                                    onChange={(e, selection, isPlaceholder) => this.onActiveControllerSelect(e, e.target.value, isPlaceholder)}
                                    onClick={(e) => this.onSelectToggle(e, "isActiveControllerOpen")}
                                    open={isActiveControllerOpen} 
                                  >
                                    {activeControllers &&
                                    <>
                                      <option key="default" value="">Select...</option>
                                      {activeControllers.map((activeController, index) => (
                                          <option key={activeController.key || index} value={activeController.props.value}>
                                              {activeController.props.value}
                                          </option>
                                      ))}
                                    </>
                                    }
                                  </Input>
                                </FormGroup>

                                <FormGroup label="Standby Controller" isRequired={failoverEnabled} fieldId="standby-controller-toggle" className="form-item"
                                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={failoverEnabled ? textInputRequiredOnly(standbyController.name) : 'default'}>
                                  <Input
                                    type="select" 
                                    value={standbyController.name || ''}
                                    onChange={(e, selection, isPlaceholder) => this.onStandbyControllerSelect(e, e.target.value, isPlaceholder)}
                                    onClick={(e) => this.onSelectToggle(e, "isStandbyControllerOpen")}
                                    open={isStandbyControllerOpen} 
                                    disabled={!failoverEnabled}
                                  >
                                    {standbyControllers &&
                                    <>
                                      <option key="default" value="">Select...</option>
                                      {standbyControllers.map((standbyController, index) => (
                                          <option key={standbyController.key || index} value={standbyController.props.value}>
                                              {standbyController.props.value}
                                          </option>
                                      ))}
                                    </>
                                    }
                                  </Input>
                                </FormGroup>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col>
                            <Card>
                              <CardHeader>
                                <div className="w-100 d-flex justify-content-between">
                                  <IconHeading icon={<DomainIcon className="icon-medium" />} heading="Tunnel Gateway" />
                                </div>
                              </CardHeader>
                              <CardBody>
                                <FormGroup label="Active Gateway" isRequired={true} fieldId="active-server-toggle" className="form-item"
                                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(activeGateway.ip4Address)}>
                                  <Input
                                    type="select" 
                                    value={activeGateway.ip4Address || ''}
                                    onChange={(e, selection, isPlaceholder) => this.onActiveGatewaySelect(e, e.target.value, isPlaceholder)}
                                    onClick={(e) => this.onSelectToggle(e, "isActiveGatewayOpen")}
                                    open={isActiveGatewayOpen} 
                                  >
                                    {activeGateways &&
                                    <>
                                      <option key="default" value="">Select...</option>
                                      {activeGateways.map((activeGateway, index) => (
                                          <option key={activeGateway.key || index} value={activeGateway.props.value}>
                                              {activeGateway.props.value}
                                          </option>
                                      ))}
                                    </>
                                    }
                                  </Input>
                                </FormGroup>

                                <FormGroup label="Standby Gateway" isRequired={failoverEnabled} fieldId="standby-server-toggle" className="form-item"
                                  helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={failoverEnabled ? textInputRequiredOnly(standbyGateway.ip4Address) : 'default'}>
                                  <Input
                                    type="select" 
                                    value={standbyGateway.ip4Address || ''}
                                    onChange={(e, selection, isPlaceholder) => this.onStandbyGatewaySelect(e, e.target.value, isPlaceholder)}
                                    onClick={(e) => this.onSelectToggle(e, "isStandbyGatewayOpen")}
                                    open={isStandbyGatewayOpen} 
                                    disabled={!failoverEnabled}
                                  >
                                    {standbyGateways &&
                                    <>
                                      <option key="default" value="">Select...</option>
                                      {standbyGateways.map((standbyGateway, index) => (
                                          <option key={standbyGateway.key || index} value={standbyGateway.props.value}>
                                              {standbyGateway.props.value}
                                          </option>
                                      ))}
                                    </>
                                    }
                                  </Input>
                                </FormGroup>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col>
                            <Card>
                              <CardHeader>
                                  <div className="w-100 d-flex justify-content-between">
                                    <div className="w-100">  
                                      <IconHeading 
                                        icon={<LockIcon className="icon-medium" />} heading="Encryption"
                                        showEnable={true} handleEnable={this.handleEncryptionEnabled} enabled={isEncrypted}
                                      />
                                    </div>
                                  </div>
                              </CardHeader>
                              <CardBody>
                                <p className="font-12">If enabled, all tunnel traffic will be encrypted at 128-bit using a 256-bit key</p>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col>
                            <Card>
                              <CardHeader>
                                  <div className="w-100 d-flex justify-content-between">
                                    <IconHeading icon={<ServiceCatalogIcon className="icon-medium" />} heading="ACL Profiles" />
                                  </div>
                              </CardHeader>
                              <CardBody>
                                <FormGroup className="form-item">
                                  <Select
                                    maxHeight={300}
                                    chipGroupProps={{ numChips: 1, expandedText: 'Hide', collapsedText: 'Show ${remaining}' }}
                                    variant={SelectVariant.typeaheadMulti}
                                    typeAheadAriaLabel="Select ACL Profile(s)"
                                    onToggle={(e) => this.onSelectToggle(e, 'isACLProfileOpen')}
                                    onSelect={this.onACLProfileSelect}
                                    onClear={this.clearACLProfileSelection}
                                    selections={aclProfiles}
                                    isOpen={isACLProfileOpen}
                                    aria-labelledby="multi-typeahead-render-chip-group-props-id-1"
                                    placeholderText="Select ACL Profile(s)"
                                    chipGroupComponent={this.chipGroupComponent()}
                                    className="form-control"
                                  >
                                    {aclProfileOptions.map((option, index) => (
                                      <SelectOption
                                        isDisabled={option.disabled}
                                        key={index}
                                        value={option.value}
                                        {...(option.description && { description: option.description })}
                                      />
                                    ))}
                                  </Select>
                                </FormGroup>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>

              {mode === 'edit' && (
                <Row>
                  <Col>
                    <div className="p-3 w-100 h-100 sites-card-wrapper d-flex justify-content-between">
                      <Card isCompact className="mb-3 w-100">
                        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
                            <div className="w-100 d-flex justify-content-between">
                              <IconHeading icon={<TopologyIcon className="icon-medium" />} heading="Site Subnets" /><br />
                            </div>
                        </CardHeader>
                        <CardBody>
                          <Table
                            onRowEdit={this.updateEditableRows}
                            aria-label="Edit IP Addresses"
                            variant={TableVariant.compact}
                            cells={this.getSiteSubnetColumns()}
                            rows={siteSubnets}
                          >
                            <TableHeader />
                            <TableBody />
                          </Table>
                          <br />
                          <span className="right error">{error}</span>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              )}

              <Saver 
                submitButtonText="Save" 
                submitButtonisDisabled={formInvalid}
                submit={this.handleSubmit} 
                cancel={this.handleCancel} 
                showCancel={true}
                message={this.state.message}
                messageType={this.state.messageType}
              />
            </>
          </Page>
        )}
      </React.Fragment>
    );
  }
}

export { WanForm };
