
export const getNamesFromFullname = (contact) => {
  if (!contact || contact === '') {
    return { first_name: '', last_name: '' };
  }

  const nameParts = contact.split(' ');
  const firstName = nameParts?.length > 0 ? nameParts[0].trim() : "";
  let lastName = nameParts?.length > 1 ? nameParts[1].trim() : "";
  
  if (nameParts?.length > 2) {
    lastName = nameParts.slice(1, nameParts.length).join(' ').trim();
  }
  
  return { 
    first_name: firstName, 
    last_name: lastName 
  };
}
