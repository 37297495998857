import Page from '@app/components/Page';
import React, { useState } from 'react';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import { Col, Row } from 'reactstrap';

const mockDocumentation = {
  sections: [
    {
      id: 'getting-started',
      title: 'Getting Started',
      articles: [
        {
          id: 'introduction',
          title: 'Introduction',
          content: 'Welcome to the documentation! This section will help you get started.',
        },
        {
          id: 'installation',
          title: 'Installation',
          content: 'To install the application, follow these steps: ...',
        },
      ],
    },
    {
      id: 'features',
      title: 'Features',
      articles: [
        {
          id: 'feature-one',
          title: 'Feature One',
          content: 'Feature One allows you to ...',
        },
        {
          id: 'feature-two',
          title: 'Feature Two',
          content: 'Feature Two is designed to help you ...',
        },
      ],
    },
  ],
};

const DocumentationView = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedArticle, setSelectedArticle] = useState(null);
  const { theme } = React.useContext(ThemeContext);

  const filteredSections = mockDocumentation.sections.map((section) => ({
    ...section,
    articles: section.articles.filter(
      (article) =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        article.content.toLowerCase().includes(searchQuery.toLowerCase())
    ),
  })).filter((section) => section.articles.length > 0);

  return (
    <Page 
    className={`cr-page cart-page px-3 pt-2 ${theme}`} 
    title="" 
    breadcrumbs={[{ name: 'Documentation', active: true }]}
    >
        <Row>
            <Col>    
                <div className="cart-card-wrapper mt-3">            
                    <Row className="w-100">
                        <Col lg={3} md={3} sm={12} xs={12} >
                            <nav style={{ borderRight: '1px solid #ccc', padding: '1rem', overflowY: 'auto' }}>
                                <input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="form-control mb-3"
                                />

                                {filteredSections.map((section) => (
                                <div key={section.id}>
                                    <h3>{section.title}</h3>
                                    <ul style={{ listStyle: 'none', padding: 0 }}>
                                    {section.articles.map((article) => (
                                        <li key={article.id}>
                                        <button
                                            onClick={() => setSelectedArticle(article)}
                                            style={{
                                            background: 'none',
                                            border: 'none',
                                            color: '#85ba3b',
                                            cursor: 'pointer',
                                            textAlign: 'left',
                                            padding: '0.5rem 0',
                                            width: '100%',
                                            }}
                                        >
                                            {article.title}
                                        </button>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                                ))}
                            </nav>
                        </Col>
                        <Col lg={9} md={9} sm={12} xs={12} >
                            <main style={{ flex: 1, padding: '1rem', overflowY: 'auto' }}>
                                {selectedArticle ? (
                                <article>
                                    <h1>{selectedArticle.title}</h1>
                                    <p>{selectedArticle.content}</p>
                                </article>
                                ) : (
                                <p>Select an article from the navigation to view its content.</p>
                                )}
                            </main>
                        </Col>                            
                    </Row>
                </div>
            </Col>
        </Row>
    </Page>
  );
};

export default DocumentationView;