import React from 'react';
import { useCart } from '@app/contexts/cart.context';
import Page from '@app/components/Page';
import { Card, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import demoImage from '@app/assets/images/Edge1002.png'
import CartApi from '@app/api/cart-api/cart.actions';

const CartPage = () => {
  const { cart, dispatch } = useCart();
  const { theme } = React.useContext(ThemeContext);  
  const [ currentCart, setCurrentCart] = React.useState([]);
  const [ currentCartId, setCurrentCartId] = React.useState(0);
  
  React.useEffect(() => {
    (async function() {
      try {
        const carts = await CartApi.getAll();
        console.log('Carts items', carts);
        if (carts && carts.items) {
          setCurrentCart(carts.items);
          setCurrentCartId(carts.id);
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, []);

  console.log('currentCart',currentCart);
  console.log('currentCartId',currentCartId);

  const removeFromCart = async (id) => {
    try {
      const response = await CartApi.removeFromCartAPI(currentCartId, id);
      console.log("Item removed from cart successfully:", response);
      dispatch({ type: 'DELETE_ITEM_SUCCESS', payload: response });
    } catch (error) {
      console.error("Failed to remove item from cart:", error);
      dispatch({ type: 'DELETE_ITEM_ERROR', payload: error.message });
    }
    dispatch({ type: 'REMOVE_ITEM', payload: id });
  };

  const clearCart = async () => {    
      try {
        const response = await CartApi.removeFromCartAPI(currentCartId, currentCart.id);
        console.log("Item added to cart successfully:", response);
          dispatch({ type: 'ADD_ITEM_SUCCESS', payload: response });
      } catch (error) {
        console.error("Failed to add item to cart:", error);
        dispatch({ type: 'ADD_ITEM_ERROR', payload: error.message });
      }
      //dispatch({ type: 'CLEAR_CART' });
    };

  const getCartTotalPrice = () => {
    let cartTotalPrice = 0;
    if (currentCart.length > 0) {
      return currentCart.reduce((total, item) => total + item.totalPrice, 0);
    }
    return cartTotalPrice;
  }

  return (
    <React.Fragment>
      <Page className={`cr-page cart-page px-3 pt-2 ${theme}`} title="" breadcrumbs={[{ name: 'Cart', active: true }]}>
        <div className={`disable-wrapper`}></div>
          {currentCart.length === 0 ? (
            <p>Cart is empty</p>
          ) : (
            <>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="cart-card-wrapper">
                      <h3 className="w-100">Products</h3>
                      {currentCart.map((item, index) => (
                        <Row key={item.product.id + '_' + index} className="w-100">
                          <Col>
                            <div className="d-flex align-items-center justify-content-between w-100 border-bottom">
                              <Col lg={1} md={1} sm={1} xs={1} className="mb-3">
                                <img src={demoImage} alt={item.product.name} />
                              </Col>
                              <Col lg={5} md={5} sm={12} xs={12} className="mb-3">
                                <div>
                                  <Link to={`/products/${item.product.id}`}>
                                    <strong>{item.product.name}</strong>
                                  </Link>
                                </div>
                                <div>
                                  <div><b>Product price:</b> {item.product.prices ? item.product.prices[0].price + ' ' + item.product.prices[0].currency : item.product.prices[0].price ? item.product.prices[0].price + ' ' + item.product.prices[0].currency : 0}</div>

                                  {item.product.selectedOptions && (
                                    <>
                                      {Object.entries(item.product.selectedOptions).map(([key, option], index) => (
                                        <div key={key}>
                                          <b>{option.name}:</b> +{option.price} {option.currency}
                                        </div>
                                      ))}
                                    </>                        
                                  )}
                                </div>
                              </Col>
                              <Col lg={3} md={3} sm={12} xs={12} className="mb-3">
                              <b>Total product price:</b> {item.product.totalPrice} {item.product.prices ? item.product.prices[0].currency : item.product.price[0].currency}
                              </Col>
                              <Col lg={2} md={2} sm={12} xs={12} className="mb-3 text-end">
                                <button className="btn ethica-button-black" onClick={() => removeFromCart(item.product.id)}>Remove</button>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      ))}
                      <div className="w-100 text-end me-4">
                        <h4>Total cart price: {getCartTotalPrice()} CND</h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="h-100">
                    <Row className="mb-3">
                      <Col>
                        <div className="cart-card-wrapper">
                          <h3 className="w-100">Payment</h3>                        
                          <select className="form-select" aria-label="Default select example">
                            <option value="1">Credit/Debit Cards</option>
                            <option value="2">Bank Transfers</option>
                            <option value="3">Process with Stripe</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="cart-card-wrapper">
                          <h3 className="w-100">Delivery</h3>                        
                          <select className="form-select" aria-label="Default select example">
                            <option value="1">Express (UPS)</option>
                            <option value="2">Expedited (UPS)</option>
                            <option value="3">UPS Saver (UPS)</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="w-100 text-end">
              <button onClick={clearCart} className="btn ethica-button-green ml-auto">
                Clear Cart
              </button>
            </div>
          </>
        )}
      </Page>
    </React.Fragment>
  );
};

export default CartPage;
