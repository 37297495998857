import React from 'react';
import PropTypes from '@app/utils/propTypes';

import { Table, Button } from 'reactstrap';
import { MdOutlinePeople, MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import { ids } from 'webpack';

const PartnersList = ({ headers, partnersData, activePartnerId, setActivePartnerId, enterPartnerSpace, setPartnerForm, ...restProps }) => {
    const [activePartnerExpand, setActivePartnerExpand] = React.useState(null);

    const handleRowClick = (id) => {
        setActivePartnerId(id);
    };

    return (
        <Table responsive hover {...restProps}>
            <thead>
                <tr className="text-capitalize align-middle text-center">
                    {headers.map((item, index) => <th key={index}>{item}</th>)}
                </tr>
            </thead>
            <tbody>
                {partnersData.map(({ name, id, contactEmail, controllers, sites, children }, index) => (
                    <React.Fragment key={index}>
                        <tr 
                            className={`align-middle ${activePartnerId === id ? 'table-active' : ''}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <td className="align-middle text-center" onClick={() => setActivePartnerExpand(activePartnerExpand === id ? null : id)}>{children && children.length > 0 && (<span>{children.length}<b className="ms-2">{activePartnerExpand === id ? '▼' : '▶'}</b></span>)}</td>
                            <td className="align-middle text-left" onClick={() => handleRowClick(id)}><strong>{name || '-'}</strong></td>
                            <td className="align-middle text-left">{id || '-'}</td>
                            <td className="align-middle text-left">{contactEmail || '-'}</td>
                            <td className="align-middle text-center">{controllers || '-'}</td>
                            <td className="align-middle text-center">{sites || '-'}</td>
                            <td className="align-middle text-center partners-action">
                                <Button className="ethica-button-green" size="sm me-2" onClick={() => { handleRowClick(id); setPartnerForm(true); }}>Edit</Button>
                                <Button className="ethica-button-black" size="sm" onClick={enterPartnerSpace}>Partner Space</Button>
                            </td>
                        </tr>

                        {children && children.length > 0 && activePartnerExpand === id && (
                            <>
                                {children.map((child, childIndex) => (
                                    <tr key={childIndex}
                                        onClick={() => handleRowClick(child.id)}
                                        className={`align-middle child-row ${activePartnerId === child.id ? 'table-active' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td className="align-middle text-center"><MdOutlineSubdirectoryArrowRight /></td>
                                        <td className="align-middle text-left">{child.name || '-'}</td>
                                        <td className="align-middle text-left">{child.id || '-'}</td>
                                        <td className="align-middle text-left">{child.contactEmail || '-'}</td>
                                        <td className="align-middle text-center">{child.controllers || '-'}</td>
                                        <td className="align-middle text-center">{child.sites || '-'}</td>
                                        <td className="align-middle text-center partners-action">
                                            <Button className="ethica-button-green" size="sm me-2" onClick={() => { handleRowClick(id); setPartnerForm(true); }}>Edit</Button>
                                            <Button className="ethica-button-black" size="sm" onClick={enterPartnerSpace}>Partner Space</Button>
                                        </td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    );
};

PartnersList.propTypes = {
  headers: PropTypes.node,
  partnersData: PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        key: PropTypes.string,
        parent_id: PropTypes.string,
        created: PropTypes.date,
        updated: PropTypes.date,
        acknowledgedTerms: PropTypes.string,
        contactFirstName: PropTypes.string,
        contactLastName: PropTypes.string,
        contactEmail: PropTypes.string,
        contactPhone: PropTypes.string,
        controllers: PropTypes.string,
        sites: PropTypes.string,
        children: PropTypes.array
    })
  ),
  activePartnerId: PropTypes.string,
  setActivePartnerId: PropTypes.func,
  enterPartnerSpace: PropTypes.func,
  setPartnerForm: PropTypes.func
};

PartnersList.defaultProps = {
  headers: [],
  partnersData: [],
};

export default PartnersList;