import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { sortable } from '@patternfly/react-table';
import { Link } from 'react-router-dom';
import { useControllerContext } from '../../contexts/controller.context';
import { selectControllerById, wanSelectors } from '../../common/configuration/configuration.selectors';
import setControllerServers from './ControllerHelper';
import ControllerApi from '@app/api/controller-api/controller.actions';
import Site from '@app/api/site/site.actions';
import Wan from '@app/api/wan/wan.actions';
import GatewayApi from '@app/api/gateway-api/gateway.actions';
import Tunnel from '@app/api/tunnel/tunnel.actions';
import { Card, CardBody, PageSection, Spinner, Title, ToggleGroup, ToggleGroupItem, Tabs, Tab, TabTitleIcon, TabTitleText } from '@patternfly/react-core';
import ControllerIcon from '@app/bgimages/controllers-black.svg';
import SiteIcon from '@app/bgimages/sites-black.svg';
import { IconHeading } from '@app/components/IconHeading';
import { InfoSection } from '@app/components/InfoSection';
import { PatternflyTable } from '@app/components/PatternflyTable';
import { PencilAltIcon, RegionsIcon, TopologyIcon, PficonNetworkRangeIcon } from '@patternfly/react-icons';
import { push } from 'connected-react-router';
import { NotFound } from '../not-found/not-found';
import { ErrorView } from '../error/error';
import '../../app.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Button, CardHeader, Col, Row } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement } from 'chart.js';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table';
import { MdArrowDownward, MdArrowUpward, MdCircle } from 'react-icons/md';
import Loader from '@app/components/Loader';

enum TabIndex {
  OSPF,
  BGP
}

type ColumnFiltersState = ColumnFilter[];
interface ColumnFilter {
  id: string;
  value: unknown;
}

export const ControllerView: React.FC = () => {
  const dispatch = useDispatch();
  const { id: contextId, isLoading, isSuccess, isFailed } = useControllerContext();
  const [activeTabIndex, setActiveTabIndex] = React.useState(TabIndex.OSPF);
  const [controller, setController] = React.useState({});
  const [sites, setSites] = React.useState([]);
  const [assigned, setAssigned] = React.useState([]);
  const [available, setAvailable] = React.useState([]);
  const [gateways, setGateways] = React.useState([]);
  const [lxdBridgeIp, setLxdBridgeIp] = React.useState('N/A');
  const [netmask, setNetmask] = React.useState('N/A');
  const wanById = useSelector(wanSelectors.selectEntities);
  Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement);
  const TitleMap = {
    [TabIndex.OSPF]: 'OSPF',
    [TabIndex.BGP]: 'BGP'
  };
  const [expandedRows, setExpandedRows] = React.useState({});

  const basePath = process.env.REACT_APP_BASE_PATH || '';

  const getIdFromUrl = () => {
    const url = window.location.pathname;
    const matches = url.match(/\/controller\/([^/]+)/);
    return matches ? matches[1] : null;
  };

  const id = contextId || getIdFromUrl();

  const toggleRow = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  React.useEffect(() => {
    (async function() {
      loadController();
      (document.getElementById("primary-app-container") as HTMLDivElement).scroll(0, 0);
    })();
  }, []);

  const loadController = async () => {
    if (!id) {
      console.error('Controller ID is undefined');
      return;
    }
    const controller = await ControllerApi.get(id);
    const gateways = await GatewayApi.getAll();
    const sites = await Site.getAll();
    const wans = await Wan.getAll();
    const tunnels = await Tunnel.getAll();

    if (controller === undefined) {
      return history.push(`${basePath}/notfound`);
    }

    controller.Servers = gateways.filter(gateway => gateway.controller_id === id);

    for (let i=0; i<controller.Servers.length; i++) {
      controller.Servers[i] = { 
        ...controller.Servers[i],
        Tunnels: tunnels.filter(tunnel => tunnel.serverId === controller.Servers[i].id) 
      };
    }

    const controllerServers = setControllerServers(controller, sites, wans);
    const lxdBridgeIpAddr = controllerServers.assigned?.[0]?.ip_4_router ?? 'N/A';
    const controllerNetmask = controllerServers.assigned?.[0]?.ip_4_cidr ?? 'N/A';

    setLxdBridgeIp(lxdBridgeIpAddr);
    setNetmask(controllerNetmask);
    setSites(controllerServers.sites);
    setAssigned(controllerServers.assigned);
    setAvailable(controllerServers.available);
    setGateways([...controllerServers.assigned,...controllerServers.available]);
    setController({
      name: controller.name,
      description: controller.description,
      country: controller.country, 
      region: controller.region, 
      timezone: controller.timezone,
      ip_address: controller.ip_address,
      routingEnabled: controller.dynamic_routing_enabled,
      routingConfig: controller.routing_configuration
    });
  }

  const mergeGatewaysAndSites = (gateways, sites) => {
    return gateways.map(gateway => {
      const matchedSites = sites.filter(site => {
        const serverIp = site.server?.ip_4_address;
        return serverIp && serverIp === gateway.ip_4_address;
      });
  
      return {
        ...gateway,
        sites: matchedSites,
      };
    });
  };

  const mergedGateways = mergeGatewaysAndSites(gateways, sites);
  
  const fullGateways = React.useMemo(() => mergedGateways, [mergedGateways]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const columns = React.useMemo(() => [
    {
      accessorKey: 'ip_4_address',
      header: 'IPv4 Address',
    },
    {
      accessorKey: 'id',
      header: 'ID',
    },
  ], []);

  const table = useReactTable({
    data: fullGateways,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 5,
        pageIndex: 0,
      },
    },
  });

  const handleTabClick = (e, index) => {
    setActiveTabIndex(index);
  };

  const getSiteColumns = () => {
    return [
      { title: 'Address', transforms: [sortable] },
      { title: 'WAN' },
      { title: 'Location' },
      { title: 'Company' },
      { title: 'Tunnel Gateway', transforms: [sortable] },
    ];
  };

  const getServerColumns = () => {
    return [{ title: 'IPv4 Address', transforms: [sortable] }, { title: 'ID' }];
  };

  const siteFromItem = React.useCallback((item) => {
    const wan = wanById[item.wanId];
    const wanLink = item.wanId ? <Link to={{ pathname: `${basePath}/wan/${item.wanId}` }}>{wan?.name}</Link> : '';
    return {
      cells: [
        { title: <Link to={{ pathname: `${basePath}/site/${item.id}` }}>{item.address1}</Link> },
        { title: wanLink },
        item.city,
        item.ClientCompany.name,
        item.server.ip_4_address,
      ],
    };
  }, []);

  const serverFromItem = (item) => {
    return {
      cells: [item.ip_4_address + item.ip_4_cidr, item.id],
    };
  };

  const getLocationInfo = React.useCallback(() => {
    return [
      { label: 'Country', value: controller?.country },
      { label: 'Region', value: controller?.region },
      { label: 'Timezone', value: controller?.timezone },
    ];
  }, [controller]);

  const getControllerInfo = React.useCallback(() => {
    const controllerIp = controller && controller.ip_address && controller.ip_address !== '' ? controller?.ip_address : 'N/A';
    
    return [
      { label: 'IP Address', value: controllerIp },
      // { label: "Controller Type", value: "Bridged/Routed" },
      { label: 'LXD Bridge IP', value: lxdBridgeIp },
      { label: 'Netmask', value: netmask },
    ];
  }, [controller, lxdBridgeIp]);

  const editController = React.useCallback(() => {
    dispatch(push(`${basePath}/controller/edit/${id}`));
  }, [dispatch, id]);

  if (isFailed) {
    return <ErrorView title="Failed to load Controller" message="🤖 Failed to load Controller" />;
  }

  if (isSuccess && !controller) {
    return <NotFound />
  }

  const ospfConfig = [];
  const bgpConfig = [];
  const ospf = controller?.routingConfig?.ospf_configuration;
  const bgp = controller?.routingConfig?.bgp_configuration;

  if (ospf) {
    for (let i=0; i<ospf.length; i++) {
      ospfConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{ospf[i]}<br /></div>);
    }
  }
  if (bgp) {
    for (let i=0; i<bgp.length; i++) {
      bgpConfig.push(<div style={{ whiteSpace: 'pre' }} key={i}>{bgp[i]}<br /></div>);
    }
  }
  
  const { theme } = React.useContext(ThemeContext);

  const cpuData = {
    labels: ['6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00'],
    datasets: [
      {
        label: controller?.name,
        data: [10, 9, 13, 11, 15, 12, 9],
        borderColor: '#45b649',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
      },
    ],
  };

  const cpuOptions = (theme) => ({
    scales: {
      x: {
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
      y: {
        min: 0,
        max: 20,
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
          callback: function (value) {
            return value + '%';
          },
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 14,
          },
          color: theme === 'dark' ? '#fff' : '#000',
        },
      },
      beforeDraw: (chart) => {
        const ctx = chart.ctx;
        ctx.save();
        ctx.fillStyle = theme === 'dark' ? '#000' : '#fff'; 
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    },
  });

  const bandwidthData = {
    labels: ['6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00'],
    datasets: [
      {
        label: controller?.name,
        data: [1, 2, 1, 3, 3, 1, 3],
        borderColor: '#f85032',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
      },
    ],
  };

  const bandwidthOptions = (theme) => ({
    scales: {
      x: {
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
      y: {
        min: 0,
        max: 5,
        ticks: {
          color: theme === 'dark' ? '#fff' : '#000',
          callback: function (value) {
            return value + 'Mb/s';
          },
        },
        grid: {
          color: theme === 'dark' ? '#444' : '#ddd',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 14,
          },
          color: theme === 'dark' ? '#fff' : '#000',
        },
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.save();
          ctx.fillStyle = theme === 'dark' ? '#000' : '#fff'; 
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        },
      },
    },
  });

  return (
    <React.Fragment>
      {isLoading ? ( 
        <Loader />
      ) : (
        <Page
            className={`cr-page px-3 pt-2 controllers-list ${theme}`}
            title=""
            breadcrumbs={[              
              { name: <a href="/controllers">Controllers</a>, active: false },
              { name: controller?.name, active: true }
            ]}
          >
          <Row>
            <Col lg={7} md={7} sm={12} xs={12}>
              <div className="controllers-card-wrapper d-flex mb-3 position-relative">
                <div className="position-absolute top-0 end-0 bg-success p-2 text-black fw-bold" style={{borderBottomLeftRadius: '5px', borderTopRightRadius: '5px'}}>Online</div>
                <div className="w-100">
                  <Title headingLevel="h1" size="x1" style={{paddingRight: "70px"}}>
                    {controller?.name}
                  </Title>
                </div>      
                <Row className="w-100">
                  <Col lg={10} md={10} sm={12} xs={12}>
                    <div className="w-100 mt-3">
                      <p><strong>Controller ID: </strong>{id}</p>
                    </div>
                    <div className="w-100 mb-3">
                      <p>{controller?.description}</p>
                    </div>
                  </Col>
                  <Col lg={2} md={2} sm={12} xs={12}>         
                    <div className="d-flex flex-column">
                      <Button className="ethica-button-green w-100 mb-2" onClick={editController}>Edit</Button>
                    </div>         
                  </Col>
                </Row>
                
                <Row className="w-100 m-auto">
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <InfoSection keyValuePairs={getControllerInfo()} showHeading={false} />
                  </Col>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <InfoSection heading="Location" icon={<RegionsIcon />} keyValuePairs={getLocationInfo()} />
                  </Col>
                </Row>
              </div>            

              <div className="controllers-card-wrapper d-flex position-relative">    
                <Row className="w-100 m-auto">
                  <Col lg={12} md={12} sm={12} xs={12} className="h-100">
                    <h3 className="pt-4">Tunnel Gateways</h3>
                    {fullGateways ? (
                      <div className="w-100 text-center">No Data</div>
                    ) : (
                      <>                    
                        <table className="controllers-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                  <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                      <div className={`d-flex align-items-center ${header.column.getCanFilter() ? 'justify-content-between' : 'justify-content-center'}`}>
                                        <div
                                          {...{
                                            className: header.column.getCanSort()
                                              ? 'select-none'
                                              : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                          }}
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: <MdArrowUpward />,
                                            desc: <MdArrowDownward />,
                                          }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                        {header.column.getCanFilter() ? (
                                          <div>
                                            <Filter column={header.column} />
                                          </div>
                                        ) : null}
                                      </div>
                                    )}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody>
                            {table.getRowModel().rows.map(row => (
                              <React.Fragment key={row.id}>
                                  <tr>
                                  {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} className={cell.column.getCanFilter() ? '' : 'text-center'}>
                                      {row.original.sites && row.original.sites.length > 0 && cell.column.columnDef.header === 'IPv4 Address' ? (
                                        <>
                                          <button onClick={() => toggleRow(row.id)} style={{ background: 'none', border: 'none', cursor: 'pointer', transition: '.3s' }}>
                                            {expandedRows[row.id] ? '▼' : '▶'}
                                          </button>
                                          <strong><a href={`/controller/${row.original.id}`} className="controller-name">{flexRender(cell.column.columnDef.cell, cell.getContext())}</a></strong>
                                        </>
                                      ) : (
                                        flexRender(cell.column.columnDef.cell, cell.getContext())
                                      )}
                                    </td>
                                  ))}
                                </tr>

                                {expandedRows[row.id] && row.original.sites && (
                                  <>
                                  <tr>
                                    <td style={{fontSize: "11px", fontWeight: "bold"}}>Address</td>
                                    <td style={{fontSize: "11px", fontWeight: "bold"}}>WAN</td>
                                    <td style={{fontSize: "11px", fontWeight: "bold"}}>Location</td>
                                    <td style={{fontSize: "11px", fontWeight: "bold"}}>Company</td>
                                    <td style={{fontSize: "11px", fontWeight: "bold"}}>Tunnel Gateway</td>
                                    <td style={{fontSize: "11px", fontWeight: "bold"}}>Type</td>
                                  </tr>
                                    {row.original.sites && row.original.sites.map((site, index) => (
                                      <tr key={index} className="expanded-row">
                                        <td style={{fontSize: "12px"}}>{site.address1}</td>
                                        <td style={{fontSize: "12px"}}>{site.Wan}</td>
                                        <td style={{fontSize: "12px"}}>{site.city}</td>
                                        <td style={{fontSize: "12px"}}>{site.name}</td>
                                        <td style={{fontSize: "12px"}}>{site.server.ip_4_address}</td>
                                        <td style={{fontSize: "12px"}}>Site</td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
                          <div className="me-2">   
                            <span className="flex items-center gap-1">
                              Go to page:
                              <input
                                type="number"
                                min="1"
                                max={table.getPageCount()}
                                defaultValue={table.getState().pagination.pageIndex + 1}
                                onChange={e => {
                                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                                  table.setPageIndex(page)
                                }}
                                className="border p-1 rounded w-16"
                              />
                            </span>
                          </div>    
                          <div>
                            <button
                              className="border rounded p-1 me-1"
                              onClick={() => table.setPageIndex(0)}
                              disabled={!table.getCanPreviousPage()}
                            >
                              {'<<'}
                            </button>
                            <button
                              className="border rounded p-1 me-1"
                              onClick={() => table.previousPage()}
                              disabled={!table.getCanPreviousPage()}
                            >
                              {'<'}
                            </button>
                            <span className="flex items-center me-1">
                              <strong>
                                {table.getState().pagination.pageIndex + 1} of{' '}
                                {table.getPageCount()}
                              </strong>
                            </span>
                            <button
                              className="border rounded p-1 me-1"
                              onClick={() => table.nextPage()}
                              disabled={!table.getCanNextPage()}
                            >
                              {'>'}
                            </button>
                            <button
                              className="border rounded p-1 me-2"
                              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                              disabled={!table.getCanNextPage()}
                            >
                              {'>>'}
                            </button>
                          </div>
                          <div>                 
                            <select
                              className="form-select"
                              value={table.getState().pagination.pageSize}
                              onChange={e => {
                                table.setPageSize(Number(e.target.value))
                              }}
                            >
                              {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                  Show {pageSize}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div> 
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={5} md={5} sm={12} xs={12}>
              <Row>
                <div className="controllers-card-wrapper d-flex position-relative">
                  <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                    <Card>
                      <CardHeader className="p-2 border-bottom">
                        <strong>CPU</strong>
                      </CardHeader>
                      <CardBody className="p-2">
                      <Line data={cpuData} options={cpuOptions(theme)}
                        style={{
                          backgroundColor: theme === 'dark' ? '#333030' : '#fff',
                        }}/>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Card>
                      <CardHeader className="p-2 border-bottom">
                        <strong>Bandwidth</strong>
                      </CardHeader>
                      <CardBody className="p-2">
                        <Line data={bandwidthData} options={bandwidthOptions(theme)}/>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </Row>
            </Col>
          </Row>

          <div className="controllers-card-wrapper d-flex position-relative"> 
            <div className="position-absolute top-0 end-0 bg-danger p-2 text-white fw-bold" style={{borderBottomLeftRadius: '5px', borderTopRightRadius: '5px'}}>disabled</div>   
                <Row className="w-100 m-auto">
                  <Col lg={12} md={12} sm={12} xs={12}>
                  <h3>Dynamic Routing</h3>
                  <Card isCompact>
                    <CardBody>
                      {!controller?.routingEnabled ? (
                        <div>Dynamic Routing disabled</div>
                      ) : (
                        <div>
                          <Tabs activeKey={activeTabIndex} onSelect={handleTabClick} isBox>
                            <Tab
                              eventKey={TabIndex.OSPF}
                              title={
                                <React.Fragment>
                                  <TabTitleIcon>
                                    <PficonNetworkRangeIcon className="icon-medium" />
                                  </TabTitleIcon>{' '}
                                  <TabTitleText>{TitleMap[TabIndex.OSPF]}</TabTitleText>
                                </React.Fragment>
                              }
                            >
                              {controller?.routingConfig?.ospf_enabled ?
                                <div className="code-config"><br />{ospfConfig}</div>
                              : <div><br />OSPF disabled</div> }
                            </Tab>
                            <Tab
                              eventKey={TabIndex.BGP}
                              title={
                                <React.Fragment>
                                  <TabTitleIcon>
                                    <PficonNetworkRangeIcon className="icon-medium" />
                                  </TabTitleIcon>{' '}
                                  <TabTitleText>{TitleMap[TabIndex.BGP]}</TabTitleText>
                                </React.Fragment>
                              }
                            >
                              {controller?.routingConfig?.bgp_enabled ?
                                <div className="code-config"><br />{bgpConfig}</div>
                              : <div><br />BGP disabled</div>}
                            </Tab>
                          </Tabs>
                        </div>
                      )}
                    </CardBody>
                  </Card>
              </Col>
          </Row>
        </div>

        </Page>
      )}
    </React.Fragment>
  );
};

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}