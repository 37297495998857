import React from 'react';
import { providers } from './contexts/index';

const CombinedProvider = ({ children }) => {
  return providers.reduce(
    (acc, Provider) => <Provider>{acc}</Provider>,
    children
  );
};

export default CombinedProvider;