import Page from '@app/components/Page';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import SupportApi from '@app/api/support-api/support.actions';
import SupportForm from './SupportForm';
import { Column, ColumnFiltersState, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, Updater, useReactTable } from '@tanstack/react-table';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { formatDate } from '@app/lib/functions';

const SupportView = () => {
  const [tickets, setTickets] = useState([]);
  const { theme } = React.useContext(ThemeContext);
  const [expandedRows, setExpandedRows] = React.useState({});

  const toggleRow = (rowId) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };
    
  const fetchTickets = async () => {
    try {
      const cases = await SupportApi.getAll();
      setTickets([...cases]);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchTickets();
  }, []); 

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const columns = React.useMemo(() => [
    {
      accessorKey: 'subject',
      header: 'Subject',
    },
    {
      accessorKey: 'priority',
      header: 'Priority',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'urgency',
      header: 'Urgency',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'impacting',
      header: 'Impacting',
      enableColumnFilter: false,
    },
    {
      accessorKey: 'message',
      header: 'Message',
      enableColumnFilter: false,
      cell: ({ row }) => (
        <>
          <span>{row.original.messages && row.original.messages[0] ? row.original.messages[0].content : '-'}</span>
        </>
      ),
    },
    {
      accessorKey: 'created_at',
      header: 'Created at',
      enableColumnFilter: false,
      cell: ({ row }) => (
        <>
          <span>{formatDate(row.original.created_at)}</span>
        </>
      ),
    },
  ], []);

  const table = useReactTable({
    data: tickets,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },
  });

  useEffect(() => {
    table.getRowModel();
  }, [tickets]);

  return (
    <Page 
    className={`cr-page support-list px-3 pt-2 ${theme}`} 
    title="" 
    breadcrumbs={[{ name: 'Support', active: true }]}
    >
        <Row>
            <Col lg={9} md={9} sm={12} xs={12} className="pb-3">    
                <div className="support-card-wrapper mt-3 pb-3"> 
                    <Card className="p-3 w-100"> 
                        <table className="support-list-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                    <div className={`d-flex  align-items-center ${header.column.getCanFilter() ? 'justify-content-between' : 'justify-content-center'}`}>
                                        <div
                                        {...{
                                            className: header.column.getCanSort()
                                            ? 'select-none'
                                            : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                        }}
                                        >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                            asc: <MdArrowUpward />,
                                            desc: <MdArrowDownward />,
                                        }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                        {header.column.getCanFilter() ? (
                                        <div>
                                            <Filter column={header.column} />
                                        </div>
                                        ) : null}
                                    </div>
                                    )}
                                </th>
                                ))}
                            </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                            <React.Fragment key={row.id}>
                                <tr>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} className={cell.column.getCanFilter() ? '' : 'text-center'}>
                                    {cell.column.columnDef.header === 'Name' ? (
                                        <>
                                        <button onClick={() => toggleRow(row.id)} className="table-arrow">
                                            {expandedRows[row.id] ? '▼' : '▶'}
                                        </button>
                                        <strong><a href={`${basePath}/controller/${row.original.id}`} className="controller-name">{flexRender(cell.column.columnDef.cell, cell.getContext())}</a></strong>
                                        </>
                                    ) : (
                                        flexRender(cell.column.columnDef.cell, cell.getContext())
                                    )}
                                    </td>
                                ))}
                                </tr>
                                
                                {expandedRows[row.id] && (
                                <>
                                    {row.original.gateway && row.original.gateway.map((gw, index) => (
                                    <tr key={index} className="expanded-row">
                                        <td style={{paddingLeft: '34px'}}>{`Gateway ${index + 1}`}</td>
                                        <td className="text-center">{gw}</td>
                                        <td className="text-center">

                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    ))}
                                </>
                                )}
                            </React.Fragment>
                            ))}
                        </tbody>
                        </table>

                        <div className="d-flex items-center justify-content-center align-items-center flex-wrap mt-2">
                        <div className="me-2">   
                            <span className="flex items-center gap-1">
                            Go to page:
                            <input
                                type="number"
                                min="1"
                                max={table.getPageCount()}
                                defaultValue={table.getState().pagination.pageIndex + 1}
                                onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                                }}
                                className="border p-1 rounded w-16"
                            />
                            </span>
                        </div>    
                        <div>
                            <button
                                className="border rounded p-1 me-1"
                                onClick={() => table.setPageIndex(0)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {'<<'}
                            </button>
                            <button
                                className="border rounded p-1 me-1"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                {'<'}
                            </button>
                            <span className="flex items-center me-1">
                                <strong>
                                {table.getState().pagination.pageIndex + 1} of{' '}
                                {table.getPageCount()}
                                </strong>
                            </span>
                            <button
                                className="border rounded p-1 me-1"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                {'>'}
                            </button>
                            <button
                                className="border rounded p-1 me-2"
                                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                {'>>'}
                            </button>   
                            </div>
                            <div>                 
                            <select
                                className="form-select"
                                value={table.getState().pagination.pageSize}
                                onChange={e => {
                                table.setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                                ))}
                            </select>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
            <Col lg={3} md={3} sm={12} xs={12} >    
                <div className="support-card-wrapper support-form mt-3">  
                    <SupportForm onTicketAdded={fetchTickets} />
                </div>
            </Col>
        </Row>
    </Page>
  );
};

export default SupportView;

function setColumnFilters(updaterOrValue: Updater<ColumnFiltersState>): void {
  throw new Error('Function not implemented.');
}

function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="form-control"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}
