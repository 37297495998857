import React from 'react';
import { useCart } from '@app/contexts/cart.context';
import { Link } from 'react-router-dom';
import { MdShoppingBasket } from 'react-icons/md';

const CartHeader = () => {
  const { cart } = useCart();

  return (
    <div className="header-cart">
        <Link to="/cart">
            <MdShoppingBasket size="2em" />
            <div className="header-cart-count">{cart.length}</div>
        </Link>
    </div>
  );
};

export default CartHeader;