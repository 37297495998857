import * as React from 'react';
import { Helmet } from 'react-helmet';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { PageSection, Title, Tabs, Tab, TabTitleText, TabTitleIcon } from '@patternfly/react-core';
import { KeyIcon, UsersIcon, UserIcon, WrenchIcon, OptimizeIcon, LockIcon, TagIcon } from '@patternfly/react-icons';
import UserSession from '@app/common/user-session';
import Settings from './Settings';
import Profile from './Profile';
import Users from './Users';
import ApiKeys from './ApiKeys';
import PaymentMethods from './PaymentMethods';
import { Preferences } from './preferences';
import ResetPassword from '../auth/ResetPassword';
import { useHistory, useLocation } from 'react-router-dom';
import Page from '@app/components/Page';
import loaderLogo from '@app/assets/images/logo/CloudAccess-Logo-BlkCloud.jpg';
import { Button, CardHeader, Col, Row, Card, CardBody } from 'reactstrap';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import { MdAccountCircle, MdPeople, MdSwitchAccount } from 'react-icons/md';
import Loader from '@app/components/Loader';

enum TabIndex {
  PROFILE,
  ACCOUNT,
  TEAMS,
  USERS,
  SECURITY,
  PAYMENT_METHODS,
  API_KEYS
}

export const AdminView: React.FC = () => {
  const TitleMap = {
    [TabIndex.PROFILE]: 'User profile',
    [TabIndex.ACCOUNT]: 'Account profile',
    [TabIndex.TEAMS]: 'Teams profile'
  };
  const location = useLocation();
  const { theme } = React.useContext(ThemeContext);
  
  const isImpersonating = UserSession.getParam('isImpersonating');
  const role = UserSession.getParam('role').toLowerCase();
  const isManager = role === 'manager' ? true : false;
  const isViewer = role === 'viewer' ? true : false;
  const isEditor = role === 'editor' ? true : false;
  const defaultTab = isImpersonating ? TabIndex.ACCOUNT : TabIndex.PROFILE;
  const queryParams = new URLSearchParams(location.search);
  const initialTab = Number(queryParams.get('tab')) || TabIndex.PROFILE;
  const [activeTabIndex, setActiveTabIndex] = React.useState(initialTab);
  const [currentTitle, setCurrentTitle] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const randomTimeout = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000;

    const loaderTimeout = setTimeout(() => {
      setIsLoading(false);
    }, randomTimeout);

    return () => clearTimeout(loaderTimeout);
  }, []);

  const handleTabClick = React.useCallback(
    (_, index) => {
      setActiveTabIndex(index);
    },
    [setActiveTabIndex]
  );

  React.useEffect(() => {
    setCurrentTitle(TitleMap[activeTabIndex]);
  }, [activeTabIndex]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      {isLoading ? ( 
        <Loader />
      ) : (
        <Page
          className={`cr-page px-3 pt-2 acl-form position-relative ${theme}`}
          title=""
          breadcrumbs={[
            { name: "Admin", active: true },
          ]}
        >
          <Row>
            <Col>
              <div className="p-3 h-100 wans-card-wrapper d-flex justify-content-between">
                <Tabs activeKey={activeTabIndex} onSelect={handleTabClick} isBox className="w-100 mb-3">
                  <Tab
                    eventKey={TabIndex.PROFILE}
                    isHidden={isImpersonating}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <MdAccountCircle color="#85ba3b" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.PROFILE]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    <Row className="w-100">
                      <Profile />
                      <ResetPassword />
                    </Row>
                    <Row className="w-100">
                      <ApiKeys />
                    </Row>
                  </Tab>
                  <Tab
                    eventKey={TabIndex.ACCOUNT}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <MdSwitchAccount color="#85ba3b" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.ACCOUNT]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    <Row className="w-100">
                      <Settings />
                      <PaymentMethods />
                    </Row>
                    <Row className="w-100">
                      <Preferences />
                    </Row>
                  </Tab>
                  <Tab
                    eventKey={TabIndex.TEAMS}
                    isHidden={isImpersonating || isViewer}
                    title={
                      <React.Fragment>
                        <TabTitleIcon>
                          <MdPeople color="#85ba3b" />
                        </TabTitleIcon>{' '}
                        <TabTitleText>{TitleMap[TabIndex.TEAMS]}</TabTitleText>
                      </React.Fragment>
                    }
                  >
                    <Row>
                      <Users />
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Page>
      )}
    </React.Fragment>
  );
};
