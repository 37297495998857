import * as React from 'react';
import UserSession from '@app/common/user-session';
import { CardBody } from '@patternfly/react-core';
import { Card, CardHeader } from 'reactstrap';

import './Dashboard.css';
import ThemeContext from '@app/providers/contexts/ThemeContext';
import Page from '@app/components/Page';
import { Col, Row, CardGroup } from 'reactstrap';
import { Chart, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement } from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';

import {
  MdLightbulbOutline,
  MdThumbUp,
  MdCancel,
  MdNetworkCell,
  MdShare,
  MdOutlineImportExport,
  MdLanguage
} from 'react-icons/md';
import IconWidget from '@app/components/widget/IconWidget';
import { getColor } from '@app/utils/colors';
import { fill } from 'lodash';
import { background } from '@storybook/theming';
import loaderLogo from '@app/assets/images/logo/CloudAccess-Logo-BlkCloud.jpg';
import Loader from '@app/components/Loader';
import MapWithBubblesDashboard from '@app/components/MapWithBubblesDashboard';

const DashboardView = () => {
  const { theme } = React.useContext(ThemeContext);
  const partnerId = UserSession.getPartnerId();
  Chart.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Legend, ArcElement);

  const [state, setState] = React.useState({
    partnerId: partnerId,
    siteCountLoading: true,
    panelIds: {
      totalWans: 1,
      totalControllers: 2,
      totalSites: 3,
      industries: 7,
      carriers: 6,
      linkTypes: 8,
      siteStatus: 9,
      cpu: 10,
      bandwidth: 11
    }
  });
  
  const { theme } = React.useContext(ThemeContext);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const randomTimeout = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000;

    const loaderTimeout = setTimeout(() => {
      setIsLoading(false);
    }, randomTimeout);

    return () => clearTimeout(loaderTimeout);
  }, []);

  const { partnerId: pid, siteCountLoading, panelIds } = state;
  const grafanaLink = process.env.REACT_APP_GRAFANA_LINK.replace('{{partner_id}}', pid);

  const siteWidgetsData = [
    {
      bgColor: 'success',
      icon: MdThumbUp,
      title: 'Brad Lagasse\'s Site',
      subtitle: 'Fidalia QA Controller',
      ip: '23.141.80.147'
    },
    {
      bgColor: 'dark',
      icon: MdCancel,
      title: 'Site Name Example is down',
      subtitle: 'Some Controller Example',
      ip: '127.0.0.1'
    },
    {
      bgColor: 'danger',
      icon: MdLightbulbOutline,
      title: 'Lenovo CPE',
      subtitle: 'Fidalia Lab 2024 New Controller 1',
      ip: '23.141.80.38'
    },
    {
      bgColor: 'success',
      icon: MdThumbUp,
      title: 'Site Name Example is ok',
      subtitle: 'Some Controller Example',
      ip: '127.0.0.1'
    },
    {
      bgColor: 'danger',
      icon: MdLightbulbOutline,
      title: 'Site Name Example is warning',
      subtitle: 'Some Controller Example',
      ip: '127.0.0.1'
    },
  ];

  const controllerWidgetsData = [
    {
      bgColor: 'success',
      icon: MdThumbUp,
      title: 'Controller 1QA Controller 3 [Proxmox]',
      subtitle: 'Ontario',
      ip: '10.0.5.0'
    },
    {
      bgColor: 'danger',
      icon: MdLightbulbOutline,
      title: 'Testing Controller',
      subtitle: 'Ontario',
      ip: '10.0.5.2'
    },
    {
      bgColor: 'success',
      icon: MdThumbUp,
      title: 'Test 2 v2',
      subtitle: 'Badakhshan',
      ip: '10.0.5.14'
    },
    {
      bgColor: 'dark',
      icon: MdLightbulbOutline,
      title: '114 JMCS-INT-vCONTROLLER-2',
      subtitle: 'Fier',
      ip: '10.0.5.6'
    },
  ];

  const gatewaysWidgetsData = [
    {
      bgColor: 'success',
      icon: MdThumbUp,
      title: 'a8f65d1c-dac9-4bb4-a97a-cf1ebaefe84d',
      subtitle: '',
      ip: '172.27.27.10'
    },
    {
      bgColor: 'dark',
      icon: MdCancel,
      title: '06382fdc-f223-4c59-991b-6ac12bcb455c',
      subtitle: '',
      ip: '172.27.27.11'
    },
  ];

  const controllers = [
    {
        "id": "641736a8-80e2-4dc4-9b51-1942c947c2c1",
        "name": "QA Controller 3 [Proxmox]",
        "description": "Proxmox QA Controller",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2024-10-03T23:39:11.213097Z",
        "updated": "2024-11-13T23:24:32.887213Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.133",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "bf7f93c0-0678-4a02-a87f-8e159f48cbe3",
        "name": "124 JMCS-INTERNAL-i7-CONTROLLER",
        "description": "CPE Model 7500U",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/New_York",
        "created": "2022-04-19T16:01:45.214086Z",
        "updated": "2022-04-19T16:01:45.214086Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "1929a78a-a5c1-4f6c-907e-0882e5d471e8",
        "name": "Testing Controller",
        "description": "Testing controller description",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2024-11-14T12:46:52.646008Z",
        "updated": "2024-11-14T13:13:21.238913Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.15",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "705afc59-376a-49f8-928f-87cb8ecbf16c",
        "name": "QA Controller 2",
        "description": "qa-controller-2\n\nRoutes - \n23.141.80.16/30\n23.141.80.20/30",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2024-09-06T02:17:45.989864Z",
        "updated": "2024-11-25T11:34:18.486387Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.11",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "4affe3e2-7564-4137-832c-70fd6ab54138",
        "name": "124 JMCS-INTERNAL-VIRTUAL-CONTROLLER",
        "description": "Lab local",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2022-03-31T18:59:00.422335Z",
        "updated": "2022-03-31T18:59:00.422335Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "a0e584ab-74be-4032-a441-d0f533974494",
        "name": "114 JMCS-INT-vCONTROLLER-BETA-DynamicGW",
        "description": "Density Testing (1) - For adding and removing GW's ",
        "region": "Badghis",
        "country": "Afghanistan",
        "timezone": "Pacific/Pago_Pago",
        "created": "2023-03-13T18:17:40.937565Z",
        "updated": "2023-03-13T18:19:31.560832Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "2c00a5bf-af62-409f-b9ba-a29f2d3a8327",
        "name": "114 JMCS-INT-vCONTROLLER-2",
        "description": "It takes two.",
        "region": "Fier",
        "country": "Albania",
        "timezone": "America/Toronto",
        "created": "2022-12-16T19:03:06.803045Z",
        "updated": "2022-12-16T19:03:06.803045Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "6c93fb8d-7a99-4e13-b014-410c86b380df",
        "name": "114 JMCS-INT-vCONTROLLER-CHARLIE-2xGW-1xWAN",
        "description": "2xGW's in WAN, nothing else here",
        "region": "Baghlan",
        "country": "Afghanistan",
        "timezone": "Pacific/Rarotonga",
        "created": "2023-03-13T18:21:46.934476Z",
        "updated": "2023-03-21T16:44:04.289381Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "9d332ddf-1188-4c4e-9a5e-bfeea47ce45d",
        "name": "Ethica Sumit Controller",
        "description": "Sumit Testing controller",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Porto_Velho",
        "created": "2024-08-23T09:28:40.318311Z",
        "updated": "2024-10-23T10:53:30.626102Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "172.16.116.175",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "95bcc10a-22a4-40af-8198-f72572a6b2e1",
        "name": "124 JMCS-INTERNAL-Baremetal.E5-v2",
        "description": "Xeon E5-2603 v2 1.8Ghz\nSupermicro X9SRH-7TF - w/onboard Intel X540 NIC 2x10G-BaseT\n96GB ECC-FBDIMM DDR3-1333 (4x16+4x8GB)",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/New_York",
        "created": "2022-04-07T16:56:31.533609Z",
        "updated": "2022-04-07T16:56:31.533609Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "ed967e08-fe67-47f4-bc81-9fa802741455",
        "name": "QA Controller 1",
        "description": "qa-controller-1\n\nRoutes - \n23.141.80.8/30\n23.141.80.12/30",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2024-09-06T05:26:42.871965Z",
        "updated": "2024-10-22T19:37:52.891114Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.10",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "b0b520c2-436a-458f-baa1-35161aae2c01",
        "name": "Zintis-Proxmox-controller4",
        "description": "Controller to break/fix/text on proxmox server and gateway subnet 23.141.80.164/30",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/New_York",
        "created": "2024-11-01T14:14:02.440941Z",
        "updated": "2024-11-11T18:50:48.577344Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.134",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "fc058f1f-e1c6-4da9-9576-b0c84d6d1d79",
        "name": "124 JMCS-INTERNAL-AMD.Baremetal",
        "description": "Ryzen 5 5600X 6-Core\nX570-PLUS\n32GB (2x16GB) DDR4 3600MHz",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/New_York",
        "created": "2022-04-22T16:50:14.641756Z",
        "updated": "2022-04-22T16:50:14.641756Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "26ba0ce7-c071-4397-8e1a-10f3b09951c2",
        "name": "Sumit Testing Controller",
        "description": "New Controller",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2024-11-21T10:41:03.123194Z",
        "updated": "2024-11-22T07:11:51.358103Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.12",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "234f9034-6c6f-4df0-95d9-a49647830dd6",
        "name": "Test 2 v2",
        "description": "123",
        "region": "Badakhshan",
        "country": "Afghanistan",
        "timezone": "Pacific/Rarotonga",
        "created": "2024-11-26T10:46:17.50983Z",
        "updated": "2024-11-26T10:46:46.296429Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "2.2.2.2",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "be69e583-c1fc-429c-ba9f-8b4f0c059134",
        "name": "125 JMCS-INTERNAL-vController-ALPHA-2023",
        "description": "From Clone ETH-vC-ALPHA",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Thunder_Bay",
        "created": "2023-10-06T14:15:34.594656Z",
        "updated": "2023-10-06T14:15:34.594656Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "c46bff53-9c67-4a63-af93-425b846e1f2e",
        "name": "114 JMCS-INT-vCONTROLLER-1",
        "description": "This controller knows no bounds",
        "region": "Elbasan",
        "country": "Albania",
        "timezone": "America/Anchorage",
        "created": "2022-12-12T16:39:00.044114Z",
        "updated": "2022-12-12T16:39:00.044114Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "1380a1ec-4c26-44e6-a876-1ddf65264fc5",
        "name": "114 JMCS-INT-vCONTROLLER3-1",
        "description": "Newer",
        "region": "Dibër",
        "country": "Albania",
        "timezone": "America/Juneau",
        "created": "2023-01-06T15:40:40.088064Z",
        "updated": "2023-01-06T15:40:40.088064Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "8beba36d-f6cc-4dc0-8c33-c0122f4315e5",
        "name": "114 JMCS-INT-vCONTROLLER-ALPHA-1xGW",
        "description": "1xGW - for testing encryption and single site load",
        "region": "Badakhshan",
        "country": "Afghanistan",
        "timezone": "America/Anchorage",
        "created": "2023-03-13T18:02:01.541358Z",
        "updated": "2023-03-13T18:02:42.849642Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    },
    {
        "id": "927ba94f-eca9-47b6-ba87-d04137af8aa3",
        "name": "Testing Controller 26Nov",
        "description": "New testing Controller",
        "region": "Ontario",
        "country": "Canada",
        "timezone": "America/Toronto",
        "created": "2024-11-26T09:15:45.32787Z",
        "updated": "2024-11-26T09:15:45.32787Z",
        "partner_id": "b8124075-44cc-4e60-9c8f-168d9e86c4f8",
        "is_vps": false,
        "ip_address": "23.141.80.13",
        "dynamic_routing_enabled": false,
        "routing_configuration": null
    }
  ]

  const sites = [
    {
        "id": "e80a09c4-57a0-4d60-98ec-142a89baeb0c",
        "accessibleIps": "",
        "address1": "124 JMCS-6PORT-i7-CPE",
        "address2": "",
        "city": "Dundas",
        "clientCompanyId": "0baf329d-6d3e-4480-b105-ec94f2ae6662",
        "country": "Canada",
        "ipAccessLevel": "All",
        "isServerless": false,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "M6p2r7",
        "rebootRequest": "2022-04-01T00:47:35.798+00:00",
        "softwareVersion": null,
        "state": "Ontario",
        "wanId": null,
    },
    {
        "id": "5d255fe2-d71f-49a7-ae21-e05477efaaad",
        "accessibleIps": "",
        "address1": "126 JMCS-VIRTUAL-1",
        "address2": "",
        "city": "Los Angeles",
        "clientCompanyId": "0baf329d-6d3e-4480-b105-ec94f2ae6662",
        "country": "",
        "ipAccessLevel": "All",
        "isServerless": false,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "90026",
        "rebootRequest": "2023-12-16T23:58:52.169+00:00",
        "softwareVersion": null,
        "state": "",
        "wanId": null,
    },
    {
        "id": "3d2a1ac9-432f-4534-8d76-c3117c293d5c",
        "accessibleIps": "",
        "address1": "152 Wannamaker Rd",
        "address2": "",
        "city": "Stirling",
        "clientCompanyId": "595bff5b-b3ab-49cc-b199-73cea4f28fdd",
        "country": "",
        "ipAccessLevel": "All",
        "isServerless": false,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "K0K 3E0",
        "rebootRequest": "2024-11-01T12:47:25.354+00:00",
        "softwareVersion": null,
        "state": "",
        "wanId": null,
    },
    {
        "id": "77acca45-bb83-49e3-9e9f-76d7e410b1e7",
        "accessibleIps": "",
        "address1": "203 Church st",
        "address2": "",
        "city": "Markham",
        "clientCompanyId": "daff4912-9d4f-49f9-9fce-022be7545c1e",
        "country": "Canada",
        "ipAccessLevel": "All",
        "isServerless": true,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "",
        "rebootRequest": null,
        "softwareVersion": null,
        "state": "Ontario",
        "wanId": null,
    },
    {
        "id": "f4d40976-3a1a-4dc8-bb6e-97a0508250f2",
        "accessibleIps": "",
        "address1": "Demonstration Ave",
        "address2": "12345",
        "city": "Toronto",
        "clientCompanyId": "224a2e67-651f-45e7-bdb0-d0295e1bfa98",
        "country": "Canada",
        "ipAccessLevel": "All",
        "isServerless": true,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "12345",
        "rebootRequest": null,
        "softwareVersion": null,
        "state": "British Columbia",
        "wanId": null,
    },
    {
        "id": "12d3a13d-e463-4f6b-ac03-18f613e9a169",
        "accessibleIps": "",
        "address1": "ILM QA",
        "address2": "",
        "city": "Toronto",
        "clientCompanyId": "ae22615a-c173-4129-a674-e55bf4b9c59b",
        "country": "Canada",
        "ipAccessLevel": "All",
        "isServerless": true,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "M4C 3S9",
        "rebootRequest": null,
        "softwareVersion": null,
        "state": "Ontario",
        "wanId": null,
    },
    {
        "id": "7178ad19-a9c3-4efb-8044-9a871f0e61ff",
        "accessibleIps": "",
        "address1": "Jeff Brunet",
        "address2": "",
        "city": "Aurora",
        "clientCompanyId": "3f801bf7-50be-42c0-9f8d-1d5bdd9ddae1",
        "country": "Canada",
        "ipAccessLevel": "All",
        "isServerless": true,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "",
        "rebootRequest": null,
        "softwareVersion": null,
        "state": "Ontario",
        "wanId": null,
    },
    {
        "id": "2a5bc5e1-cc6e-4615-b7cd-2b2b0a5123c4",
        "accessibleIps": "",
        "address1": "zintis-dummy-site",
        "address2": "",
        "city": "Stirling",
        "clientCompanyId": "595bff5b-b3ab-49cc-b199-73cea4f28fdd",
        "country": "Canada",
        "ipAccessLevel": "All",
        "isServerless": false,
        "meshWanId": "3a5def04-6115-423e-856b-9db94a6eb258",
        "monitorEndpoint": null,
        "monitorId": null,
        "osVersion": null,
        "postalCode": "K0K 3E0",
        "rebootRequest": null,
        "softwareVersion": null,
        "state": "Ontario",
        "wanId": null,
    }
  ]

  const mapData = controllers.concat(sites);

  return ( 
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Page
          className={`cr-page px-3 pt-2 ${theme}`}
          title=""
          breadcrumbs={[{ name: 'Dashboard', active: true }]}
        >     
            <>
              <Row>
                <Col lg={4} md={4} sm={12} xs={12} className="mb-3">
                  <Col lg={12} md={12} sm={12} xs={12} className="mb-3 d-flex flex-wrap">
                    <div className="dashboard-card-wrapper site-status">
                      <h2><strong>Site Alerts</strong></h2>
                        {siteWidgetsData.map(
                          ({ bgColor, icon, title, subtitle, ...restProps }, index) => (
                            <div className="mb-3 me-1 w-auto">
                              <IconWidget
                                bgColor={bgColor}
                                icon={icon}
                                title={title}
                                subtitle={subtitle}
                                {...restProps}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12} className="mb-3 d-flex flex-wrap">
                    <div className="dashboard-card-wrapper site-status">
                      <h2><strong>Gateway Alerts</strong></h2>
                        {gatewaysWidgetsData.map(
                          ({ bgColor, icon, title, subtitle, ...restProps }, index) => (
                            <div className="mb-3 me-1 w-auto">
                              <IconWidget
                                bgColor={bgColor}
                                icon={icon}
                                title={title}
                                subtitle={subtitle}
                                {...restProps}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12} className="mb-3 d-flex flex-wrap">
                    <div className="dashboard-card-wrapper site-status">
                      <h2><strong>Controller Alerts</strong></h2>
                        {controllerWidgetsData.map(
                          ({ bgColor, icon, title, subtitle, ...restProps }, index) => (
                            <div className="mb-3 me-1 w-auto">
                              <IconWidget
                                bgColor={bgColor}
                                icon={icon}
                                title={title}
                                subtitle={subtitle}
                                {...restProps}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </Col>
                </Col>
                <Col lg={8} md={8} sm={12} xs={12} className="mb-3">
                  <div className="dashboard-card-wrapper w-100 h-100 pb-3">
                    <h2><strong>Heatmap</strong></h2>
                    <Card inverse style={{background: '#808080'}} className="w-100">
                        <CardBody>
                        <MapWithBubblesDashboard mapData={mapData}/>
                        </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={3} md={3} sm={12} xs={12} className="mb-3">
                  <div className="dashboard-card-wrapper customer-configurations h-100">
                    <h2><strong>Version control</strong></h2>
                    <p>Some data</p>
                  </div> 
                </Col>

                <Col lg={9} md={9} sm={12} xs={12} className="mb-3">
                  <div className="dashboard-card-wrapper overview h-100">
                    <h2><strong>Overview</strong></h2>
                    <Row className="d-flex w-100 justify-content-center">
                        <Col className="mb-3">
                            <IconWidget
                                bgColor={theme === "dark" ? "dark" : "white"}
                                inverse={false}
                                icon={MdOutlineImportExport}
                                iconProps={{ size: 50 }} 
                                title="20"
                                subtitle="Total WANs"
                                className="w-100 mb-2 p-2"
                            />
                        </Col>
                        <Col className="mb-3">
                            <IconWidget
                                bgColor={theme === "dark" ? "dark" : "white"}
                                inverse={false}
                                icon={MdNetworkCell}
                                iconProps={{ size: 50 }} 
                                title="51"
                                subtitle="Total controllers"
                                className="w-100 mb-2 p-2"
                            />
                        </Col>
                        <Col className="mb-3">
                            <IconWidget
                                bgColor={theme === "dark" ? "dark" : "white"}
                                inverse={false}
                                icon={MdLanguage}
                                iconProps={{ size: 50 }} 
                                title="125"
                                subtitle="Total sites"
                                className="w-100 mb-2 p-2"
                            />
                        </Col>
                      </Row>
                  </div>
                </Col>
              </Row>
            </>
        </Page>
      )}
    </>
  );
}

export { DashboardView };
