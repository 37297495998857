import world50m from '@app/assets/geo-data/world-50m.json';
import cities from '@app/assets/geo-data/world-most-populous-cities.json';
import { scaleLinear, scaleSqrt } from 'd3-scale';
import React, { Component } from 'react';
import { MdCircle } from 'react-icons/md';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from 'react-simple-maps';

const cityScale = scaleLinear()
  .domain([0, 37843000])
  .range([1, 25]);

const sizeScale = scaleSqrt()
  .domain([1, 10])
  .range([1, 4]);

const statuses = [
  "#85ba3b",
  "#e93a54",
  "#000"
];

const generateRandomNumber = () => {
  const randomNumber = Math.floor(Math.random() * statuses.length);
  return statuses[randomNumber];
}

class BubbleMapSites extends Component {
  // state = {
  //   cities,
  // };
  state = {
    tooltipContent: null,
    tooltipPosition: { x: 0, y: 0 },
  };

  handleMouseEnter = (event, cityName, sites) => {
    const mapContainer = event.target.closest('svg').getBoundingClientRect();
    const { clientX, clientY } = event;
  
    this.setState({
      tooltipContent: {
        cityName,
        sites,
      },
      tooltipPosition: {
        x: clientX - mapContainer.left + 10,
        y: clientY - mapContainer.top + 10,
      },
    });
  };
  
  handleMouseLeave = () => {
    this.setState({ tooltipContent: null });
  };

  render() {
    const secondaryColor = '#fc5c7d';
    const lightColor = '#6a82fb';
    const { sites } = this.props;
    const { tooltipContent, tooltipPosition } = this.state;

    const markers = sites
      .filter(site => site.city)
      .map((site, i) => {
        const sitesIncity = sites.filter(ctrl => ctrl.city === site.city);
        const coordinates = this.getCoordinatesForcity(site.city);
        const pointSize = sizeScale(sitesIncity.length); // Вычисляем размер точки заранее

        return (
          <Marker
            key={site.id}
            coordinates={coordinates}
            onMouseEnter={(event) =>
              this.handleMouseEnter(event, site.city, sitesIncity)
            }
            onMouseLeave={this.handleMouseLeave}
          >
            <circle cx={0} cy={0} r={pointSize} fill="#85ba3b" strokeWidth="0.5" />
          </Marker>
        );
      });

    return (
      <div style={{ position: 'relative' }}>
        <ComposableMap
          projection="geoMercator" 
          projectionConfig={{ scale: 150 }}
          width={980}
          height={551}
          style={{
            width: '100%',
            height: 'auto',
            backgroundColor: '#808080',
          }}
        >
          <ZoomableGroup>
            <Geographies geography={world50m}>
            {({ geographies }) =>
                geographies.map(
                  (geography, i) =>
                    geography.id !== 'ATA' && (
                      <Geography
                        key={i}
                        geography={geography}
                        style={{
                          default: {
                            fill: '#000000',
                            stroke: '#000000',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          hover: {
                            fill: '#000000',
                            stroke: '#000000',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          pressed: {
                            fill: '#000000',
                            stroke: '#000000',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                        }}
                      />
                    ),
                )
              }
            </Geographies>
            
            {markers}

            </ZoomableGroup>
        </ComposableMap>
        {tooltipContent && (
          <div
            style={{
              position: 'absolute',
              top: `${tooltipPosition.y}px`,
              left: `${tooltipPosition.x}px`,
              backgroundColor: '#fff',
              padding: '5px 10px',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              pointerEvents: 'none',
              color: '#222'
            }}
          >
            <strong>{tooltipContent.cityName}</strong>
            <ul className='ps-1 mb-0'>
              {tooltipContent.sites.map(site => (
                <li key={site.id} className='d-flex align-items-center'>
                  <MdCircle color={generateRandomNumber()} style={{marginRight: "5px"}}/>{site.address1}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  getCoordinatesForcity = (city) => {
    const cityCoordinates = {
      "Ontario": [-85.3232, 51.2538],
      "Quebec": [-71.2082, 46.8139],
      "Eckerö": [19.5595, 60.2232],
      "Finström": [19.9881, 60.2299],
      "Rose Atoll": [-168.1580, -14.5646],
      "Virginia": [-78.6569, 37.4316],
      "Badghis": [63.7695, 35.1671],
      "Fier": [19.5596, 40.7279],
      "Elbasan": [20.0823, 41.1114],
      "District of Columbia": [-77.0369, 38.9072],
      "Escaldes-Engordany": [1.5388, 42.5101],
      "Badakhshan": [70.8120, 36.7348],
      "Dibër": [20.2356, 41.5888],
      "Maharashtra": [75.7139, 19.7515],
      "Encamp": [1.5830, 42.5363],
      "Delhi": [77.23, 28.61],
      "Ta'ū": [-169.466667, -14.233333],
      "Picton": [174.005833, -41.292778],
      "Dundas": [-79.953182, 43.264198],
      "Toronto": [-79.373333, 43.741667],
      "Aurora": [-79.466667, 44.0],
      "Los Angeles": [-118.25, 34.05],
      "Stirling": [-3.9369, 56.1166],
      "Markham": [-79.263333, 43.876667],
    };
    return cityCoordinates[city] || [0, 0];
  };
}

export default BubbleMapSites;
