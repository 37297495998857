import React from 'react';
import { ExclamationCircleIcon, UserIcon } from '@patternfly/react-icons';
import { Checkbox, FormGroup, TextInput } from "@patternfly/react-core";
import { isLengthWithinRange } from '@app/lib/validator';
import { FormGroupSpacer } from '../../../components/forms/pf/form-group-spacer.component';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { IconHeading } from '@app/components/IconHeading';

const ContactInfo = ({ 
  contact, emailErrorState, phoneErrorState, alertErrorState, handleChange, handleEmailChange, handleAlertChange, 
  firstNameErrorState, lastNameErrorState, extErrorState
}) => {
  return (     
    <>                      
      <Card isCompact>
        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
          <div className="w-100 d-flex justify-content-between">       
            <IconHeading icon={<UserIcon className="icon-medium" />} heading="Contact Information" />
            <div className="email-alert sub-heading">
              <FormGroup fieldId="emailAlert" className="form-item"
                helperTextInvalid={alertErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={alertErrorState.validated}>
                <Checkbox 
                  label="Receive Alerts?" aria-label="Receive Alerts?" name="emailAlert" id="emailAlert" key="emailAlert"
                  isChecked={contact.emailAlert} onChange={handleAlertChange} 
                />
              </FormGroup>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormGroup fieldId="firstName" className="form-item"
                helperTextInvalid={firstNameErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.firstName !== "" ? firstNameErrorState.validated : 'default'}>
                <TextInput className="form-input form-control"
                  isRequired type="text" id="firstName" name="firstName" placeholder="First Name"
                  validated={contact.firstName !== "" ? firstNameErrorState.validated : 'default'}
                  value={contact.firstName} onChange={(e) => handleChange("siteContact", "firstName", e)}
                />
              </FormGroup>
            </Col>

            <Col>
            <FormGroup fieldId="lastName" className="form-item"
              helperTextInvalid={lastNameErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.lastName !== "" ? lastNameErrorState.validated : 'default'}>
              <TextInput className="form-input form-control"
                isRequired type="text" id="lastName" name="lastName" placeholder="Last Name"
                validated={contact.lastName !== "" ? lastNameErrorState.validated : 'default'}
                value={contact.lastName} onChange={(e) => handleChange("siteContact", "lastName", e)}
              />
            </FormGroup>
            </Col>

            <Col>
            <FormGroup fieldId="email" className="form-item"
              helperTextInvalid={emailErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.email ? emailErrorState.validated : 'default'}>
              <TextInput className="form-input form-control" validated={contact.email ? emailErrorState.validated : 'default'}
                isRequired type="text" id="email" name="email" placeholder="Email"
                value={contact.email} onChange={handleEmailChange}
              />
            </FormGroup>
            </Col>
            </Row>

            <Row>
            <Col>
            <FormGroup fieldId="phone" className="form-item"
              helperTextInvalid={phoneErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.phone ? phoneErrorState.validated : 'default'}>
              <TextInput className="form-input form-control" validated={contact.phone ? phoneErrorState.validated : 'default'}
                isRequired type="text" id="phone" name="phone" placeholder="Phone"
                value={contact.phone} onChange={(e) => handleChange("siteContact", "phone", e)}
              />
            </FormGroup>
            </Col>

            <Col>
            <FormGroup fieldId="ext" className="form-item"
              helperTextInvalid={extErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={contact.ext !== "" ? extErrorState.validated : 'default'}>
              <TextInput className="form-input form-control"
                isRequired type="text" id="ext" name="ext" placeholder="Extension" validated={contact.ext !== "" ? extErrorState.validated : 'default'}
                value={contact.ext} onChange={(e) => handleChange("siteContact", "ext", e)}
              />
            </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default ContactInfo;