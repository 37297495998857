import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectLogoFileUrl } from '../../common/session/session.selectors';
import EthicaLogo from '@app/bgimages/ethica-logo-white.svg';
import UserSession from '@app/common/user-session';
import logoBlack from '@app/bgimages/ethica-logo-white.svg';
import logoWhite from '@app/bgimages/ethica-wht-colourbg.svg';
import ThemeContext from '@app/providers/contexts/ThemeContext';

export const Logo: React.FC = () => {
  const { theme } = React.useContext(ThemeContext);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(push('/dashboard'));
  const defaultLogo = theme === 'dark' ? logoBlack : logoWhite;
  let logoUrl = useSelector(selectLogoFileUrl) || defaultLogo;

  return React.useMemo(
    () => <img src={logoUrl} width="150px" height="auto" onClick={handleClick} alt="Orchestrator Logo" />,
    [logoUrl]
  );
};
