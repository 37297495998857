import ApiGateway from '../api-gateway/api-gateway';
import UserSession from '@app/common/user-session';
import axios from 'axios';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll() {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const path = '/carts';
      const result = await ApiGateway.get(path);
      return result.data.cart;
    }
    else {
      cognitoUser.signOut();
    }
  },  

  async addToCartAPI(product) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;
  
    if (tokenIsValid(expiration)) {
      try {
        const payload = {
          items: [
            {
              options: product.selectedOptions || [],
              product_id: product.id,
            },
          ],
        };
  
        const result = await ApiGateway.post(`/carts`, payload);
        return result.data;
      } catch (error) {
        console.error("Error adding to cart:", error);
        throw error;
      }
    } else {
      cognitoUser.signOut();
    }
  },
  
  async addToUserCartAPI(cartId, product) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;
  
    if (tokenIsValid(expiration)) {
      try {
        const payload = {
          items: [
            {
              options: product.selectedOptions || [],
              product_id: product.id,
            },
          ],
        };
  
        const result = await ApiGateway.post(`/carts/${cartId}/items`, payload);
        return result.data;
      } catch (error) {
        console.error("Error adding to cart:", error);
        throw error;
      }
    } else {
      cognitoUser.signOut();
    }
  },

  async removeFromCartAPI(cartId, productId) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;
  
    if (tokenIsValid(expiration)) {
      try {
        const payload = {
          id: productId,
        };
  
        const result = await ApiGateway.delete(`/carts/${cartId}`, payload);
        return result.data;
      } catch (error) {
        console.error("Error adding to cart:", error);
        throw error;
      }
    } else {
        cognitoUser.signOut();
    }
  },
  
}
