import ApiGateway from '../api-gateway/api-gateway';
import UserSession from '@app/common/user-session';
import axios from 'axios';
import { tokenIsValid } from '@app/lib/utils';
import { cognitoUser } from '@app/lib/cognito';
import { store } from '@app/store';

export default {
  async getAll(allLevels) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const path = allLevels ? '/partners?level=all' : '/partners';
      const result = await ApiGateway.get(path);
      return result.data.partners;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async get(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${id}`);
      return result.data.partner;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async create(payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post('/partners', payload);
      return result.partner;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async update(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/partners/${id}`, payload);
      return result.data.partner;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getSites(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${id}/sites`);
      return result.data.sites;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getControllers(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${id}/controllers`);
      return result.data.controllers;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getAssumedRole(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${id}/assumed-role`);
      return result.data;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getPaymentMethods(id) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.get(`/partners/${id}/payment-methods`);
      return result.data.payment_methods;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async addPaymentMethod(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.post(`/partners/${id}/payment-methods`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async updatePaymentMethod(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      const result = await ApiGateway.patch(`/partners/${id}/payment-methods`, payload);
      return result;
    }
    else {
      cognitoUser.signOut();
    }
  },

  async getPreferences(id) {
    const result = await ApiGateway.get(`/partners/${id}/preferences`);
    return result.data.preferences;
  },

  // TODO: Refactor these headers etc. into ApiGateway functions
  async updatePreferences(id, payload) {
    const state = store.getState();
    const expiration = state.common.auth.session.expiration;

    if (tokenIsValid(expiration)) {
      let formData = new FormData();
      const fileHeaders = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${UserSession.getToken()}`,
      }
  
      formData.append('pageTitle', payload.pageTitle);
      formData.append('headerColor', payload.headerColor ? payload.headerColor : 'default');
  
      if (payload.logo instanceof File) {
        formData.append('logo', payload.logo);
      }
      if (payload.favicon instanceof File) {
        formData.append('favicon', payload.favicon);
      }
  
      const result = await axios.post(
        process.env.REACT_APP_USER_MGMT_API_BASE_URL + `/partners/${id}/preferences`, 
        formData, { headers: fileHeaders }
      );
  
      return result.preferences;
    }
    else {
      cognitoUser.signOut();
    }
  }
}
