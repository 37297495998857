import { isValidNumber, isUUID } from '@app/lib/validator';
import { trimString } from '@app/utils/utils';

export const getRuleColumns = () => {
  return [ 
    'Priority', 
    'Type',
    'Src IP',
    'Source Port' , 
    'Dest IP',
    'Dest Port', 
    'Action',
    'Comments',
    ''
  ];
}

export const getAppRuleColumns = () => {
  return [ 
    'Priority', 
    'Group',
    'App',
    'QoS',
    'Src IP',
    'Download(Mbps)',
    'Upload(Mbps)',
    'Exit Via',
    'Action',
    ''
  ];
}

export const getSitesColumns = () => {
  return [ 
    'Access Control List ID', 
    'ID',
    'Resource ID'
  ];
}

export const getPortFromTrafficType = (trafficType) => {
  switch (trafficType) {
    case 'HTTP': return "80";
    case 'HTTPS': return "443";
    case 'SSH': return "22";
    case 'SMTP': return "25";
    case 'DNS(UDP)': return "53";
    case 'DNS(TCP)': return "53";
    case 'All TCP': return "0-65535";
    case 'All UDP': return "0-65535";
    case 'All ICMP - IPv4': return "0-65535";
    case 'All ICMP - IPv6': return "0-65535";
    case 'All Traffic': return "0-65535";
    default: return ""
  }
}

export const getPortsFromRange = (input) => {
  let startPort = 0;
  let endPort = 0;

  if (!input) {
    return { start: 0, end: 0 }
  }

  if (isValidNumber(input)) {
    startPort = input;
  }
  else {
    let range = input.split('-');
    startPort = range[0];
    endPort = range[1];
  }

  return {
    start: parseInt(startPort),
    end: parseInt(endPort)
  }
}

export const getPortRangeFromStartEnd = (start, end) => {
  if ((start === 0 && end === 0) || (!start && !end)) {
    return "";
  }

  if (!end || end === 0) {
    return start;
  }
  else if (!start || start === 0) {
    return '0-' + end;;
  }

  return start + '-' + end;
}

export const ruleFromTableRow = (row) => {
  const sourcePort = getPortsFromRange(row.cells[getRuleColumns().indexOf('Source Port')].props.value);
  const destPort = getPortsFromRange(row.cells[getRuleColumns().indexOf('Dest Port')].props.value);
  const idIndex = getRuleColumns().indexOf('') + 1; // ID is hidden and is last element in the data

  let rule = {
    priority: trimString(row.cells[getRuleColumns().indexOf('Priority')].props.value),
    type: row.cells[getRuleColumns().indexOf('Type')].props.value,
    source_ip: trimString(row.cells[getRuleColumns().indexOf('Src IP')].props.value),
    source_port_start: sourcePort.start,
    source_port_end: sourcePort.end,
    destination_ip: trimString(row.cells[getRuleColumns().indexOf('Dest IP')].props.value),
    destination_port_start: destPort.start,
    destination_port_end: destPort.end,
    action: row.cells[getRuleColumns().indexOf('Action')].props.value.toLowerCase(),
    comments: trimString(row.cells[getRuleColumns().indexOf('Comments')].props.value) 
  }

  // Add back id for existing rows
  if (isUUID(row.cells[idIndex].props.value)) {
    rule.id = row.cells[idIndex].props.value;
  }

  return rule;
}

export const appRuleFromTableRow = (row) => {
  const action = row.cells[getAppRuleColumns().indexOf('Action')].props.value.toLowerCase();
  const exitVia = trimString(row.cells[getAppRuleColumns().indexOf('Exit Via')].props.value);
  const downloadCap = row.cells[getAppRuleColumns().indexOf('Download(Mbps)')].props.value;
  const uploadCap = row.cells[getAppRuleColumns().indexOf('Upload(Mbps)')].props.value;
  const idIndex = getAppRuleColumns().indexOf('') + 1; // ID is hidden and is last element in the data
  let type = row.cells[getAppRuleColumns().indexOf('Group')].props.value;
  let match = trimString(row.cells[getAppRuleColumns().indexOf('App')].props.value);

  // type: should ALWAYS be either 'Application'  or 'All Applications'
  // match: should be the Group name(type:) if 'All' is selected in the UI
  // match: should be the App name if a specific App is selected in the UI
  if (match === 'All') {
    match = type;
    type = 'All Applications';
  }
  else {
    type = 'Application';
  }

  let rule = {
    priority: trimString(row.cells[getAppRuleColumns().indexOf('Priority')].props.value),
    type: type,
    qos: trimString(row.cells[getAppRuleColumns().indexOf('QoS')].props.value),
    match: match,
    source_ip: row.cells[getAppRuleColumns().indexOf('Src IP')].props.value.trim(),
    action: action
  }

  if (downloadCap && action === 'shape') {
    rule.download_cap = parseInt(downloadCap);
  }
  if (uploadCap && action === 'shape') {
    rule.upload_cap = parseInt(uploadCap);
  }
  if (action === 'accept') {
    rule.exit_via = exitVia;
  }

  // Add back id for existing rows
  if (isUUID(row.cells[idIndex].props.value)) {
    rule.id = row.cells[idIndex].props.value;
  }

  return rule;
}

// Convert Access Control List object in state back to API's data model
export const aclFromState = (state) => {
  state.inbound_rules = state.inboundRules?.map(row => {
    return { ...ruleFromTableRow(row) };
  });
  state.outbound_rules = state.outboundRules?.map(row => {
    return { ...ruleFromTableRow(row) };
  });
  state.application_rules = state.applicationRules?.map(row => {
    return { ...appRuleFromTableRow(row) };
  });

  const { inboundRules, outboundRules, applicationRules, ...acl } = state;
  return acl;
}
