import * as React from 'react';
import { AppDispatch } from '@app/store';
import PartnerApi from '@app/api/partner-api/partner.actions';
import { getNamesFromFullname } from '../partner/PartnerHelper';
import UserSession from '@app/common/user-session';
import { countryCodeFromName } from '@app/common/world';
import { textInputRequiredOnly, textInputValidate, isValidCreditCardExpiry, isValidCreditCardNumber, isValidCreditCardCvc } from '@app/lib/validator';
import { Saver } from '@app/components/Saver';
import { ExclamationCircleIcon, PencilAltIcon } from '@patternfly/react-icons';
import { PageSection, Title, TextInput, Flex, FlexItem, Form, FormGroup, TextArea, ToggleGroup, ToggleGroupItem } from '@patternfly/react-core';
import './Admin.css';
import { connect } from 'react-redux';
import { State } from '@app/store/root-reducer';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';

class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      number: '',
      cvc: '',
      description: '',
      expiry: '',
      last4: '',
      mode: '',
      message: "",
      isLoading:  false,
    }
  }

  componentDidMount = () => {
    try {
      this.loadPaymentMethod();
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error getting the payment methods"
      }));
    }
  }

  loadPaymentMethod = async () => {
    const partnerId = UserSession.getPartnerId();
    const paymentMethods = await PartnerApi.getPaymentMethods(partnerId);

    if (paymentMethods?.length) {
      const paymentMethod = paymentMethods[0];
      this.setState(() => ({
        currentPaymentMethod: <div><b>Current Payment Method:</b> Credit Card: **** **** **** {paymentMethod.card.last4}</div>,
        last4: paymentMethod.card.last4,
        mode: 'read'
      }));
    }
    else {
      this.setState(() => ({
        mode: 'create'
      }));
    }
  }

  handleChange = (name, value) => {
    this.setState(() => ({ 
      [name]: value 
    }));
  }

  editPaymentMethod = async () => {
    const partnerId = UserSession.getPartnerId();
    const paymentMethods = await PartnerApi.getPaymentMethods(partnerId);
    const paymentMethod = paymentMethods[0];
    const month = paymentMethod.card.exp_month;
    const expMonth = month < 10 ? `0${month}` : month;

    this.setState(() => ({ 
      mode: 'edit',
      description: paymentMethod.billing_details.name,
      expiry: `${expMonth.toString()}/${paymentMethod.card.exp_year.toString()}`,
    }));
  }

  getCreatePayloadFromState = (partner) => {
    const { name, number, description, cvc, expiry } = this.state;
    const names = getNamesFromFullname(partner.billing_contact);
    const expMonth = expiry.charAt(0) + expiry.charAt(1);
    const expYear = expiry.charAt(3) + expiry.charAt(4) + expiry.charAt(5) + expiry.charAt(6);

    return {
      billing_details: {
        address: {
          line1: partner.billing_address1,
          line2: partner.billing_address2,  
          city: partner.billing_city,
          country: countryCodeFromName(partner.billing_country),
          postal_code: partner.billing_postal_code,
          state: partner.billing_state
        },
        contact: {
          email: partner.billing_email,
          first_name: names.first_name,
          last_name: names.last_name,
          phone: partner.billing_phone
        }
      },
      card: {
        cvc: cvc,
        description: description,
        name: name,
        number: number,
        exp_month: expMonth,
        exp_year: expYear
      }
    }
  }

  getUpdatePayloadFromState = (paymentMethod, partner) => {
    const { description, expiry } = this.state;
    const names = getNamesFromFullname(partner.billing_contact);
    const countryCode = countryCodeFromName(partner.billing_country);
    const expMonth = expiry.charAt(0) + expiry.charAt(1);
    const expYear = expiry.charAt(3) + expiry.charAt(4) + expiry.charAt(5) + expiry.charAt(6);

    return {
      id: paymentMethod.id,
      billing_details: {
        address: {
          line1: partner.billing_address1,
          line2: partner.billing_address2,  
          city: partner.billing_city,
          country: countryCode,
          postal_code: partner.billing_postal_code,
          state: partner.billing_state
        },
        contact: {
          email: partner.billing_email,
          first_name: names.first_name,
          last_name: names.last_name,
          phone: partner.billing_phone
        }
      },
      card: {
        exp_month: expMonth,
        exp_year: expYear
      }
    }
  }

  handleSubmit = async (event) => {
    try {
      const { mode } = this.state;
      const partnerId = UserSession.getPartnerId();
      const partner = await PartnerApi.get(partnerId);
      let payload;
      let result;

      if (mode === 'edit') {
        const paymentMethods = await PartnerApi.getPaymentMethods(partnerId);
        payload = this.getUpdatePayloadFromState(paymentMethods[0], partner);
        result = await PartnerApi.updatePaymentMethod(partnerId, payload);

        if (result.status === 200) {
          window.location.reload();
        }
        else {
          this.setState(() => ({
            message: "There was an error updating your payment method"
          }));
        }
      }
      else if (mode === 'create') {
        payload = this.getCreatePayloadFromState(partner);
        result = await PartnerApi.addPaymentMethod(partnerId, payload);

        if (result?.data?.request_id && result?.data?.payment_methods) {
          window.location.reload();
        }
        else {
          this.setState(() => ({
            message: "There was an error creating your payment method"
          }));
        }
      }
    }
    catch (error) {
      this.setState(() => ({
        message: "There was an error processing your payment method"
      }));
    }
  }

  render() {
    const { name, number, cvc, description, expiry, currentPaymentMethod, paymentMethodExists, message, mode, isLoading } = this.state;
    
    const numberIsValid = isValidCreditCardNumber(number);
    const numberErrorState = textInputValidate(number, true, numberIsValid, "Credit card number is invalid");
    const cvcIsValid = isValidCreditCardCvc(cvc);
    const cvcErrorState = textInputValidate(cvc, true, cvcIsValid, "CVC code is invalid");
    const expiryIsValid = isValidCreditCardExpiry(expiry);
    const expiryErrorState = textInputValidate(expiry, true, expiryIsValid, "Must be MM/YYYY format, and not be in the past");
    const descIsValid = description === '' || (description && description.length < 256);
    const descErrorState = descIsValid ? { validated: 'default', errorText: '' } : { validated: 'error', errorText: 'Description too long' }
    
    let formValid = numberErrorState.validated !== 'error' && cvcErrorState.validated !== 'error' &&
      descErrorState.validated !== 'error' && expiryErrorState.validated !== 'error';

    if (mode === 'edit') {
      formValid = expiryErrorState.validated !== 'error';
    }

    return (
      <Col lg={4} md={4} sm={12} xs={12}>
        <Card className="h-100">
          <CardHeader>
            <div className="w-100 d-flex justify-content-between">
              <div className="icon-heading-container">
                <div className="icon-heading">
                  Payment Methods
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody className="font-12">
            <p>Credit Card is the only supported Payment Method at this time.</p>
            {mode === 'read' ?
                <div>
                  <div>{currentPaymentMethod}</div>
                  <div className="mt-3">
                    <Button className="ethica-button-green ms-0" size="sm me-2" onClick={this.editPaymentMethod}>Edit</Button>
                  </div>
                </div>
              :
                <Form isHorizontal>
                  <Flex>
                    <FlexItem>
                      {mode === 'create' ?
                        <div>
                          <FormGroup label="Name on card" isRequired fieldId="name"
                            helperTextInvalid=" Required field" helperTextInvalidIcon={<ExclamationCircleIcon />} validated={textInputRequiredOnly(name)}>
                            <TextInput
                              isRequired type="text" id="name" name="name" validated={textInputRequiredOnly(name)}
                              value={name} onChange={(e) => this.handleChange("name", e)}
                            />
                          </FormGroup>
                          <FormGroup label="Card number" isRequired fieldId="number"
                            helperTextInvalid={numberErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={numberErrorState.validated}>
                            <TextInput
                              isRequired type="text" id="number" name="number" validated={numberErrorState.validated}
                              value={number} onChange={(e) => this.handleChange("number", e)}
                            />
                          </FormGroup>
                          <FormGroup label="CVC" isRequired fieldId="number"
                            helperTextInvalid={cvcErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={cvcErrorState.validated}>
                            <TextInput
                              isRequired type="password" id="cvc" name="number" validated={cvcErrorState.validated}
                              value={cvc} onChange={(e) => this.handleChange("cvc", e)}
                            />
                          </FormGroup>
                        </div>
                      : <div></div> }

                      {mode === 'create' || mode === 'edit' ?
                        <div>
                          <FormGroup label="Expiry" isRequired fieldId="expiry" className="form-item"
                            helperTextInvalid={expiryErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={expiryErrorState.validated}>
                            <TextInput
                              isRequired type="expiry" id="expiry" name="expiry" validated={expiryErrorState.validated} 
                              value={expiry} onChange={(e) => this.handleChange("expiry", e)} className="form-control"
                            />
                          </FormGroup>
                        </div>
                      : <div></div> }
                    </FlexItem>
                  </Flex>

                  {mode === 'create' ?
                    <FormGroup label="Description" fieldId="description" className="form-item"
                      helperTextInvalid={descErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={descErrorState.validated}>
                      <TextArea
                        type="text" id="description" name="description" validated={descErrorState.validated} style={{width:'300px'}}
                        value={description} onChange={(e) => this.handleChange("description", e)} className="form-control"
                      />
                    </FormGroup>
                  : <div></div> }
                </Form>
              }

              {mode === 'read' ? <div></div> : 
                <Saver 
                  submitButtonText="Save" 
                  submitButtonisDisabled={!formValid}
                  confirmationMessage="Are you sure you want to save this Payment Method?"
                  submit={this.handleSubmit} 
                  showCancel={false}
                  message={this.state.message}
                  messageType={this.state.messageType}
                />
              }
          </CardBody>
        </Card>
      </Col>

    );
  }
}

export default PaymentMethods;
