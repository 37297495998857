import * as React from 'react';
import axios from 'axios';
import replace from "buffer-replace";
import { saveAs } from "file-saver";
import { DownloadIcon } from '@patternfly/react-icons';
import { Spinner } from '@patternfly/react-core';
import './DownloadImage.css';
import { MdCloudDownload } from 'react-icons/md';

class DownloadImage extends React.Component<any, any> {
  constructor(props) {
    super(props);
    
    this.state = {
      imageType: this.props.imageType,
      downloading: false,
      error: ""
    }
  }

  handleDownload = async () => {
    const { imageId, imageType } = this.props;

    // For "server" and "site" imageType, pull the latest source image from cloudfront/s3,  write in 
    // the appropriate id, then instruct the browser to save the generated file. For the "controller" 
    // imageType, it is a straight file download
    switch (imageType) {
      case "server": 
        this.setState({ downloading: true }, async () => {
          try {
            await this.generateServerImage(imageId);
            this.setState({ downloading: false });
          }
          catch(error) {
            console.log(error)
            this.setState({ downloading: false, error: "Unable to download image" });
          }
        }); 
        break;
      case "site": 
        this.setState({ downloading: true }, async () => {
          try {
            await this.generateSiteImage(imageId);
            this.setState({ downloading: false });
          }
          catch(error) {
            console.log(error)
            this.setState({ downloading: false, error: "Unable to download image" });
          }
        });
        break;
      case "controller": 
        // Nothing to do here as controller image type is a straight download without mutation
        break;
      default: 
        throw "Unsupported image type for download"
    }
  }

  generateServerImage = async (id) => {
    const { data: raw } = await axios.get(process.env.REACT_APP_SERVER_IMAGE_URL!, {
      responseType: "arraybuffer",
    });
    const theId = id.replace(/-/g, "");
    const replaced = replace(raw, "ethica_machine_id_placeholder___", theId);
    const blob = new Blob([replaced], { type: "charset=binary" });
    saveAs(blob, `server-${theId}.img`);
    return Promise.resolve();
  };

  generateSiteImage = async (id) => {
    const { data: raw } = await axios.get(process.env.REACT_APP_CPE_IMAGE_URL!, {
      responseType: "arraybuffer",
    });
    const theId = id.replace(/-/g, "");
    const replaced = replace(raw, "ethica_machine_id_placeholder___", theId);
    const blob = new Blob([replaced], { type: "charset=binary" });
    saveAs(blob, `cpe-${theId}.img`);
    return Promise.resolve();
  };

  render() {
    const { imageType, downloading, error } = this.state;
    const controllerImageUrl = process.env.REACT_APP_CONTROLLER_IMAGE_URL;
    const controllerIcon = <div><a href={controllerImageUrl} download><MdCloudDownload /></a></div>;
    const serverIcon = <div><a onClick={this.handleDownload}><MdCloudDownload /></a></div>;
    const spinner = <Spinner size="md" />;

    return (
      <div>
        {error === "" ?
          imageType === "controller" ? 
            downloading ? spinner : controllerIcon
          : 
            downloading ? spinner : serverIcon
        : 
          <div>{error}</div>
        }

      </div>
    );
  }
}

export { DownloadImage };