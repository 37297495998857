import * as React from 'react';
import { Card, CardBody, Modal, ModalVariant, Checkbox } from '@patternfly/react-core';
import UserSession from '@app/common/user-session';
import { viewerSubmitPaths } from '@app/common/constants';
import BullhornIcon from '@patternfly/react-icons/dist/js/icons/bullhorn-icon';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import './Saver.css';

interface SaverProps {
  isLoading: boolean,
  message: string,
  submitButtonText: string,
  submitButtonisDisabled: boolean,
  confirmationMessage: string,
  showConfirmationMessage: boolean,
  cancelButtonText: string,
  showCancel: boolean,
  isConfirmOpen: boolean,
  forceAcceptTerms: boolean,
  termsChecked: boolean,
  submit: () => void,
  cancel: () => void,
}

class Saver extends React.Component<Partial<SaverProps>, Partial<SaverProps>>  {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isLoading: false,
      message: "",
      submitButtonText: "",
      submitButtonisDisabled: false,
      confirmationMessage: "",
      showConfirmationMessage: true,
      cancelButtonText: "Cancel",
      showCancel: true,
      isConfirmOpen: false,
      forceAcceptTerms: false,
      termsChecked: false
    }
  }

  handleConfirm = () => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Success!'
        );
        this.handleSubmit();
      }
    });
  };

  componentDidMount() {
    this.setState(() => ({
      isLoading: this.props.isLoading,
      message: this.props.message,
      showCancel: this.props.showCancel,
      showConfirmationMessage: this.props.showConfirmationMessage === undefined ? true : this.props.showConfirmationMessage,
      confirmationMessage: this.props.confirmationMessage || "Are you sure you want to save this resource?", 
      submitButtonText: this.props.submitButtonText,
      cancelButtonText: this.props.cancelButtonText || "Cancel",
      submitButtonisDisabled: this.props.submitButtonisDisabled,
      forceAcceptTerms: this.props.forceAcceptTerms || false
    }));
  }

  handleCheckboxChange = (checked, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleConfirmToggle = () => {
    this.setState(({ isConfirmOpen }) => ({
      isConfirmOpen: !isConfirmOpen
    }));
  };

  handleSubmit = async () => {
    this.setState(() => ({
      isLoading: true,
      isConfirmOpen: false
    }));
    await this.props.submit();
    (document.getElementById('submit-btn') as HTMLButtonElement).blur();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var isLoading = this.state.isLoading;
    var message = this.state.message;
    var submitButtonisDisabled = this.state.submitButtonisDisabled;

    if (nextProps.isLoading !== isLoading) {
      isLoading = nextProps.isLoading;
    }
    if (nextProps.message !== message) {
      message = nextProps.message;
    }
    if (nextProps.submitButtonisDisabled !== submitButtonisDisabled) {
      submitButtonisDisabled = nextProps.submitButtonisDisabled;
    }

    this.setState(() => ({
      isLoading: isLoading,
      message: message,
      submitButtonisDisabled: submitButtonisDisabled
    }));
  }

  render() {    
    const { isLoading, message, submitButtonText, cancelButtonText, showConfirmationMessage, confirmationMessage, showCancel, isConfirmOpen, submitButtonisDisabled, forceAcceptTerms, termsChecked } = this.state;
    const termsLink = process.env.REACT_APP_SITE_TERMS_LINK;
    const confirmButtonDisabled = forceAcceptTerms ? (termsChecked ? false : true) : false;
    const isViewer = UserSession.getParam('role')?.toLowerCase() === 'viewer';
    const currentPath = window.location.pathname + window.location.search;
    const isViewerSubmitPath = viewerSubmitPaths().includes(currentPath) ? true : false;
    let submitDisabled = (submitButtonisDisabled || isViewer) && !isViewerSubmitPath;

    if (isViewerSubmitPath) {
      submitDisabled = submitButtonisDisabled;
    }

    return (
      <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
        <div className="w-100 text-center">
          <Button id="submit-btn" isLoading={isLoading} disabled={submitDisabled} variant="info" className="ethica-button-green button-submit me-1" onClick={this.handleConfirm}>
            {submitButtonText} 
          </Button>

          {showCancel && (                
            <Button variant="primary" className="ethica-button-black" onClick={this.props.cancel}>
              {cancelButtonText}
            </Button>
          )}
          {message && ( 
            <Card className="response-message-box" isCompact>
              <CardBody>
                {message}
              </CardBody>
            </Card>
          )}
        </div>
        {forceAcceptTerms && (
          <div className="d-flex mt-3 justify-content-center flex-wrap">
            <div className="w-100 d-flex justify-content-center">
              <Checkbox 
                label="Accept the Terms and Conditions" aria-label="Accept the Terms and Conditions" 
                name="termsChecked" id="termsChecked" key="termsChecked"
                isChecked={termsChecked} onChange={this.handleCheckboxChange}
              /> 
            </div>
            <p className="fonr-12 mt-3">Terms and Conditions can be read <a href={termsLink} target="_blank">here</a>.</p>
          </div>
        )}
      </div>
    );
  }
}

export { Saver };