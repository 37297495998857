// @ts-nocheck
import * as React from 'react';
import { Title, Checkbox } from "@patternfly/react-core";
import './IconHeading.css';

class IconHeading extends React.Component<any, any> {
  constructor(props) {
    super(props);
    
    const key = this.getEnableKey(this.props.id, this.props.heading);

    this.state = {
      id: this.props.id,
      icon: this.props.icon,
      heading: this.props.heading,
      [key]: this.props.enabled,
      enableText: (this.props.enableText === undefined) ? "Enabled" : this.props.enableText,
      showEnable: (this.props.showEnable === undefined) ? false : true,
      subHeading: (this.props.subHeading === undefined || this.props.subHeading === "") ? "" : this.props.subHeading,
      checkboxDisabled: (this.props.checkboxDisabled === undefined) ? false : this.props.checkboxDisabled
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var id = this.state.id;
    var subHeading = this.state.subHeading;
    var heading = this.state.heading;
    var checkboxDisabled = this.state.checkboxDisabled;
    
    if (nextProps.id !== id) {
      id = nextProps.id;
    }
    if (nextProps.subHeading !== subHeading) {
      subHeading = nextProps.subHeading;
    }
    if (nextProps.heading !== heading) {
      heading = nextProps.heading;
    }
    if (nextProps.checkboxDisabled !== checkboxDisabled) {
      checkboxDisabled = nextProps.checkboxDisabled;
    }

    const key = this.getEnableKey(id, heading);
    var enabled = this.state[key];

    if (nextProps.enabled !== enabled) {
      enabled = nextProps.enabled;
    }

    this.setState(() => ({
      id: id,
      subHeading: subHeading,
      heading: heading,
      checkboxDisabled: checkboxDisabled,
      [key]: enabled
    }));
  }

  getEnableKey = (id, heading) => {
    const headingStr = heading.toLowerCase().replace(' ', '').replace('-', '') + "enabled";
    return id ? headingStr + id : headingStr;
  }

  getSubHeadingClass = (subHeading) => {
    if (subHeading === "" || subHeading === undefined) {
      return "";
    }
    return " " + subHeading.toLowerCase();
  }

  handleEnableChange = (checked, event) => {
    const key = this.getEnableKey(this.state.id, this.state.heading);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [key]: value });
    this.props.handleEnable(value);
  };

  render() {
    const key = this.getEnableKey(this.state.id, this.state.heading);
    const { icon, heading, showEnable, enableText, handleDisable, subHeading, checkboxDisabled } = this.state;
    const subHeadingClass = this.getSubHeadingClass(subHeading);
    
    return (
      <div>
        <div className="icon-heading-container">
          <div className="icon icon-heading">{icon}</div>
          <div className="icon-heading">
            {heading}
          </div>
          <div className={"sub-heading" + subHeadingClass}>
            {showEnable ? 
              <Checkbox 
                label={enableText} aria-label={enableText} name={key} id={key} key={key}
                isChecked={this.state[key]} onChange={this.handleEnableChange} isDisabled={checkboxDisabled}
              />
            : 
              <Title headingLevel="h4" size="sm">{subHeading}</Title>
            }
          </div>
        </div>
      </div>
    );
  }
}

export { IconHeading };