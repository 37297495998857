import React from 'react';
import { IconHeading } from '@app/components/IconHeading';
import { ExclamationCircleIcon, MapMarkerIcon } from '@patternfly/react-icons';
import { FormGroup, TextInput, Select, SelectVariant } from "@patternfly/react-core";
import { FormGroupSpacer } from '../../../components/forms/pf/form-group-spacer.component';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';

const SiteInfo = ({ 
  site, address1ErrorState, address2ErrorState, cityErrorState, postalCodeErrorState, onRegionSelect, 
  onRegionToggle, isRegionOpen, onCountrySelect, onCountryToggle, isCountryOpen, handleChange, countries, regions
}) => {
  return (
    <div>                      
      <Card isCompact className="mb-3">
        <CardHeader className="p-2 fw-bold d-flex align-items-center bg-transparent">
          <div className="w-100 d-flex justify-content-between">       
          <IconHeading icon={<MapMarkerIcon className="icon-medium" />} heading="Site Location" />
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <FormGroup isRequired fieldId="address1" className="form-item"
                helperTextInvalid={address1ErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={address1ErrorState.validated}>
                <TextInput className="form-input form-control" validated={address1ErrorState.validated}
                  isRequired type="text" id="address1" name="address1" placeholder="Site Address"
                  value={site.address1} onChange={(e) => handleChange("siteInfo", "address1", e)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup fieldId="address2" className="form-item"
                helperTextInvalid={address2ErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={address2ErrorState.validated}>
                <TextInput className="form-input form-control" validated={address2ErrorState.validated}
                  type="text" id="address2" name="address2" placeholder="Address Line 2"
                  value={site.address2} onChange={(e) => handleChange("siteInfo", "address2", e)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup fieldId="city" className="form-item"
                helperTextInvalid={cityErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={cityErrorState.validated}>
                <TextInput className="form-input form-control"
                  type="text" id="city" name="city" placeholder="City" validated={cityErrorState.validated}
                  value={site.city} onChange={(e) => handleChange("siteInfo", "city", e)}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup fieldId="country-toggle" className="form-item">
                <Input
                  type="select" 
                  value={site.country || ''}
                  onChange={(e) => onCountrySelect(e, e.target.value)}
                  onClick={() => onCountryToggle}
                  open={isCountryOpen} 
                >
                  {countries &&
                  <>
                    <option key="default" value="">Select Country...</option>
                    {countries.map((country, index) => (
                        <option key={country.key || index} value={country.props.value}>
                            {country.props.value}
                        </option>
                    ))}
                  </>
                  }
                </Input>
              </FormGroup>
            </Col>
              
            <Col>
              <FormGroup fieldId="region-toggle" className="form-item">
                <Input
                  type="select" 
                  value={site.state || ''}
                  onChange={(e) => onRegionSelect(e, e.target.value)}
                  onClick={() => onRegionToggle}
                  open={isRegionOpen} 
                >
                  {regions &&
                  <>
                    <option key="default" value="">Select Region...</option>
                    {regions.map((region, index) => (
                        <option key={region.key || index} value={region.props.value}>
                            {region.props.value}
                        </option>
                    ))}
                  </>
                  }
                </Input>
              </FormGroup>
            </Col>
              
            <Col>
              <FormGroup fieldId="postalCode" className="form-item"
                helperTextInvalid={postalCodeErrorState.errorText} helperTextInvalidIcon={<ExclamationCircleIcon />} validated={postalCodeErrorState.validated}>
                <TextInput className="form-input form-control"
                  type="text" id="postalCode" name="postalCode" placeholder="Postal Code / Zip" validated={postalCodeErrorState.validated}
                  value={site.postalCode} onChange={(e) => handleChange("siteInfo", "postalCode", e)}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default SiteInfo;