import React, { useState } from 'react';
import axios from 'axios';
import SupportApi from '@app/api/support-api/support.actions';
import { FormGroup } from 'reactstrap';

const SupportForm = ({ onTicketAdded }) => {
  const [formData, setFormData] = useState({
    subject: '',
    priority: 'Low',
    urgency: 'None',
    impacting: 'None',
    additional_contacts: '',
    message: '',
    attachments: [],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachments: e.target.files });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    const data = new FormData();
    data.append('subject', formData.subject);
    data.append('priority', formData.priority);
    data.append('urgency', formData.urgency);
    data.append('impacting', formData.impacting);
    data.append('additional_contacts', formData.additional_contacts);
    data.append('message', formData.message);

    for (let i = 0; i < formData.attachments.length; i++) {
      data.append('attachments', formData.attachments[i]);
    }

    try {
      const response = await SupportApi.addCase(data);
      setSuccessMessage('Support case created successfully!');
      if (onTicketAdded) {
        onTicketAdded(response);
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while creating the support case.');
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2><strong>Create Support Case</strong></h2>
      <form onSubmit={handleSubmit}>
        <div>
            <FormGroup label="Subject">
                <label>Subject</label>
                <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    className="form-control"
                />
            </FormGroup>
        </div>

        <div>
            <FormGroup label="Priority">
                <label>Priority</label>
                <select name="priority" value={formData.priority} onChange={handleInputChange} required className="form-control">
                    <option value="Low">Low</option>
                    <option value="Normal">Normal</option>
                    <option value="High">High</option>
                    <option value="Urgent">Urgent</option>
                </select>
            </FormGroup>
        </div>

        <div>
            <FormGroup label="Priority">
            <label>Urgency</label>
            <select name="urgency" value={formData.urgency} onChange={handleInputChange} required className="form-control">
                <option value="Inquiry/Request">Inquiry/Request</option>
                <option value="Degraded Service">Degraded Service</option>
                <option value="No Service">No Service</option>
                <option value="Security Event">Security Event</option>
            </select>
            </FormGroup>
        </div>

        <div>
            <FormGroup label="Priority">
            <label>Impacting</label>
            <select name="impacting" value={formData.impacting} onChange={handleInputChange} required className="form-control">
                <option value="One Site">One Site</option>
                <option value="Multiple Sites">Multiple Sites</option>
            </select>
            </FormGroup>
        </div>

        <div>
            <FormGroup label="Priority">
            <label>Additional Contacts</label>
            <input
                type="email"
                name="additional_contacts"
                value={formData.additional_contacts}
                onChange={handleInputChange}
                className="form-control"
            />
            </FormGroup>
        </div>

        <div>
            <FormGroup label="Priority">
            <label>Message</label>
            <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
                className="form-control"
            ></textarea>
            </FormGroup>
        </div>

        <div>
            <FormGroup label="Priority">
            <label>Attachments</label>
            <input
                type="file"
                name="attachments"
                onChange={handleFileChange}
                multiple
                className="form-control"
            />
            </FormGroup>
        </div>

        <div className="text-center p-3">
            <button type="submit" className="ethica-button-green" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
        </div>
      </form>

      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default SupportForm;