import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../components/InfoSection.css';
import './Lesson.css';
import { selectLessonId, selectLessonLessons } from '@app/common/session/session.selectors';
import { Col, Row } from 'reactstrap';
import Page from '@app/components/Page';

export const LessonView: React.FC = () => {
  const dispatch = useDispatch();

  // const courseId = useSelector(selectLessonId);
  const courseId = 1;
  // const course = useSelector(selectLessonId(courseId));
  const course = [];
  // const lesson = useSelector(selectLessonLessons(courseId));
  const lesson = {
    "id": 101,
    "title": "Check Permissions and Export Current Dashboard",
    "image": "https://portal.ethica.partners/wp-content/uploads/2023/05/image-45-1024x272.png",
    "content": '<div role="tabpanel" tabindex="0" aria-labelledby="content" class="ld-tab-content ld-visible" id="ld-tab-content-4777">' +
      '<p>Your main Site Dashboard allows you to see metrics for all sites deployed in your environment. The first step is to make sure that you main Site Dashboard only allows for access from Administrators. To so, go to your Dashboards list, and select the “Site Dashboard”.</p>' +
      '<figure class="wp-block-image size-large"><img loading="lazy" decoding="async" width="1024" height="272" src="https://portal.ethica.partners/wp-content/uploads/2023/05/image-45-1024x272.png" alt="" class="wp-image-4789" srcset="https://portal.ethica.partners/wp-content/uploads/2023/05/image-45-980x260.png 980w, https://portal.ethica.partners/wp-content/uploads/2023/05/image-45-480x128.png 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1024px, 100vw"></figure>' +
      '<p>In the top right corner, click on the Settings button.</p>' +
      '<figure class="wp-block-image size-large"><img decoding="async" loading="lazy" width="1024" height="475" src="https://portal.ethica.partners/wp-content/uploads/2023/05/image-46-1024x475.png" alt="" class="wp-image-4791" srcset="https://portal.ethica.partners/wp-content/uploads/2023/05/image-46-980x454.png 980w, https://portal.ethica.partners/wp-content/uploads/2023/05/image-46-480x223.png 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1024px, 100vw"></figure>' +
      '<p>Once in the Site Dashboard Settings, click on the Permissions tab.</p>' +
      '<figure class="wp-block-image size-large"><img decoding="async" loading="lazy" width="1024" height="428" src="https://portal.ethica.partners/wp-content/uploads/2023/05/image-47-1024x428.png" alt="" class="wp-image-4792" srcset="https://portal.ethica.partners/wp-content/uploads/2023/05/image-47-1024x428.png 1024w, https://portal.ethica.partners/wp-content/uploads/2023/05/image-47-980x410.png 980w, https://portal.ethica.partners/wp-content/uploads/2023/05/image-47-480x201.png 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1024px, 100vw"></figure>' +
      '<p>In the Permissions tab, make sure that the only permission available is the Admin with Admin access. Make sure to remove any Viewer Permissions.</p>' +
      '<figure class="wp-block-image size-large"><img decoding="async" loading="lazy" width="1024" height="239" src="https://portal.ethica.partners/wp-content/uploads/2023/05/image-48-1024x239.png" alt="" class="wp-image-4793" srcset="https://portal.ethica.partners/wp-content/uploads/2023/05/image-48-980x229.png 980w, https://portal.ethica.partners/wp-content/uploads/2023/05/image-48-480x112.png 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1024px, 100vw"></figure>' +
      '<p>Finally, click on the JSON Model tab. Copy the contents of the JSON Model, and save them to your computer. Make sure to save it as a JSON file, this will be used later to import into a new dashboard for your customer(s).</p>' +
      '<figure class="wp-block-image size-large"><img decoding="async" loading="lazy" width="1024" height="581" src="https://portal.ethica.partners/wp-content/uploads/2024/01/image-1024x581.png" alt="" class="wp-image-5030" srcset="https://portal.ethica.partners/wp-content/uploads/2024/01/image-1024x581.png 1024w, https://portal.ethica.partners/wp-content/uploads/2024/01/image-980x556.png 980w, https://portal.ethica.partners/wp-content/uploads/2024/01/image-480x272.png 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1024px, 100vw"></figure>' +
      '</div>',
  };


  return (
    <React.Fragment>
      <Page
        className={`cr-page px-3 pt-2 lesson-view`}
        breadcrumbs={[{ name: ` Lessons `, active: true }, { name: ` ${lesson.title} `, active: true }]}
      >
        <div className={`disable-wrapper active`}></div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
            <div className="user-lesson">
              <h2>
                <strong>{lesson.title}</strong>
              </h2>
              <div className="p-3">
                <div className="lesson-name mb-3">
                  <strong>{lesson.title}</strong>
                </div>
                <div className="text-center">{<div dangerouslySetInnerHTML={{ __html: lesson.content }} />}</div>
              </div>
              <div className="learndash-wrapper learndash-shortcode-wrap-ld_navigation-4774_4777_292">
                <div className="ld-content-actions">
                  <div className="ld-content-action">
                    <a
                      href="/courses"
                      className="ld-primary-color ld-course-step-back btn w-100 ethica-button-green"
                    >
                      Back to Course
                    </a>
                  </div>
                  <div className="ld-content-action">
                    <a
                      className="ld-button btn w-100 ethica-button-green"
                      href="/courses/1/lessons/102"
                    >
                      <span className="ld-text">Next Lesson</span>
                      <span className="ld-icon ld-icon-arrow-right"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};