import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNotifier } from '../../lib/use-notifier';
import UserSession from '@app/common/user-session';
import { selectFaviconFileUrl, selectHeaderColor, selectPageTitle } from '../../common/session/session.selectors';
import ThemeContext from '@app/providers/contexts/ThemeContext';

export const Html: React.FC = ({ children }) => {
  useNotifier();
  let pageTitle = useSelector(selectPageTitle);
  let faviconUrl = useSelector(selectFaviconFileUrl);
  let headerColor = useSelector(selectHeaderColor);
  const { theme } = React.useContext(ThemeContext);

  if (UserSession.getParam('isImpersonating')) {
    pageTitle = UserSession.getParam('pageTitle') || pageTitle;
    faviconUrl = UserSession.getParam('faviconFileUrl') || faviconUrl;
    headerColor = UserSession.getParam('headerColor') || headerColor;
  }

  return (
    <React.Fragment>
      <Helmet titleTemplate={`%s | ${pageTitle}`} defaultTitle={`${pageTitle}`}>
        <link rel="icon" type="image/png" href={`${faviconUrl}`} />
        {headerColor !== 'default' && theme !== 'dark' && (
          <style type='text/css'>
            {`
              .cr-header {
                --pf-global--BackgroundColor--100: ${headerColor} !important; 
                background-color: ${headerColor} !important;
                background-image: none !important;
              }

              .ethica-button-green, .swal2-confirm {
                background-color: ${headerColor} !important;
                border-color: ${headerColor} !important;
              }

              .card-body svg, .cr-search-form svg, .pf-c-tabs__item-icon svg {
                fill: ${headerColor} !important;
              }

              .cr-header__nav-right svg, .icon-heading svg, .dashboard-card-wrapper.site-status svg {
                fill: #fff !important;
              }

              a {
                color: ${headerColor} !important;
              }
            `}
          </style>
        )}
      </Helmet>
      {children}
    </React.Fragment>
  );
};
